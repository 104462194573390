<template>
  <div
    class="flex items-center"
    data-test-id="invited-signatory-options"
    @mouseenter="isMouseHover = true"
    @mouseleave="isMouseHover = false"
  >
    <!-- EMAIL AND STATUS TEXT -->
    <div
      class="d-flex flex-column flex-fill signer-card"
      :class="[signatureClass]"
      style="min-width: 0"
    >
      <!-- EMAIL -->
      <div
        class="d-flex md:flex items-center justify-between signee-email"
        :class="{ clickable: !isDocumentWithdrawn && !isDocumentRejected }"
        @click="scrollToSignature"
      >
        <v-tooltip
          content-class="tooltip deep-light tooltip-top pointer-events-auto"
          location="top"
          :open-on-click="device.isMobile"
        >
          <template #activator="{ props: ActivatorProps }">
            <span v-bind="ActivatorProps" class="deep-truncate">
              {{ signature.email }}
            </span>
          </template>
          <template #default>
            <span>
              {{ signature.email }}
            </span>
          </template>
        </v-tooltip>
      </div>
      <!-- /EMAIL -->

      <!-- STATUS TEXT -->
      <div class="flex items-center status" :class="statusClass">
        <div v-if="signature.signStatus === SignStatus.ON_HOLD">
          <span>
            {{ i18n.t('document_status.on_hold') }}
          </span>
        </div>
        <div v-if="signature.signStatus === SignStatus.SIGNED">
          <span class="signed"> {{ i18n.t('document_status.signed') }}: </span>
        </div>
        <div v-if="signature.signStatus === SignStatus.REJECTED">
          <span class="rejected">
            {{ i18n.t('titles.signature_rejected') }}:
          </span>
        </div>
        <div class="flex items-center">
          {{ text }}
          <v-tooltip
            v-if="
              signature.signStatus === SignStatus.SIGNED ||
              signature.signStatus === SignStatus.REJECTED
            "
            content-class="tooltip deep-light tooltip-top"
            location="top"
            open-on-click
          >
            <template #activator="{ props: ActivatorProps }">
              <v-icon v-bind="ActivatorProps" class="ml-1 date" :size="12">
                far fa-clock
              </v-icon>
            </template>
            <span class="text-xs">
              {{
                formatDateTimeToLocaleTimeWithTimeZone(
                  signature.signedTime,
                  i18n.locale
                )
              }}
            </span>
          </v-tooltip>
        </div>
      </div>
      <!-- /STATUS TEXT -->
    </div>
    <!-- /EMAIL AND STATUS TEXT -->

    <!-- STATUS ICONS -->
    <div class="d-flex flex-column justify-center align-center icons-column">
      <div class="d-flex flex-row">
        <ParticipantMenu
          v-if="signature.signStatus !== SignStatus.SIGNED"
          :show-activator="isMouseHover"
          :participant-id="signature.signeeId"
          :participant-status="signature.signStatus"
          :participant-policy="signature.policy"
          :participant-email="signature.email"
        />
        <a v-if="signeeComment" class="show-comment" @click="showDialog">
          <v-icon class="mr-2" size="18"> fal fa-comment-lines</v-icon>
        </a>
        <v-icon
          v-if="signature.signStatus === SignStatus.SIGNED"
          class="signed-icon mr-3"
          size="18"
        >
          fas fa-circle-check
        </v-icon>
        <v-icon
          v-if="signature.signStatus === SignStatus.REJECTED"
          class="rejected-icon mr-3"
          size="18"
        >
          fa-solid fa-circle-xmark
        </v-icon>
      </div>
    </div>
    <!-- /STATUS ICONS -->

    <!-- COMMENT DIALOG -->
    <SigneeCommentDialog
      v-bind="$attrs"
      :ref="`comment-dialog-${signature.email}`"
      v-model="commentDialog"
      :comment="signeeComment"
      :signee="signature"
    />
    <!-- /COMMENT DIALOG -->
  </div>
</template>
<script setup>
import SignatureService from '@/services/signatureService';
import { isMobileOrTablePortrait } from '@/services/styleService';
import AuthService from '@/services/auth-service';
import { DocumentStatus } from '@/types/enums/DocumentStatus.ts';
import { SignStatus } from '@/types/enums/SignStatus.ts';
import { useSignStore } from '@/stores/sign/sign.ts';
import { computed, inject, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { useEmitter } from '@/composables/useEmitter.ts';
import { DeviceKey } from '@/plugins/device-detector-js';
import {
  formatDateTimeToLocaleTimeWithTimeZone,
  formatDateToLocale
} from '@/composables/useUtils';
import ParticipantMenu from '@/components/menus/ParticipantMenu.vue';
import { useScreenOrientation } from '@vueuse/core';

const props = defineProps({
  signature: {
    type: Object,
    required: true
  }
});

const display = useDisplay();
const signStore = useSignStore();
const commentDialog = ref(false);
const invitationSent = ref(false);
const { orientation } = useScreenOrientation();
const device = inject(DeviceKey);
const i18n = useI18n();
const emitter = useEmitter();
const isMouseHover = ref(false);
const documentStatus = computed(() => {
  return signStore.signInfo?.document?.documentStatus;
});

const isDocumentWithdrawn = computed(() => {
  return documentStatus.value === DocumentStatus.WITHDRAWN;
});

const isDocumentRejected = computed(() => {
  return documentStatus.value === DocumentStatus.REJECTED;
});

const isMobileOrTabletPortrait = computed(() => {
  return (
    isMobileOrTablePortrait(
      device.isMobile,
      device.isTablet,
      display.mdAndDown.value
    ) && orientation.value === 'portrait-primary'
  );
});

const isMe = computed(() => {
  if (signStore.signInfo?.email) {
    return SignatureService.compareString(
      signStore.signInfo.email,
      props.signature?.email
    );
  }
  if (AuthService.isAuthenticated) {
    return SignatureService.compareString(
      AuthService.user?.email,
      props.signature?.email
    );
  } else {
    return false;
  }
});

const signatureClass = computed(() => {
  if (isMe.value && props.signature.signStatus === SignStatus.PENDING) {
    return 'my-card';
  }
  if (props.signature.signStatus === SignStatus.PENDING) return 'pending-card';
  if (props.signature.signStatus === SignStatus.SIGNED) return 'signed-card';
  return '';
});

const text = computed(() => {
  if (props.signature.signStatus === SignStatus.PENDING) {
    if (documentStatus.value === DocumentStatus.REJECTED) {
      return i18n.t('titles.signee_rejected_signature');
    }
    return i18n.t('titles.waiting_for_signature');
  }
  if (props.signature.signStatus === SignStatus.REJECTED) {
    return formatDateToLocale(
      props.signature.signedTime,
      i18n.locale.value,
      true
    );
  }
  if (props.signature.signStatus === SignStatus.SIGNED) {
    return formatDateToLocale(
      props.signature.signedTime,
      i18n.locale.value,
      true
    );
  }
  return '';
});

const statusClass = computed(() => {
  if (props.signature.signStatus === SignStatus.PENDING) {
    return 'pending';
  }

  if (props.signature.signStatus === SignStatus.REJECTED) {
    return 'date';
  }

  if (props.signature.signStatus === SignStatus.SIGNED) {
    return 'date';
  }

  return 'pending';
});

const signeeComment = computed(() => {
  if (isMe.value) {
    return (
      signStore.signInfo?.signeeComment || props.signature?.signeeComment || ''
    );
  }
  return props.signature?.signeeComment || '';
});

emitter.$on('invitation-sent', updatedInvitationStatus);

onUnmounted(() => {
  emitter.$off('invitation-sent', updatedInvitationStatus);
});

function showDialog() {
  commentDialog.value = true;
}

function updatedInvitationStatus(eventData) {
  if (eventData === props.signature.email) {
    invitationSent.value = true;
  }
}

function scrollToSignature() {
  if (
    isMobileOrTabletPortrait.value ||
    isDocumentWithdrawn.value ||
    isDocumentRejected.value ||
    !props.signature.autographPosition
  ) {
    return null;
  }
  SignatureService.scrollToSignaturePosition(props.signature.autographPosition);
}
</script>

<style lang="scss" scoped>
@import '@/styles/core/colors';

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.signer-card {
  margin-top: 21px;
}

.mobile-signer-card {
  margin-top: 21px;
}

.signee-email {
  word-break: break-all;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}

.signed {
  margin-right: 4px;
  color: $success;
}

.signed-icon {
  color: $success;
}

.rejected-icon {
  color: $error;
}

.date {
  color: $grey;
}

.pending {
  color: $grey;
}

.rejected {
  margin-right: 4px;
  color: $error;
}

.status {
  margin-top: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.015em;
}

.sub-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #7d7d7d;
}

.show-comment {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: $grey-darken-1;

  &:hover {
    color: $primary-color;

    i {
      color: $primary-color;
    }
  }
}

.resend-invitation {
  &:hover {
    color: $primary-color;
  }
}

.icons-column {
  margin-top: 21px;
  margin-left: 8px;
}

.tooltip {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}
</style>
