import { Jurisdiction } from '@/types/enums/Jurisdiction';
import { SignatureMode } from '@/types/enums/SignatureMode';
import { isRef } from 'vue';
import { constants } from '@/constants/constants';
import type { HTMLInputEvent } from '@/utils/types/HtmlInputEvent';
import type { Signee } from '@/types/Signee';
import type { RouteLocation } from 'vue-router';
import type { BackRoute } from '@/types/ui';

export const OUT_OF_SCOPE =
  'getCurrentInstance() returned null. Method must be called at the top of a setup() function.';
export function getSignatureLevelText(signatureMode: SignatureMode): string {
  if (signatureMode) {
    switch (signatureMode) {
      case SignatureMode.TIMESTAMP:
        return 'texts.basic';
      case SignatureMode.ADVANCED:
        return 'texts.advanced';
      case SignatureMode.QUALIFIED:
        return 'texts.qualified';
      default:
        return '';
    }
  }
  return '';
}

export function getSignatureLevelAcronym(signatureMode: SignatureMode): string {
  switch (signatureMode) {
    case SignatureMode.TIMESTAMP:
      return 'SES';
    case SignatureMode.ADVANCED:
      return 'AES';
    case SignatureMode.QUALIFIED:
      return 'QES';
    default:
      return '';
  }
}

export function getJurisdictionText(jurisdiction: Jurisdiction): string {
  switch (jurisdiction) {
    case Jurisdiction.ZERTES:
      return 'certificates.ch';
    case Jurisdiction.EIDAS:
      return 'certificates.eu';
    default:
      return '';
  }
}

export function humanFileSize(bytes: number, dp = 1) {
  const thresh = 1000;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} Bytes`;
  }

  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let u = -1;
  const r = 10 ** dp;

  do {
    // eslint-disable-next-line no-param-reassign
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${bytes.toFixed(dp)} ${units[u]}`;
}

function isObject(item: unknown): item is Record<string, unknown> {
  return !!(item && typeof item === 'object' && !Array.isArray(item));
}

function isArray(item: unknown): item is unknown[] {
  return Array.isArray(item);
}

export function cloneDeep<T>(obj: T): T {
  if (obj instanceof Date) {
    return new Date(obj.getTime()) as unknown as T;
  }

  if (isArray(obj)) {
    return obj.map(cloneDeep) as unknown as T;
  }

  if (isObject(obj)) {
    const result = Object.keys(obj).reduce(
      (acc, key) => {
        return { ...acc, [key]: cloneDeep(obj[key]) };
      },
      {} as Record<string, unknown>
    );
    return result as T;
  }

  return obj;
}

export function maxBy<T, K extends number | string>(
  collection: T[],
  iteratee: (item: T) => K
): T | undefined {
  if (!Array.isArray(collection) || collection.length === 0) {
    return undefined;
  }

  let maxVal = collection[0];
  let maxComputed = iteratee(maxVal);

  for (let i = 1; i < collection.length; i++) {
    const computed = iteratee(collection[i]);

    if (computed > maxComputed) {
      maxComputed = computed;
      maxVal = collection[i];
    }
  }

  return maxVal;
}

type Comparable = string | number | Date;

type SortableKeys<T> = {
  [K in keyof T]: T[K] extends Comparable ? K : never;
}[keyof T];

export function orderBy<T>(
  array: T[],
  keys: SortableKeys<T>[],
  orders: ('asc' | 'desc')[] = []
): T[] {
  return [...array].sort((a, b) => {
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const order = orders[i] || 'asc';
      const aValue = a[key];
      const bValue = b[key];
      if (order === 'asc') {
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
      } else {
        if (aValue < bValue) return 1;
        if (aValue > bValue) return -1;
      }
    }
    return 0;
  });
}

type GroupKey = string | number;

export function groupBy<T>(
  collection: T[],
  iteratee: ((item: T) => GroupKey) | keyof T
): Record<GroupKey, T[]> {
  const grouped: Record<GroupKey, T[]> = {};
  for (const item of collection) {
    let key: GroupKey;

    if (typeof iteratee === 'function') {
      key = iteratee(item);
    } else if (typeof iteratee === 'string') {
      key = item[iteratee] as unknown as GroupKey;
    } else {
      throw new Error('Iteratee should be a function or a string');
    }

    if (key in grouped) {
      grouped[key].push(item);
    } else {
      grouped[key] = [item];
    }
  }
  return grouped;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<F extends (...args: any[]) => any>(
  func: F,
  wait: number
): (...funcArgs: Parameters<F>) => void {
  let timeout: NodeJS.Timeout | null = null;

  return (...args: Parameters<F>) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      func(...args);
      timeout = null;
    }, wait);
  };
}

export function getIsoCodeByLanguage(language: string): string {
  let locale = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const lang: any = isRef(language) ? language.value : language;
  switch (lang.toLowerCase()) {
    case 'fr':
      locale = 'fr';
      break;
    case 'it':
      locale = 'it';
      break;
    case 'de':
      locale = 'de';
      break;
    case 'en':
      locale = 'en';
      break;
    default:
      locale = lang;
      break;
  }
  return locale;
}

export function truncate(
  fullStr: string,
  strLen: number,
  separator: string
): string {
  let itemSeparator = separator;
  if (fullStr.length <= strLen) {
    return fullStr;
  }

  itemSeparator = itemSeparator || '...';

  const sepLen = itemSeparator.length;
  const charsToShow = strLen - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substring(0, frontChars) +
    itemSeparator +
    fullStr.substring(fullStr.length - backChars)
  );
}

// Date options
type ExtendedDateFormat = {
  year: 'numeric';
  month: 'short';
  weekday: 'short';
  day: '2-digit';
  hour: '2-digit';
  minute: '2-digit';
  second: '2-digit';
  hour12: false;
};

type NormalDateFormat = {
  year: 'numeric';
  month: '2-digit';
  day: '2-digit';
  hour: '2-digit';
  minute: '2-digit';
  second: '2-digit';
  hour12: false;
};

const normalDateFormat: NormalDateFormat = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false
};

const extendedDateFormat: ExtendedDateFormat = {
  year: 'numeric',
  month: 'short',
  weekday: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false
};

// Input validation
function isValidDateString(dateString: string): dateString is string {
  return Boolean(dateString && dateString !== '');
}

// Format Date
export function formatDateTimeToLocale(
  dateString: string,
  locale: string,
  extended = false
) {
  if (!isValidDateString(dateString)) {
    return '';
  }

  const isoCode = getIsoCodeByLanguage(locale);
  const formatOptions = extended ? extendedDateFormat : normalDateFormat;
  return new Date(dateString).toLocaleDateString(isoCode, formatOptions);
}

type ExtendedDateOnlyFormat = {
  year: 'numeric';
  month: 'short';
  weekday: 'short';
  day: '2-digit';
};

type NormalDateOnlyFormat = {
  year: 'numeric';
  month: '2-digit';
  day: '2-digit';
};

const normalDateOnlyFormat: NormalDateOnlyFormat = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
};

const extendedDateOnlyFormat: ExtendedDateOnlyFormat = {
  year: 'numeric',
  month: 'short',
  weekday: 'short',
  day: '2-digit'
};

// Format Date to Locale
export function formatDateToLocale(
  dateString: string,
  locale: string,
  extended = false
): string {
  if (!isValidDateString(dateString)) {
    return '';
  }

  const isoCode = getIsoCodeByLanguage(locale);
  const formatOptions = extended
    ? extendedDateOnlyFormat
    : normalDateOnlyFormat;

  return new Date(dateString).toLocaleDateString(isoCode, formatOptions);
}

// Time options
type TimeFormat = {
  hour: '2-digit';
  minute: '2-digit';
  second: '2-digit';
  hour12: false;
};

const timeFormat: TimeFormat = {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false
};

// Format Date Time to Locale time
export function formatDateTimeToLocaleTime(
  dateString: string,
  locale: string
): string {
  if (!isValidDateString(dateString)) return '';
  const isoCode = getIsoCodeByLanguage(locale);
  return new Date(dateString).toLocaleTimeString(isoCode, timeFormat);
}

function getCurrentTimeZone(): string {
  // Get timezone offset in minutes.
  const offsetMinutes = new Date().getTimezoneOffset();

  // Convert offset minutes into hours and minutes.
  const absoluteOffsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
  const absoluteOffsetMinutes = Math.abs(offsetMinutes) % 60;

  // Create offset string with hours and minutes.
  const offsetString = `GMT${offsetMinutes < 0 ? '+' : '-'}${String(
    absoluteOffsetHours
  ).padStart(2, '0')}:${String(absoluteOffsetMinutes).padStart(2, '0')}`;

  return `(${offsetString})`;
}

export function formatDateTimeToLocaleTimeWithTimeZone(
  dateString: string,
  locale: string
): string {
  return `${formatDateTimeToLocaleTime(
    dateString,
    locale
  )} ${getCurrentTimeZone()}`;
}

function explodePath(path: string): string[] {
  const output = path.split('/');
  if (output[0] === '') output.shift();
  return output;
}

function isInvisibleFile(file: File | FileSystemEntry): boolean {
  return file.name.substring(0, 1) === '.';
}

interface Folder {
  parent: string | null;
  name: string;
}

interface UploadedFile {
  file: File;
  idFolder: string | undefined;
}

interface Output {
  files: UploadedFile[];
  folders: Record<string, Folder>;
  hasFolders: boolean;
}

function getFolderId(path: string[], level: number): string {
  const levelFolderNames = path.slice(0, level + 1);
  return `${level}-${levelFolderNames.join('-')}`;
}

function processFile(file: File, output: Output): void {
  if (file.size > constants.FILE_MAX_SIZE || isInvisibleFile(file)) {
    return;
  }

  const path = explodePath(file.webkitRelativePath);
  const levelFile = path.length - 2;

  const newFolders = { ...output.folders };
  let hasFolders = output.hasFolders;

  path.forEach((nameFolder, level) => {
    const idFolder = getFolderId(path, level);
    const parentId = level === 0 ? null : getFolderId(path, level - 1);

    if (!newFolders[idFolder]) {
      hasFolders = true;
      newFolders[idFolder] = { parent: parentId, name: nameFolder };
    }
  });

  const idFolder = levelFile >= 0 ? getFolderId(path, levelFile) : undefined;

  output.files.push({ file, idFolder });
  // eslint-disable-next-line no-param-reassign
  output.folders = newFolders;
  // eslint-disable-next-line no-param-reassign
  output.hasFolders = hasFolders;
}

export function getAllFileEntriesFromButtonUpload(
  e: HTMLInputEvent
): Output | false {
  const files = e.target.files;
  const output: Output = { files: [], folders: {}, hasFolders: false };

  if (!files) {
    return output;
  }

  for (let i = 0; i < files.length; i++) {
    processFile(files[i], output);
  }
  return output;
}

type FileEntry = FileSystemFileEntry | null;

async function getFile(fileEntry: FileEntry): Promise<File | null> {
  if (!fileEntry || fileEntry.isDirectory) {
    return null;
  }
  try {
    return await new Promise((resolve, reject) =>
      fileEntry.file(resolve, reject)
    );
  } catch (err) {
    console.error(err);
    return null;
  }
}

type ProcessedEntry = {
  file: File | null;
  fileEntry: FileEntry;
  idFolder?: string;
};

type OutputEntries = {
  folders: object;
  files: ProcessedEntry[];
  hasFolders: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getAllFileEntries(event: any): Promise<OutputEntries> {
  const dataTransferItemList = event.dataTransfer.items;

  const output: OutputEntries = {
    folders: {},
    files: [],
    hasFolders: false
  };

  const entries: FileEntry[] = Array.from(dataTransferItemList)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((item: any) => item.webkitGetAsEntry())
    .filter(Boolean);

  const files = await Promise.all(entries.map((entry) => getFile(entry)));

  const processedEntries: ProcessedEntry[] = entries.map((entry, i) => ({
    file: files[i],
    fileEntry: entry
  }));

  const fileEntries: ProcessedEntry[] = processedEntries.filter(
    ({ file, fileEntry }) => {
      if (file && fileEntry?.isFile && !isInvisibleFile(fileEntry)) {
        const fullPath = explodePath(fileEntry.fullPath);
        const level = fullPath.length;
        if (level > 1) {
          return {
            idFolder: `${level - 1}-${fullPath[level - 2]}`
          };
        }
        return true;
      }
      return false;
    }
  );

  output.files = fileEntries;

  return output;
}

export async function base64ToFile(
  dataString: string,
  filename: string
): Promise<File> {
  const signatureBlob: Response = await fetch(dataString);
  const blob: Blob = await signatureBlob.blob();
  return new File([blob], filename);
}

export async function createDownloadLink(url: string, fileName: string) {
  const link = document.createElement('a');
  link.setAttribute('download', fileName);
  link.setAttribute('href', url);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export async function downloadFile(url, filename) {
  try {
    const response = await fetch(url, {
      headers: {
        Accept: 'application/pdf'
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    await createDownloadLink(blobUrl, filename);
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error in fetching and downloading file:', error);
  }
}

export function setDeepBoxBackRoute(userId: string, path: string) {
  sessionStorage.setItem(`deepsign.${userId}.deepbox.back.route`, path);
}

export function getDeepBoxBackRoute(userId: string) {
  return sessionStorage.getItem(`deepsign.${userId}.deepbox.back.route`) || '';
}

export function setSettingsBackRoute(userId: string, route: RouteLocation) {
  if (route.name) {
    sessionStorage.setItem(
      `deepsign.${userId}.settings.back.route`,
      JSON.stringify({
        name: route.name,
        query: route.query,
        params: route.params
      })
    );
  }
}

export function getSettingsBackRoute(userId: string) {
  const backRoute =
    sessionStorage.getItem(`deepsign.${userId}.settings.back.route`) ||
    undefined;

  if (!backRoute) {
    return undefined;
  }

  return JSON.parse(backRoute) as BackRoute;
}

export function scrollToSignature(signees: Signee[]) {
  scrollToSignatureDebounced(signees);
}

export function scrollToSignatureDebounced(signees: Signee[]) {
  const debouncedFunction = debounce(function () {
    if (signees.length > 0) {
      const documentPreview = document.getElementById('page-content');
      const firstSignature = document.getElementById(
        `signature-preview-${signees[signees.length - 1].signeeId}`
      );

      documentPreview.scroll({
        top:
          firstSignature.offsetTop -
          firstSignature.getBoundingClientRect().height,
        behavior: 'smooth'
      });
    }
  }, 300);
  debouncedFunction();
}

export function todo() {
  window.alert('TODO: this feature is missing yet');
}

function countWordOccurrencesInList(list: string[], word: string): number {
  return list.reduce(
    (count, currentWord) => (currentWord === word ? count + 1 : count),
    0
  );
}

function getInitialsFromList(list: string[]): string {
  if (list.length === 0) return '';

  const ignoredWords = ['AG', 'SA', 'SARL', 'LTD', 'GMBH'];
  let initials = [...list];
  let result: string;

  if (initials.length > 1) {
    const hasDuplicatedIgnoredWords = initials.some(
      (initial) =>
        ignoredWords.includes(initial) &&
        countWordOccurrencesInList(initials, initial) > 1
    );

    if (hasDuplicatedIgnoredWords) {
      initials = initials.filter(
        (initial, index, self) =>
          !ignoredWords.includes(initial) || self.indexOf(initial) === index
      );
      return getInitialsFromList(initials);
    } else {
      initials = initials.filter(
        (word) => !ignoredWords.includes(word.toUpperCase())
      );
    }
  } else {
    const pascalCaseInitials = initials[0]
      .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
      .split(' ');
    if (pascalCaseInitials.length > 1) {
      return getInitialsFromList(pascalCaseInitials);
    }
  }

  if (initials.length > 2) {
    result = initials[0].charAt(0) + initials[initials.length - 1].charAt(0);
  } else if (initials.length > 1) {
    result = initials[0].charAt(0) + initials[1].charAt(0);
  } else {
    result = initials[0].substring(0, 2);
  }

  return result.toUpperCase();
}

export function getInitials(name: string): string {
  if (!name) {
    return '';
  }
  // remove emojis
  const text = name.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, '');
  if (!text) {
    return '';
  }
  const initials = text.split(' ');
  return getInitialsFromList(initials);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useSanitizeQueryParams(queryParams: Record<string, any>) {
  const params = new URLSearchParams();
  Object.entries(queryParams).forEach(([key, value]) => {
    if (!value) return;
    params.append(key, value);
  });
  return params;
}

export async function fetchImageAndConvertToBase64(url: string) {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error converting image to Base64:', error);
    throw error;
  }
}
