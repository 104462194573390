<template>
  <div>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel elevation="0">
        <v-expansion-panel-title static class="observers-panel-header">
          <div class="drawer-section-title text-uppercase">
            {{ i18n.t('titles.observers') }}
          </div>
          <template #actions="{ expanded }">
            <v-icon size="20" :class="{ 'fa-rotate-180': expanded }">
              fal fa-chevron-down
            </v-icon>
          </template>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="observers-panel-content">
          <template
            v-for="observer in document.observers"
            :key="observer.observerId"
          >
            <ObserverItem
              v-if="observer.email && observer.email !== ''"
              :observer="observer"
            />
          </template>

          <div
            v-if="undefinedObserversCount > 0"
            class="mt-5 drawer-subhead text-gray-400 ml-3"
            data-test-id="undefined-observer-count"
          >
            +
            {{
              i18n.t('texts.undefined_observers', {
                number: undefinedObserversCount
              })
            }}
          </div>

          <!-- EDIT OBSERVERS -->
          <div class="my-3 ml-3" data-test-id="edit-observers">
            <div
              class="deep-text-button"
              data-test-id="edit-observers-deep-text"
              @click="editObservers"
            >
              {{ i18n.t('buttons.edit_observers') }}
            </div>
          </div>
          <!-- /EDIT OBSERVERS -->
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <ObserversDialog
      :document="document"
      :has-valid-subscription="subscriptionStore.hasValidSubscription"
    />
  </div>
</template>

<script setup lang="ts">
import { useSubscriptionStore } from '@/stores/subscription/subscription';
import { useEmitter } from '@/composables/useEmitter';
import { ref, computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import type { Document } from '@/types/Document';
import { useDocumentContext } from '@/composables/useDocumentContext';

const props = defineProps({
  document: {
    type: Object as PropType<Document>,
    required: true
  }
});

const emitter = useEmitter();
const i18n = useI18n();

const { currentDocument } = useDocumentContext();
const subscriptionStore = useSubscriptionStore();
const panel = ref(0);

const undefinedObserversCount = computed(
  () =>
    props.document?.observers?.filter((obs) => obs.email === null).length || 0
);

const checkSubscriptionPayload = {
  companyId: props.document.companyId,
  signatureMode: props.document.signatureMode,
  jurisdiction: props.document.jurisdiction,
  signatureCount: 0,
  observersCount: 1
};

if (currentDocument.value) {
  subscriptionStore
    .checkDocumentSubscription(
      currentDocument.value.documentId,
      checkSubscriptionPayload
    )
    .catch((error) => {
      console.error(error.message);
    });
}

function editObservers() {
  emitter.$emit('open-observers-dialog', props.document.observers);
}
</script>

<style scoped lang="scss">
:deep(.v-expansion-panel-title__overlay) {
  background-color: white;
}

.observers-panel-header {
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 0;
  color: black;
}

.observers-panel-content :deep(.v-expansion-panel-content__wrap) {
  padding-left: 8px;
  padding-right: 0 !important;
}

.observers-panel-content :deep(.v-expansion-panel-text__wrapper) {
  padding: 0px !important;
}

.deep-secondary-button :deep(.v-btn__content),
.v-btn__content > span {
  font-weight: 400 !important;
  letter-spacing: 0;
}
</style>
