<template>
  <div id="message-panel" class="mt-4">
    <v-textarea
      v-model="message"
      hide-details
      :label="i18n.t('labels.add_message')"
      variant="outlined"
      data-test-id="add-message"
      @blur="updateSimpleDocumentMessage"
      @focus="setInitialMessage"
    />
  </div>
</template>

<script setup lang="ts">
import DOMPurify from 'dompurify';
import { ref } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { useI18n } from 'vue-i18n';
import { VerificationState } from '@/types/enums/VerificationState';
import { useDocumentContext } from '@/composables/useDocumentContext';

defineProps({
  forRequestor: {
    type: Boolean,
    default: true
  }
});

const { currentDocument } = useDocumentContext();

const message = ref(currentDocument.value ? currentDocument.value.comment : '');
const initialMessage = ref('');
const emitter = useEmitter();
const i18n = useI18n();

function setInitialMessage() {
  if (message.value) {
    initialMessage.value = message.value;
  }
}

function updateSimpleDocumentMessage() {
  if (message.value !== initialMessage.value) {
    let sanitizedMessage;
    if (
      currentDocument.value &&
      currentDocument.value.initiatorCompanyVerificationType ===
        VerificationState.STRONG
    ) {
      if (message.value) {
        sanitizedMessage = DOMPurify.sanitize(message.value, {
          ALLOWED_TAGS: [
            'a',
            'abbr',
            'acronym',
            'b',
            'blockquote',
            'br',
            'code',
            'em',
            'i',
            'li',
            'ol',
            'strong',
            'ul'
          ],
          ALLOWED_ATTR: ['href', 'src'],
          ALLOWED_URI_REGEXP:
            /^(?:(?:(?:f|ht)tps?|mailto|tel):|[^a-z]|[a-z+.-]+(?:[^a-z+.\-:]|$))/i
        }).replaceAll('\n', '<br />');
      }
    } else if (message.value) {
      sanitizedMessage = DOMPurify.sanitize(message.value);
    }
    emitter.$emit('update-signature-message', sanitizedMessage);
  }
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 100%;
}

.mobile-container {
  padding: 12px 20px 40px 20px;
  max-width: 100%;
}

.no-padding {
  padding: 0;
}

.sub-expansion-panel-content :deep(.v-expansion-panel-content__wrap) {
  margin-top: 8px;
  padding: 0 !important;
}
</style>
