<template>
  <v-tooltip
    location="right"
    content-class="tooltip deep-light tooltip-right tooltip-preview"
    :open-on-click="device.isMobile"
    open-delay="600"
  >
    <template #activator="{ props: ActivatorProps }">
      <div v-bind="ActivatorProps">
        <slot name="activator"></slot>
      </div>
    </template>
    <template #default>
      <div class="document-thumbnail-container">
        <img
          v-if="thumbnailUrl"
          class="bg-contain max-h-[184px]"
          :src="thumbnailUrl"
          alt=""
        />
        <document-placeholder v-else />
      </div>
    </template>
  </v-tooltip>
</template>

<script setup lang="ts">
import { DeviceKey } from '@/plugins/device-detector-js';
import { inject } from 'vue';

const device = inject(DeviceKey);

defineProps({
  thumbnailUrl: {
    type: String,
    default: undefined
  }
});
</script>

<style scoped lang="postcss">
.tooltip {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}

.tooltip-content {
  color: white;
  max-width: 238px !important;
}

.tooltip-preview {
  opacity: 1 !important;
  background-color: white;
}

.document-thumbnail-container {
  @apply flex items-center justify-center h-[224px] min-h-[185px] w-[164px];
  border: solid 1px #cfd4d9;
  border-radius: 3px;
  background-color: #f8f9fa;
}
</style>
