<template>
  <div class="d-flex flex-column">
    <div class="sms-code-title">
      {{ i18n.t('titles.take_your_phone') }}
    </div>
    <div class="d-flex flex-column" style="max-width: 370px">
      <div class="hint-text mt-4">
        {{ i18n.t('texts.advanced_signature_instructions') }}
      </div>
      <MobileNumberCard class="my-6" :phone-number="phoneNumber" />
      <v-card
        rounded="lg"
        color="#FAFAFA"
        class="pa-6 mb-8 text-center mv"
        width="100%"
        flat
      >
        <div class="max-h-48 overflow-y-auto mb-4">
          <div
            v-for="document in documents"
            :key="`document-name-${document.documentName}`"
            class="filename deep-truncate"
          >
            {{ document.documentName }}
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import type { Document } from '@/types/Document';
import { type PropType } from 'vue';

defineProps({
  documents: {
    type: Object as PropType<Document[]>,
    default: undefined
  },
  phoneNumber: {
    type: String,
    default: ''
  }
});
const i18n = useI18n();
</script>

<style scoped lang="scss">
.filename {
  font-weight: 590;
  font-size: 16px;
  line-height: 19px;
  color: #121212;
}
.hint-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.sms-code-title {
  text-align: center;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
}
</style>
