<template>
  <div v-if="isLoading" class="overlay" :style="{ zIndex: zIndex }">
    <div
      class="overlay__scrim"
      :style="{ opacity }"
      style="
        background-color: rgb(255, 255, 255);
        border-color: rgb(255, 255, 255);
      "
    />
    <div class="overlay__content">
      <svg
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :stroke="color"
      >
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".25" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="0.8s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
      <div class="overlay__content_text" style="color: #000">
        {{ loadingMsg }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { onUnmounted } from 'vue';
defineProps({
  opacity: {
    type: String,
    default: '0.6'
  },
  zIndex: {
    type: String,
    default: '255'
  },
  height: {
    type: Number,
    default: 64
  },
  width: {
    type: Number,
    default: 64
  },
  color: {
    type: String,
    default: '#0052FF'
  }
});
const isLoading = ref(false);
const loadingMsg = ref<string | undefined>(undefined);
const emitter = useEmitter();

emitter.$on('show_loader', show);
emitter.$on('hide_dialog', hide);

onUnmounted(() => {
  emitter.$off('show_loader', show);
  emitter.$off('hide_dialog', hide);
});

function show(loadingMessage?: string) {
  isLoading.value = true;
  loadingMsg.value = loadingMessage;
}
function hide() {
  isLoading.value = false;
  loadingMsg.value = undefined;
}
</script>
<style scoped>
.overlay {
  align-items: center;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition:
    0.3s cubic-bezier(0.25, 0.8, 0.5, 1),
    z-index 1ms;
}

.overlay__scrim {
  border-radius: inherit;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: inherit;
  width: 100%;
  will-change: opacity;
}

.overlay__content {
  position: relative;
  text-align: center;
}

.overlay__content_text {
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}
</style>
