import AuthService from '@/services/auth-service';
import { computed } from 'vue';
import { useSignStore } from '@/stores/sign/sign';
import { useRoute } from 'vue-router';
import { SignStatus } from '@/types/enums/SignStatus';
import { DocumentStatus } from '@/types/enums/DocumentStatus';

export function useSignContext() {
  const route = useRoute();
  const signStore = useSignStore();

  const isGuest = computed(() => {
    return !AuthService.isAuthenticated;
  });

  const signKey = computed(() => {
    if (signStore.signInfo?.signKey) {
      return signStore.signInfo.signKey;
    } else {
      return route.params.id;
    }
  });

  const signatureType = computed(() => signStore.signInfo?.signatureType);
  const signStatus = computed(() => signStore.signInfo?.signStatus);

  const userEmail = computed(() => {
    if (isGuest.value) {
      return signStore.userEmail;
    } else {
      return AuthService.user?.email;
    }
  });

  const userSignees = computed(() => {
    return signStore.signInfo?.document.signees.filter(
      (signee) => signee.email === userEmail.value
    );
  });

  const userStatusPage = computed(() => {
    if (
      signatureType.value === 'seal' &&
      signStatus.value === SignStatus.PENDING
    ) {
      return 'document-seal';
    } else if (
      signatureType.value === 'seal' &&
      signStatus.value === SignStatus.SIGNED
    ) {
      return 'document-details';
    } else if (isGuest.value || route.name === 'sign-id') {
      return 'sign-id';
    } else if (
      userSignees.value &&
      userSignees.value.some(
        (signee) => signee.signStatus !== SignStatus.SIGNED
      ) &&
      signStore.signInfo?.document.documentStatus === DocumentStatus.IN_PROGRESS
    ) {
      return 'document-sign';
    } else if (
      userSignees.value ||
      signStore.signInfo?.document.documentStatus === DocumentStatus.WITHDRAWN
    ) {
      return 'document-details';
    } else {
      return null;
    }
  });

  const policy = computed(() => {
    return signStore.signInfo?.policy || {};
  });

  const signeeId = computed(() => {
    return signStore.signInfo?.signeeId || undefined;
  });

  const requestorEmail = computed(() => {
    return signStore.signInfo?.document?.initiatorDisplayEmail || undefined;
  });

  const isRequestor = computed(() => {
    return (
      requestorEmail.value?.toLowerCase() === userEmail.value?.toLowerCase()
    );
  });

  const jurisdiction = computed(() => {
    return signStore.signInfo?.document.jurisdiction;
  });

  const isSignatureTypeSeal = computed(() => {
    return signStore.signInfo?.signatureType === 'seal';
  });

  const availableSeals = computed(() => {
    if (signStore.signInfo && signStore.signInfo.availableSeals) {
      return signStore.signInfo?.availableSeals;
    } else {
      return [];
    }
  });

  return {
    isGuest,
    isRequestor,
    isSignatureTypeSeal,
    jurisdiction,
    policy,
    requestorEmail,
    signKey,
    signeeId,
    userEmail,
    userSignees,
    userStatusPage,
    availableSeals,
    signatureType,
    signStatus
  };
}
