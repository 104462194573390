<template>
  <v-navigation-drawer
    v-model="globalStore.drawer"
    :permanent="$vuetify.display.lgAndUp"
    location="right"
    width="484"
    touchless
  >
    <AppHeader class="bg-white shadow-lg">
      <template #start>
        <div class="flex items-center gap-3 pl-4">
          <RouterLink :to="{ path: '/' }">
            <DeepSignLogo class="!px-0"></DeepSignLogo>
          </RouterLink>
          <span class="text-gray-300">|</span>
          <img alt="Swiss Made" height="16px" src="/svg/swiss_made.svg" />
        </div>
      </template>
      <template #end>
        <UserAccountMenu />
      </template>
    </AppHeader>

    <!-- DRAWER CONTENT -->
    <div class="px-4 md:pb-6">
      <template v-if="isCreate">
        <CreateCard v-if="currentDocument" />
        <PositionAndMessageCard
          @enforce-authority-service="enforceAuthorityService"
        />
        <!-- ACTIONS -->
        <div class="w-full">
          <DeepButton
            ref="sendInvitationsButton"
            data-test-id="continue"
            :action="proceed"
            :disabled="isInvalid"
            :loading="
              startDocumentPending ||
              clearingEmptyInvitations ||
              clearingEmptyObservers
            "
            :text="buttonText"
          />
          <DeepButton
            v-if="
              currentDocument.documentUrl &&
              currentDocument.documentStatus !== DocumentStatus.DRAFT
            "
            class="mt-2"
            :action="download"
            variant="outlined"
            :text="i18n.t('buttons.download')"
          />
        </div>
        <!-- /ACTIONS -->
        <ConfirmationDialog ref="confirmationDialog" />
      </template>
      <div v-else class="mt-3">
        <SealingCard
          v-if="route.name === 'document-seal'"
          :signatures="currentDocument.signees"
          :document="currentDocument"
          :can-sign="canSign"
        />
        <SigningCard
          v-else-if="shouldSign"
          :can-add-autograph="canAddAutograph"
          :can-sign="canSign"
          :document="currentDocument"
          :email="userEmail"
          :is-signed-by-user="isSignedByUser"
          :sign="sign"
          :sign-button-enabled="canSign"
          :signatures="currentDocument.signees"
          @update-authority-service="setAuthorityService"
        />
        <ObserverCard v-else />
        <DocumentInfo
          :document="currentDocument"
          :signees="currentDocument.signees"
          :sing-key="signKey"
          @update-signature-message="updateSignatureComment"
        />
        <ConfirmationDialog ref="signConfirmationDialog" />
        <TimeOutDialog ref="timeOutDialog" />
        <SignatureCancelledDialog />
        <SignErrorDialog />
        <SignatureEdition ref="signatureEdition" />
      </div>
    </div>

    <!-- /DRAWER CONTENT -->

    <template v-if="$vuetify.display.mdAndDown" #append>
      <v-footer style="height: 60px; background-color: white">
        <DeepButton
          :action="
            () => {
              globalStore.drawer = !globalStore.drawer;
            }
          "
          variant="outlined"
          :text="i18n.t('buttons.close')"
        />
      </v-footer>
    </template>
  </v-navigation-drawer>
</template>

<script setup>
import { DeepSignLogo } from '@deepfront/logo';
import { AppHeader } from '@deepfront/landmarks';
import AuthService from '@/services/auth-service';
import SignatureService from '@/services/signatureService';
import { AuthorityService } from '@/types/enums/AuthorityService.ts';
import { DocumentStatus } from '@/types/enums/DocumentStatus';
import { Jurisdiction } from '@/types/enums/Jurisdiction.ts';
import { SignStatus } from '@/types/enums/SignStatus';
import { SignatureMode } from '@/types/enums/SignatureMode.ts';
import { base64ToFile } from '@/composables/useUtils';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { storeStartedDocument } from '@/api/deepsign/start';
import { useDeepAdminStore } from '@/stores/deepadmin/deepadmin';
import { useDocumentStore } from '@/stores/document/document';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';
import { useMessageStore } from '@/stores/message/message';
import { usePreferencesStore } from '@/stores/deepadmin/preferences';
import { useRouter, useRoute } from 'vue-router';
import { useSignStore } from '@/stores/sign/sign';
import { useSigneeStore } from '@/stores/signee/signee.ts';
import { useSubscriptionStore } from '@/stores/subscription/subscription';
import { useGlobalStore } from '@/stores/global/global';
import { useSignContext } from '@/composables/useSignContext';
import { downloadDocumentPdf } from '@/services/documentService';
import { useDocumentAuth } from '@/composables/useDocumentAuth';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { useDisplay } from 'vuetify';
import { useEmailService } from '@/components/inputs/Emailsuggestion/useEmailService';

const { isDocumentAuthRoute, currentDocument } = useDocumentContext();

const agreement = ref(false);
const authorityService = ref(null);
const autographData = ref(null);
const clearingEmptyInvitations = ref(false);
const clearingEmptyObservers = ref(false);
const confirmationDialog = ref();
const deepadminStore = useDeepAdminStore();
const documentStore = useDocumentStore();
const display = useDisplay();
const globalStore = useGlobalStore();
const emitter = useEmitter();
const i18n = useI18n();
const jurisdiction = ref(Jurisdiction.ZERTES);
const message = ref('');
const messageStore = useMessageStore();
const pollInterval = ref(null);
const preferencesStore = usePreferencesStore();
const router = useRouter();
const route = useRoute();
const signConfirmationDialog = ref();
const signStore = useSignStore();
const signatureLevel = ref(SignatureMode.TIMESTAMP);
const signeeFields = ref([]);
const startDocumentPending = ref(false);
const subscriptionStore = useSubscriptionStore();
const useDefaultAutograph = ref(false);
const { removeSignee } = useSigneeStore();
const { isGuest, userEmail, isSignatureTypeSeal, signKey } = useSignContext();
const { saveRecentlyUsedEmails } = useEmailService(
  currentDocument.value.companyId
);

defineEmits(['update-sign-status']);

emitter.$on('change-autograph', updateChangeAutograph);
emitter.$on('stop-sign-status-polling', stopPolling);
emitter.$on('update-agreement', updateAgreementStatus);
emitter.$on('update-attachments-option', updateAttachmentsAllow);
emitter.$on('update-authority-service', updateAuthorityService);
emitter.$on('update-autograph-data', updateSignatureData);
emitter.$on('update-signature-level', updateSignatureLevel);
emitter.$on('update-signature-message', updateSignatureMessage);
emitter.$on('update-signee-fields', updateSigneeFields);

globalStore.drawer = display.lgAndUp.value;

onMounted(() => {
  useDefaultAutograph.value = signStore.signInfo?.defaultAutographUrl !== null;
});

onUnmounted(() => {
  emitter.$off('change-autograph', updateChangeAutograph);
  emitter.$off('stop-sign-status-polling', stopPolling);
  emitter.$off('update-agreement', updateAgreementStatus);
  emitter.$off('update-attachments-option', updateAttachmentsAllow);
  emitter.$off('update-authority-service', updateAuthorityService);
  emitter.$off('update-autograph-data', updateSignatureData);
  emitter.$off('update-signature-level', updateSignatureLevel);
  emitter.$off('update-signature-message', updateSignatureMessage);
  emitter.$off('update-signee-fields', updateSigneeFields);
});

const documentAuth = useDocumentAuth();

const isDraftMode = computed(() => route.name === 'document-create');

const isAdvancedEidas = computed(() => {
  return (
    currentDocument.value.signatureMode === SignatureMode.ADVANCED &&
    currentDocument.value.jurisdiction === Jurisdiction.EIDAS
  );
});

const isBasic = computed(() => {
  return currentDocument.value.signatureMode === SignatureMode.TIMESTAMP;
});

const isCreate = computed(() => {
  return currentDocument.value.documentStatus === DocumentStatus.DRAFT;
});

const isInvalid = computed(() => {
  // Check we have at least one signee
  if (!documentStore.hasSignees) return true;

  // If we only have empty fields
  if (documentStore.hasOnlyEmptySignees) return true;

  // Check all strings are valid emails
  let hasInvalidStrings = false;
  if (signeeFields.value) {
    signeeFields.value
      .filter((s) => {
        return s.email !== null && s.email !== '';
      })
      .forEach((signee) => {
        if (validateEmail(signee.email)) {
          hasInvalidStrings = true;
        }
      });
  }
  return hasInvalidStrings;
});

const isMissingSubscription = computed(() => {
  return !subscriptionStore.hasValidSubscription;
});

const canAddAutograph = computed(() => {
  const mySignature = SignatureService.getSigneeByEmail(
    AuthService.user?.email,
    currentDocument.value.signees
  );
  return (
    canSign.value &&
    (mySignature?.autographPosition !== null ||
      signStore.signInfo?.canModifyAutographPosition)
  );
});

const canManageSubscriptions = computed(() => {
  if (isGuest.value) return false;
  if (!documentStore.document?.companyId) return false;
  const documentCompany = deepadminStore.userInfo?.companies.find(
    (company) => company.group_id === documentStore?.document?.companyId
  );
  return documentCompany.roles.includes('ORDER_SUB');
});

const canSign = computed(() => {
  if (isGuest.value) {
    return signStore.signInfo?.signStatus === SignStatus.PENDING;
  }
  if (AuthService.isAuthenticated) {
    return SignatureService.canSign(
      AuthService.user?.email,
      currentDocument.value
    );
  }
  return false;
});

const destination = computed(() => {
  if (SignatureService.onlyMe(AuthService.user?.email, currentDocument.value)) {
    return `/document/${currentDocument.value.documentId}/sign`;
  }
  return `/document/${currentDocument.value.documentId}/invitations-sent`;
});

const confirmationTitle = computed(() => {
  if (documentStore.hasEmptyObservers && documentStore.hasEmptySignees) {
    // Empty signees and observers
    return i18n.t('titles.undefined_signees_and_observers');
  } else if (documentStore.hasEmptySignees) {
    // Only empty signees
    return i18n.t('titles.undefined_signees');
  } else if (documentStore.hasEmptyObservers) {
    // Only empty observers
    return i18n.t('titles.undefined_observers');
  } else {
    return i18n.t('titles.no_observers_subscription_title');
  }
});

const confirmationText = computed(() => {
  if (documentStore.hasEmptyObservers && documentStore.hasEmptySignees) {
    // Empty signees and observers
    return i18n.t(
      'titles.undefined_signees_and_observers_confirmation_request'
    );
  } else if (documentStore.hasEmptySignees) {
    // Only empty signees
    return i18n.t('titles.undefined_signees_confirmation_request');
  } else if (documentStore.hasEmptyObservers) {
    // Only empty observers
    return i18n.t('titles.undefined_observers_confirmation_request');
  } else if (canManageSubscriptions.value) {
    return i18n.t('titles.no_observers_subscription_dialog', {
      link: `${
        import.meta.env.VITE_DEEPBOX_ADMIN_FRONTEND_BASE_URL
      }/organization/${currentDocument.value.companyId}/subscription`
    });
  } else {
    return i18n.t('titles.no_observers_subscription_dialog_ask_owners');
  }
});

const buttonText = computed(() => {
  if (SignatureService.onlyMe(AuthService.user?.email, currentDocument.value)) {
    return i18n.t('buttons.continue');
  }
  return i18n.t('buttons.send_invitations');
});

const signStatus = computed(() => {
  return signStore.signInfo?.signStatus;
});

watch(
  () => signStatus.value,
  (newValue, oldValue) => {
    if (newValue !== SignStatus.IN_PROGRESS) {
      stopPolling();
      emitter.$emit('close-sign-pending-dialog');
      // If signStatus is 'pending', we have to check if there is an error and display it
      if (newValue === SignStatus.PENDING) {
        if (!signStore.signInfo?.lastError) {
          return;
        } else if (
          signStore.signInfo.lastError?.messageId === 'error.sign.user.timeout'
        ) {
          emitter.$emit('show-timeout-dialog');
        } else if (
          signStore.signInfo.lastError?.messageId === 'error.sign.user.cancel'
        ) {
          emitter.$emit('show-signature-cancelled-dialog');
        } else {
          emitter.$emit('show-sign-error-dialog', {
            errorKey: signStore.signInfo.lastError?.messageId,
            authorityService: authorityService.value
          });
        }
      }
      // If signStatus is 'signed' check if we need to redirect to correct page
      if (newValue === SignStatus.SIGNED) {
        stopPolling();
        const signature = document.getElementById(
          `signature-preview-${signStore.userSigneeId}`
        );
        if (signature) {
          signature.parentNode.removeChild(signature);
        }
        // Need to do this check to avoid showing the success message twice
        if (newValue !== oldValue) {
          if (!isGuest.value && isSignedByUser.value) {
            router.push(
              `/document/${currentDocument.value.documentId}/details`
            );
          }
          emitter.$emit('update-sign-status');

          messageStore.showMessage({
            key: isSignatureTypeSeal.value
              ? 'snackbars.sealed_text'
              : 'snackbars.signed_text',
            color: 'success',
            icon: 'fa fa-circle-check'
          });
        }
      }
    }
  },
  {
    deep: true
  }
);

function validateEmail(email) {
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;
  return !reg.test(email);
}

function updateSigneeFields(value) {
  signeeFields.value = value;
}

function updateSignatureComment(newComment) {
  message.value = newComment;
}

async function updateSignatureMessage(newMessage) {
  try {
    message.value = newMessage;
    await documentStore.updateDocumentById(currentDocument.value.documentId, {
      comment: newMessage
    });
  } catch (error) {
    console.error('error adding document comment ', error);
  }
}

async function updateAttachmentsAllow(value) {
  if (value !== currentDocument.value.signeeAttachmentsAllowed) {
    try {
      await documentStore.updateDocumentById(currentDocument.value.documentId, {
        signeeAttachmentsAllowed: value
      });
    } catch (error) {
      console.error('error updating attachments option ', error);
    }
  }
}

async function updateSignatureLevel({ level, newJurisdiction }) {
  signatureLevel.value = level;
  jurisdiction.value = newJurisdiction;

  try {
    await documentStore.updateDocumentById(currentDocument.value.documentId, {
      signatureMode: signatureLevel.value,
      jurisdiction: jurisdiction.value,
      signeeAttachmentsAllowed: currentDocument.value.signeeAttachmentsAllowed
    });
    if (
      level === SignatureMode.ADVANCED &&
      newJurisdiction === Jurisdiction.EIDAS
    ) {
      emitter.$emit('set-authority-service', AuthorityService.DEEP_ID);
    }
  } catch (error) {
    console.error('error updating document signature level ', error);
  }
}

async function enforceAuthorityService(serviceKey) {
  try {
    await documentStore.updateDocumentById(currentDocument.value.documentId, {
      requiredAuthorityService: serviceKey
    });
  } catch (error) {
    console.warn('error enforcing authority service', error);
  }
}

async function updateAuthorityService(authorityService) {
  try {
    let jurisdiction = currentDocument.value.jurisdiction;
    if (authorityService !== AuthorityService.DEEP_ID) {
      jurisdiction = Jurisdiction.ZERTES;
    }
    await documentStore.updateDocumentById(currentDocument.value.documentId, {
      authorityService,
      jurisdiction
    });
  } catch (error) {
    console.error('error updating document authority service ', error);
  }
}

async function proceed() {
  if (!documentStore.hasEmptySignees && !documentStore.hasEmptyObservers) {
    if (isDraftMode.value) {
      try {
        saveRecentlyUsedEmails(currentDocument.value.signees);
      } catch (e) {
        console.error('saveRecentlyUsedEmails ', e);
      }
    }

    try {
      startDocumentPending.value = true;
      await storeStartedDocument(currentDocument.value.documentId);
      currentDocument.value.documentStatus = DocumentStatus.IN_PROGRESS;
      startDocumentPending.value = false;
      // emit open invitations dialog
      if (
        SignatureService.onlyMe(
          AuthService.user?.email,
          currentDocument.value
        ) &&
        !isDocumentAuthRoute.value
      ) {
        await router.push(destination.value);
      } else {
        emitter.$emit('open-invitations-dialog');
      }
    } catch (error) {
      startDocumentPending.value = false;
      console.error(error);
    }
  } else {
    confirmationDialog.value
      .open(confirmationTitle.value, confirmationText.value, null, {
        noCancel:
          isMissingSubscription.value &&
          !documentStore.hasEmptySignees &&
          !documentStore.hasEmptyObservers,
        confirmActionText:
          isMissingSubscription.value &&
          !documentStore.hasEmptySignees &&
          !documentStore.hasEmptyObservers
            ? i18n.t('buttons.close')
            : null
      })
      .then(async (result) => {
        if (result) {
          if (
            isMissingSubscription.value &&
            !documentStore.hasEmptySignees &&
            !documentStore.hasEmptyObservers
          ) {
            return;
          }
          clearingEmptyInvitations.value = true;
          clearingEmptyObservers.value = true;

          let promises = [];
          for (const signee of currentDocument.value.signees) {
            if (signee.email === null) {
              promises.push(
                removeSignee({
                  documentId: currentDocument.value.documentId,
                  signeeId: signee.signeeId
                })
              );
            }
          }
          await Promise.all(promises);
          clearingEmptyInvitations.value = false;

          promises = [];
          for (const observer of currentDocument.value.observers) {
            if (observer.email === null) {
              promises.push(
                documentStore.deleteObserver(
                  currentDocument.value.documentId,
                  observer.observerId
                )
              );
            }
          }
          await Promise.all(promises);
          clearingEmptyObservers.value = false;

          if (!isMissingSubscription.value) {
            await storeStartedDocument(currentDocument.value.documentId);
            currentDocument.value.documentStatus = DocumentStatus.IN_PROGRESS;
            // emitt open invitations dialog
            if (
              SignatureService.onlyMe(
                AuthService.user?.email,
                currentDocument.value
              ) &&
              !documentAuth.isAuthenticated.value
            ) {
              await router.push(destination.value);
            } else {
              emitter.$emit('open-invitations-dialog');
            }
          } else {
            await proceed();
          }
        }
      });
  }
}

const shouldSign = computed(() => {
  if (isDocumentAuthRoute.value) return false;
  if (isGuest.value) {
    return currentDocument.value.signees.some((signee) => {
      return (
        signee.email === userEmail.value &&
        currentDocument.value.documentStatus !== DocumentStatus.WITHDRAWN &&
        currentDocument.value.documentStatus !== DocumentStatus.REJECTED
      );
    });
  } else {
    return (
      currentDocument.value.signees.some((signee) =>
        SignatureService.compareString(signee.email, AuthService.user?.email)
      ) &&
      currentDocument.value.documentStatus !== DocumentStatus.WITHDRAWN &&
      currentDocument.value.documentStatus !== DocumentStatus.REJECTED
    );
  }
});

const isSignedByUser = computed(() => {
  if (isGuest.value) {
    return SignatureService.isSignedByUser(
      userEmail.value,
      signStore.signInfo?.document?.signees || []
    );
  }
  return SignatureService.isSignedByUser(
    AuthService.user?.email,
    currentDocument.value.signees
  );
});

function setAuthorityService(newAuthorityService) {
  authorityService.value = newAuthorityService;
}

function getAutographData() {
  if (autographData.value) {
    return base64ToFile(autographData.value, 'autograph.png');
  }
  if (signStore.signInfo?.defaultAutographUrl) {
    return base64ToFile(
      signStore.signInfo?.defaultAutographUrl,
      'autograph.png'
    );
  }
  return null;
}

function checkAsynchronousSign() {
  try {
    if (signStore.asynchronousSign?.signStatus === SignStatus.IN_PROGRESS) {
      pollInterval.value = setInterval(() => {
        signStore.fetchAuthSignStatus(signStore.signInfo?.signKey);
      }, 2500);
      emitter.$emit('show-sign-pending-dialog', {
        document: currentDocument.value,
        authorityService: authorityService.value
      });
    }
  } catch (error) {
    signStore.signPending = false;
    console.error('Error polling sign status: ', error);
  }
}

function updateSignatureData(eventData) {
  autographData.value = eventData;
}

function updateAgreementStatus(eventData) {
  agreement.value = eventData;
}

function updateChangeAutograph() {
  useDefaultAutograph.value = false;
}

function stopPolling() {
  clearInterval(pollInterval.value);
}

async function sign() {
  try {
    signStore.signPending = true;
    let canProceed = false;

    // We need to show attachments hint  if attachments are allowed and none was added
    if (
      signStore.signInfo?.policy.canModifySigneeAttachments &&
      signStore.signInfo?.signeeAttachments.length === 0
    ) {
      await signConfirmationDialog.value
        .open(
          i18n.t('titles.no_attachments'),
          i18n.t('texts.no_attachments_hint'),
          null,
          { confirmActionText: i18n.t('buttons.sign_anyway') }
        )
        .then((result) => {
          if (result) {
            canProceed = true;
          } else {
            signStore.signPending = false;
          }
        });
    } else {
      canProceed = true;
    }

    if (canProceed) {
      let payload = null;

      // When user is not authenticated he needs to confirm accepting agreements
      if (!AuthService.isAuthenticated) {
        await signConfirmationDialog.value
          .open(
            i18n.t('titles.signature_confirmation'),
            i18n.t('terms.guest_ses'),
            null,
            { confirmActionText: i18n.t('buttons.confirm'), width: 417 }
          )
          .then((result) => {
            canProceed = !!result;
            signStore.signPending = canProceed;
          });
      } else if (
        currentDocument.value.signatureMode !== SignatureMode.TIMESTAMP &&
        authorityService.value !== AuthorityService.DEEP_ID
      ) {
        // Mobile ID confirmation
        const textKey = `terms.${currentDocument.value.jurisdiction}`;
        await signConfirmationDialog.value
          .open(
            i18n.t('titles.signature_confirmation'),
            i18n.t(textKey),
            null,
            { confirmActionText: i18n.t('buttons.confirm'), width: 417 }
          )
          .then((result) => {
            canProceed = result;
            signStore.signPending = canProceed;
          });
      }

      // Can finally sign the document
      if (canProceed) {
        // Sign SES as guest
        if (isGuest.value) {
          payload = {
            signKey: signStore.signInfo.signKey,
            sign: {
              autographPosition: signStore.signInfo?.canModifyAutographPosition
                ? signStore.getPlacedSignaturePosition
                : null,
              comment: message.value
            },
            autograph: await getAutographData()
          };
          try {
            await signStore.signDocument(payload);
            signStore.signPending = false;
          } catch (error) {
            signStore.signPending = false;
            emitter.$emit('show-sign-error-dialog', {
              errorKey: error.response.data?.messageId,
              authorityService: authorityService.value,
              documentErrors: error.response.data?.details
            });
          }
        } else {
          // Sign as authenticated user
          if (!isBasic.value && !isAdvancedEidas.value) {
            await preferencesStore.setPreference(
              'defaultAuthorityService',
              authorityService.value
            );
          }
          payload = {
            signKey: signStore.signInfo.signKey,
            sign: {
              comment: message.value,
              authorityService: authorityService.value,
              autographPosition: signStore.signInfo?.canModifyAutographPosition
                ? signStore.getPlacedSignaturePosition
                : null
            },
            autograph: await getAutographData()
          };

          try {
            await signStore.signAuthDocument(payload);
            signStore.signPending = false;
          } catch (error) {
            emitter.$emit('show-sign-error-dialog', {
              errorKey: error.response.data?.messageId,
              authorityService: authorityService.value,
              documentErrors: error.response.data?.details
            });
          }
          checkAsynchronousSign();
        }
      }
    }
  } catch (error) {
    signStore.signPending = false;
    console.error('Error signing the document', error);
  }
}

async function download() {
  try {
    await downloadDocumentPdf(
      currentDocument.value.signKey,
      currentDocument.value.documentName,
      currentDocument.value.documentStatus,
      currentDocument.value.documentId
    );
  } catch (error) {
    console.error('Error downloading document ', error);
  }
}
</script>
<style scoped lang="scss">
.drop-shadow {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
}
</style>
