<template>
  <v-menu
    class="mb-4"
    :disabled="authorityServices.length < 2"
    location="bottom"
  >
    <template #activator="{ props: ActivatorProps }">
      <div
        v-bind="ActivatorProps"
        class="d-flex flex-row flex-shrink-1 justify-center align-center ml-1 cursor-pointer"
        data-test-id="authority-service-menu"
        :class="{ disabled: authorityServices.length < 2 }"
        :data-test-selected="authorityServiceKey"
      >
        <v-img
          v-if="showLogo"
          class="ma-0 mr-1"
          :class="{
            ras: authorityServiceKey === 'ras',
            did: authorityServiceKey === 'did'
          }"
          height="14"
          :src="`/svg/authority-services/${authorityServiceKey}-logo.svg`"
        />
        <div v-else class="d-flex justify-end align-end mr-1 mt-1 sms-code">
          {{ i18n.t('authorities.sms_code') }}
        </div>
        <ChevronDown
          v-if="authorityServices.length > 1"
          color="black"
          :size="16"
        />
      </div>
    </template>
    <v-card class="pa-4" rounded="lg" data-test-id="id-service-card">
      <v-list class="pa-0" data-test-id="id-service-list">
        <AuthorityServiceItem
          v-for="(service, index) in authorityServices"
          :key="service.key"
          :signature-mode="signatureMode"
          :active="service.key === authorityServiceKey"
          :authority-service="service.key"
          :class="{ 'mb-4': index !== authorityServices.length - 1 }"
          :data-test-id="`${service.key}`"
        />
      </v-list>
    </v-card>
  </v-menu>
</template>

<script lang="ts" setup>
import { SignatureMode } from '@/types/enums/SignatureMode.ts';
import { AuthorityService } from '@/types/enums/AuthorityService.ts';
import { computed } from 'vue';
import { ChevronDown } from 'lucide-vue-next';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  authorityServices: { type: Array, default: () => [] },
  authorityService: { type: Object, default: null },
  signatureMode: { type: String, required: true }
});

const i18n = useI18n();

const authorityServiceKey = computed(() => {
  return props.authorityService?.key || null;
});

const showLogo = computed(() => {
  if (props.signatureMode === SignatureMode.ADVANCED) {
    return authorityServiceKey.value === AuthorityService.DEEP_ID;
  } else {
    return (
      props.authorityService !== null && props.authorityService !== undefined
    );
  }
});
</script>

<style lang="scss" scoped>
.did {
  margin-bottom: -5px !important;
  width: 64px;
  object-fit: contain;
}
.ras {
  margin-bottom: -1px !important;
  width: 64px;
  object-fit: contain;
}

.sms-code {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #090909;
}

.disabled {
  &:hover {
    cursor: default;
  }
}
</style>
