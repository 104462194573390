import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import type { SignKeyInfo } from '@/types/SignKeyInfo';
import publicAxios from '@/plugins/publicAxios';
import type { SignDocument } from '@/types/SignDocument';
import type { AxiosRequestConfig } from 'axios';
import type { SignPayload } from '@/types/SignPayload';
import { useMessageStore } from '@/stores/message/message';
import { SignStatus } from '@/types/enums/SignStatus';
import type { SignAccepted } from '@/types/SignAccepted';
import type { RejectPayload } from '@/types/RejectPayload';
import type { PostAttachmentPayload } from '@/types/PostAttachmentPayload';
import type { DeleteAttachmentPayload } from '@/types/DeleteAttachmentPayload';
import type { AuthorityService } from '@/types/enums/AuthorityService';
import type { AutographPosition } from '@/types/AutographPosition';
import type { SignaturePosition } from '@/types/SignaturePosition';
import globalAxios from '@/composables/useAxios';
import { abortSignature } from '@/api/deepsign/sign-abort';
import type { ForwardSignee } from '@/types/deepsign/ForwardSignee';
import { forwardSignee } from '@/api/deepsign/sign-forward';
import {
  addSignee,
  deleteSignee,
  updateSignee,
  updateSigneeSignatureType
} from '@/api/deepsign/sign-signee';
import type { UpdateSignSignee } from '@/types/deepsign/UpdateSignSignee';
import type { SignSignee } from '@/types/SignSignee';
import deepSignApi from '@/api/deepsign/deepsignApi';
import { base64ToFile } from '@/composables/useUtils';
import { seal } from '@/api/deepsign/seal';

export const useSignStore = defineStore('sign', () => {
  // API base url
  const apiBaseUrl = import.meta.env.VITE_DEEPSIGN_API_BASE_URL;

  // Header config
  const config: AxiosRequestConfig = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  // Message store
  const messageStore = useMessageStore();

  // SignKeyInfo
  const signKeyInfo: Ref<SignKeyInfo | undefined> = ref();
  const fetchSignKeyInfoPending = ref(false);
  const signaturePosition = ref<SignaturePosition>();
  const signaturePlaced = ref(false);
  const pageDetails = ref();

  const userEmail = computed(() => signKeyInfo.value?.email);
  const getSignaturePosition = computed(() => signaturePosition.value);
  const getPlacedSignaturePosition = computed(() => {
    if (placedSignatures.value) {
      return placedSignatures.value.find(
        (sg) => sg.signeeId === userSigneeId.value
      )?.autographPosition;
    } else {
      return undefined;
    }
  });
  const isSignaturePlaced = computed(() => signaturePlaced.value);
  const getPageDetails = computed(() => pageDetails.value);
  const roles = computed(() => signKeyInfo.value?.types);
  const userSignees = computed(
    () =>
      signInfo.value?.document.signees.filter(
        (signee) => signee.email === userEmail.value
      ) ?? []
  );

  async function fetchSignKeyInfo(signKey: string) {
    try {
      fetchSignKeyInfoPending.value = true;
      const response = await publicAxios.get(`sign/${signKey}/info`);
      if (response?.data) {
        signKeyInfo.value = response.data as SignKeyInfo;
      }
      fetchSignKeyInfoPending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      fetchSignKeyInfoPending.value = false;
      return Promise.reject(error);
    }
  }

  // SignInfo
  const signInfo: Ref<SignDocument | null> = ref(null);
  const fetchSignInfoPending = ref(false);
  const fetchAuthSignInfoPending = ref(false);
  const asynchronousSign: Ref<SignAccepted | null> = ref(null);
  const fetchDocumentSignPending = ref(false);
  const asynchronousSignRequestDateTime: Ref<string> = ref('');

  //SealPreview
  const sealPreviewUrl = ref('');

  const isOrdered = computed(() => {
    return (signInfo.value?.document.signeesOrdered.length ?? 1) > 1;
  });

  const isSignee = computed(() => {
    return signInfo.value?.email !== null || false;
  });
  const placedSignatures = computed(
    () =>
      signInfo.value?.document?.signees.filter(
        (signee) => signee.autographPosition !== null
      ) || []
  );
  const userSigneeId = computed(() => signInfo.value?.signeeId ?? '');

  async function fetchSignInfo(signKey: string) {
    try {
      fetchSignInfoPending.value = true;
      const response = await publicAxios.get(`sign/${signKey}`);
      if (response?.data) {
        signInfo.value = response.data as SignDocument;
      }
      fetchSignInfoPending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      fetchSignInfoPending.value = false;
      return Promise.reject(error);
    }
  }

  async function fetchAuthSignInfo(signKey: string) {
    try {
      fetchAuthSignInfoPending.value = true;
      const response = await globalAxios.get(`${apiBaseUrl}sign/${signKey}`);
      if (response?.data) {
        signInfo.value = response.data as SignDocument;
      }
      fetchAuthSignInfoPending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      fetchAuthSignInfoPending.value = false;
      return Promise.reject(error);
    }
  }

  async function fetchDocumentSign(documentId: string) {
    fetchDocumentSignPending.value = true;
    const response = await globalAxios.get(
      `${apiBaseUrl}sign/document/${documentId}`
    );
    if (response?.data) {
      signInfo.value = response.data as SignDocument;
    }
    fetchDocumentSignPending.value = false;
  }

  // Sign Document
  const signPending = ref(false);
  const signDocumentPending = ref(false);
  const signAuthDocumentPending = ref(false);
  const fetchAuthSignStatusPending = ref(false);
  const fetchAuthSignStatusFullPending = ref(false);

  async function signDocument(payload: SignPayload) {
    try {
      signDocumentPending.value = true;
      const sign = JSON.stringify({
        comment: payload.sign.comment ?? '',
        autographPosition: payload.sign.autographPosition ?? null
      });
      const autograph = payload?.autograph || null;
      const formData = new FormData();

      formData.append('sign', new Blob([sign], { type: 'application/json' }));

      if (autograph) {
        formData.append('autograph', autograph);
      }

      const response = await publicAxios.post(
        `sign/${payload.signKey}`,
        formData,
        config
      );
      signInfo.value = response.data as SignDocument;
      signDocumentPending.value = false;
      messageStore.showMessage({
        key: 'snackbars.signed_text',
        color: 'success',
        icon: 'fa fa-circle-check'
      });
      return Promise.resolve(response);
    } catch (error) {
      signDocumentPending.value = false;
      return Promise.reject(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function sealDocument(payload: any) {
    try {
      signDocumentPending.value = true;
      const sign = JSON.stringify({
        autographPosition: payload.sign.autographPosition || null,
        sealId: payload.sealId
      });

      const formData = new FormData();
      formData.append('data', new Blob([sign], { type: 'application/json' }));

      const response = await seal(payload.signKey, formData, config);

      signInfo.value = response.data;
      signDocumentPending.value = false;
      return response;
    } catch (error) {
      signDocumentPending.value = false;
      return error;
    }
  }

  async function signAuthDocument(payload: SignPayload) {
    try {
      signAuthDocumentPending.value = true;
      const sign = JSON.stringify({
        comment: payload.sign.comment ?? '',
        autographPosition: payload.sign.autographPosition ?? null,
        authorityService: payload.sign.authorityService ?? null
      });
      const autograph = payload?.autograph || null;
      const formData = new FormData();
      formData.append('sign', new Blob([sign], { type: 'application/json' }));
      if (autograph) {
        formData.append('autograph', autograph);
      }

      const response = await globalAxios.post(
        `${apiBaseUrl}sign/${payload.signKey}`,
        formData,
        config
      );
      let signInfoTmp;
      if (response.status === 202) {
        asynchronousSign.value = response.data;
        asynchronousSignRequestDateTime.value = new Date().toISOString();
        signInfoTmp = signInfo.value;
      } else {
        signInfoTmp = response.data;
      }
      if (signInfoTmp.signStatus === SignStatus.SIGNED) {
        messageStore.showMessage({
          key: 'snackbars.signed_text',
          color: 'success',
          icon: 'fa fa-circle-check'
        });
      }
      signInfo.value = signInfoTmp;
      signAuthDocumentPending.value = false;
    } catch (error) {
      signAuthDocumentPending.value = false;
      signPending.value = false;
      return Promise.reject(error);
    }
  }

  async function fetchAuthSignStatus(signKey: string) {
    try {
      fetchAuthSignStatusPending.value = true;
      const response = await globalAxios.get(
        `${apiBaseUrl}sign/${signKey}/status`
      );
      if (signInfo.value) {
        signInfo.value.signStatus = response.data.signStatus as SignStatus;
        signInfo.value.lastError = response.data.lastError;
      }
      fetchAuthSignStatusPending.value = false;
      if (response.data.signStatus === SignStatus.SIGNED) {
        await fetchAuthSignStatusFull(signKey);
      }
      return Promise.resolve(response);
    } catch (error) {
      fetchAuthSignStatusPending.value = false;
      return Promise.reject(error);
    }
  }

  async function fetchAuthSignStatusFull(signKey: string) {
    try {
      fetchAuthSignStatusFullPending.value = true;
      const response = await globalAxios.get(`${apiBaseUrl}sign/${signKey}`);
      signInfo.value = response.data as SignDocument;
      fetchAuthSignStatusFullPending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      fetchAuthSignStatusFullPending.value = false;
      return Promise.reject(error);
    }
  }

  function updateSigneeAutographPosition(payload: SignaturePosition) {
    if (signInfo.value) {
      const index = signInfo.value.document.signees.findIndex(
        (signee) => signee.signeeId === payload.id
      );
      if (payload.page) {
        const autographPosition = {
          height: payload.signatureHeight,
          width: payload.signatureWidth,
          x: payload.positionX,
          y: payload.positionY,
          pageNumber: payload.page
        };
        // Update position
        if (signInfo.value) {
          signInfo.value.document.signees[index].autographPosition =
            autographPosition as AutographPosition;
        }
      } else {
        // Remove Position
        signInfo.value.document.signees[index].autographPosition = null;
      }
    }
  }

  function updateSigneeAutographPage(payload: {
    signeeId: string;
    page: number;
  }) {
    if (signInfo.value) {
      const index = signInfo.value.document.signees.findIndex(
        (signee) => signee.signeeId === payload.signeeId
      );

      signInfo.value.document.signees[index].autographPosition.pageNumber =
        payload.page;
    }
  }

  // Default autograph data

  // Reject Signature
  const rejectSignaturePending = ref(false);
  const rejectAuthSignaturePending = ref(false);

  async function rejectSignature(payload: RejectPayload) {
    try {
      rejectSignaturePending.value = true;
      const response = await publicAxios.put(
        `${apiBaseUrl}p/sign/${payload.signKey}/reject`,
        { comment: payload.comment }
      );
      rejectSignaturePending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      rejectSignaturePending.value = false;
      return Promise.reject(error);
    }
  }

  async function rejectAuthSignature(payload: RejectPayload) {
    try {
      rejectAuthSignaturePending.value = true;
      const response = await globalAxios.put(
        `${apiBaseUrl}sign/${payload.signKey}/reject`,
        { comment: payload.comment }
      );
      rejectAuthSignaturePending.value = false;
      messageStore.showMessage({
        key: 'snackbars.signature_rejected',
        color: 'success',
        icon: 'fa fa-circle-check'
      });
      return Promise.resolve(response);
    } catch (error) {
      rejectAuthSignaturePending.value = false;
      messageStore.showMessage({
        key: 'errors.api.reject_signature',
        color: 'error',
        icon: 'fa fa-circle-exclamation'
      });
      return Promise.reject(error);
    }
  }

  // SID
  const resendSidPending = ref(false);

  async function resendSid(signKey: string) {
    try {
      resendSidPending.value = true;
      const response = await publicAxios.post(`sign/${signKey}/resend-sid`);
      resendSidPending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      resendSidPending.value = false;
      messageStore.showMessage({
        key: 'errors.api.send_email',
        color: 'error',
        icon: 'fa fa-circle-exclamation'
      });
      return Promise.reject(error);
    }
  }

  // Attachments
  const postAttachmentPending = ref(false);
  const deleteAttachmentPending = ref(false);

  async function postAttachment(payload: PostAttachmentPayload) {
    try {
      postAttachmentPending.value = true;
      const formData = new FormData();
      formData.append('file', payload.file);

      let url;
      let response;

      if (!payload.isPublic) {
        url = `${apiBaseUrl}sign/${payload.signKey}/signee-attachments`;
        response = await globalAxios.post(url, formData, config);
      } else {
        url = `${apiBaseUrl}p/sign/${payload.signKey}/signee-attachments`;
        response = await publicAxios.post(url, formData, config);
      }
      if (signInfo.value) {
        signInfo.value?.signeeAttachments.push(response.data);
      }
      postAttachmentPending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      postAttachmentPending.value = false;
      return Promise.reject(error);
    }
  }

  async function deleteAttachment(payload: DeleteAttachmentPayload) {
    try {
      deleteAttachmentPending.value = true;
      let url;
      let response;

      if (!payload.isPublic) {
        url = `${apiBaseUrl}sign/${payload.signKey}/signee-attachments/${payload.signeeAttachmentId}`;
        response = await globalAxios.delete(url);
      } else {
        url = `${apiBaseUrl}p/sign/${payload.signKey}/signee-attachments/${payload.signeeAttachmentId}`;
        response = await publicAxios.delete(url);
      }
      deleteAttachmentPending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      deleteAttachmentPending.value = false;
      return Promise.reject(error);
    }
  }

  function enableAuthorityService(authorityService: AuthorityService) {
    if (signInfo.value) {
      signInfo.value.availableAuthorityServices[authorityService] = {
        hasRequiredLevel: true,
        signeeIssues: []
      };
    }
  }

  function abortSignatureProcess(signKey: string) {
    return abortSignature(signKey);
  }

  async function forward(signKey: string, payload: ForwardSignee) {
    return forwardSignee(signKey, payload);
  }

  async function addNewSignee(documentId: string, payload: ForwardSignee) {
    const response = await addSignee(documentId, payload);
    signInfo.value?.document.signees.push(response.data);
  }

  async function removeSignee(documentId: string, signeeId: string) {
    await deleteSignee(documentId, signeeId);
    if (signInfo.value?.document.signees) {
      signInfo.value.document.signees = signInfo.value.document.signees.filter(
        (signee) => signee.signeeId !== signeeId
      );
      if (signInfo.value.document.signeesOrdered.length > 1) {
        removeOrderedSigneeById(
          signInfo.value.document.signeesOrdered,
          signeeId
        );
      }
    }
  }

  function removeOrderedSigneeById(
    signeesOrdered: SignSignee[][],
    signeeId: string
  ) {
    for (let i = 0; i < signeesOrdered.length; i++) {
      const innerArray = signeesOrdered[i];
      const index = innerArray.findIndex(
        (signee) => signee.signeeId === signeeId
      );

      if (index !== -1) {
        innerArray.splice(index, 1);

        if (innerArray.length === 0) {
          signeesOrdered.splice(i, 1);
          i--;
        }
      }
    }
  }

  async function updateSigneeEmail(
    documentId: string,
    signeeId: string,
    payload: UpdateSignSignee
  ) {
    const response = await updateSignee(documentId, signeeId, payload);
    if (signInfo.value?.document.signees) {
      const signeeIndex = signInfo.value.document.signees.findIndex(
        (signee) => signee.signeeId === signeeId
      );
      if (signeeIndex >= 0) {
        signInfo.value.document.signees[signeeIndex] = response.data;
      }
    }
  }

  // eslint-disable-next-line prettier/prettier
  async function updateSignatureType(
    documentId: string,
    signeeId: string,
    signatureType: 'seal' | 'signature'
  ) {
    // eslint-disable-next-line prettier/prettier
    const response = await updateSigneeSignatureType(
      documentId,
      signeeId,
      signatureType
    );
    if (signInfo.value?.document.signees) {
      const signeeIndex = signInfo.value.document.signees.findIndex(
        (signee) => signee.signeeId === signeeId
      );
      if (signeeIndex >= 0) {
        signInfo.value.document.signees[signeeIndex] = response.data;
      }
    }
  }

  function removeObserver(observerId: string) {
    if (signInfo.value) {
      const observerIndex = signInfo.value.document.observers.findIndex(
        (observer) => observer.observerId === observerId
      );
      if (observerIndex >= 0) {
        signInfo.value?.document.observers.splice(observerIndex, 1);
      }
    }
  }

  // Signature Preview
  const autographData = ref<string | null>(null);
  const previewUrl = ref<string | null>(null);

  async function updateAutographImage() {
    const formData = new FormData();
    if (autographData.value && !autographData.value.startsWith('https')) {
      const fileImage = await base64ToFile(autographData.value, 'autograph');
      formData.append('autograph', fileImage);
      try {
        await deepSignApi.post('users/me/autograph', formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function getPreviewPayload() {
    const formData = new FormData();

    const data = JSON.stringify({
      sizeFactor: 3,
      optionalText: undefined,
      location: undefined
    });
    formData.append('data', new Blob([data], { type: 'application/json' }));

    if (autographData.value) {
      const autograph = await base64ToFile(autographData.value, 'autograph');
      formData.append('autograph', autograph);
    }

    return formData;
  }

  async function getGuestSignaturePreview(signKey: string) {
    const formData = await getPreviewPayload();
    const response = await publicAxios.post(
      `/sign/${signKey}/signature/preview`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: 'blob'
      }
    );
    return URL.createObjectURL(response.data);
  }

  async function getSignaturePreview(signKey: string) {
    const formData = await getPreviewPayload();
    const response = await deepSignApi.post(
      `/sign/${signKey}/signature/preview`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: 'blob'
      }
    );
    previewUrl.value = URL.createObjectURL(response.data);
    return URL.createObjectURL(response.data);
  }

  // async function

  return {
    // SignKeyInfo
    signKeyInfo,
    fetchSignKeyInfoPending,
    userEmail,
    roles,
    fetchSignKeyInfo,

    // SignInfo
    signInfo,
    fetchSignInfoPending,
    fetchAuthSignInfoPending,
    fetchDocumentSignPending,
    isSignee,
    placedSignatures,
    userSigneeId,
    userSignees,
    getPlacedSignaturePosition,
    fetchSignInfo,
    fetchAuthSignInfo,
    fetchDocumentSign,
    updateSigneeAutographPosition,
    updateSigneeAutographPage,

    // Sign Document
    signPending,
    signDocumentPending,
    signAuthDocumentPending,
    asynchronousSign,
    fetchAuthSignStatusPending,
    fetchAuthSignStatusFullPending,
    signDocument,
    signAuthDocument,
    abortSignatureProcess,
    fetchAuthSignStatus,
    signaturePosition,
    getSignaturePosition,
    signaturePlaced,
    isSignaturePlaced,
    pageDetails,
    getPageDetails,
    asynchronousSignRequestDateTime,
    isOrdered,
    sealDocument,

    // add/forward/update/delete
    forward,
    addNewSignee,
    removeSignee,
    updateSigneeEmail,
    updateSignatureType,

    // Observers
    removeObserver,

    // Reject Signature
    rejectSignaturePending,
    rejectAuthSignaturePending,
    rejectSignature,
    rejectAuthSignature,

    // SID
    resendSidPending,
    resendSid,

    // Attachments
    postAttachmentPending,
    deleteAttachmentPending,
    postAttachment,
    deleteAttachment,

    // Enable authority service
    enableAuthorityService,

    //Preview
    autographData,
    previewUrl,
    getGuestSignaturePreview,
    getSignaturePreview,
    updateAutographImage,

    //Seal
    sealPreviewUrl
  };
});
