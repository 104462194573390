<template>
  <v-dialog v-model="showDialog" width="600" persistent>
    <v-card class="pa-6" rounded="lg">
      <!-- TITLE -->
      <v-card-title class="justify-center">
        <h5
          class="md:text-base text-sm flex items-center justify-center mb-4 whitespace-normal text-center"
        >
          Batch Process
        </h5>
      </v-card-title>
      <!-- /TITLE -->

      <!-- FORM AUTHORITY SERVICE SELECTOR -->
      <v-form
        v-if="
          batchState !== 'completed' && batchState !== 'uploaded-files-max-size'
        "
        ref="batchProcessForm"
        @submit.prevent
      >
        <v-container class="px-0">
          <v-select
            v-model="form.authorityService"
            :disabled="
              batchState === 'unmatching-authority-service' ||
              batchState === 'required-authority-service' ||
              possibleAuthorityServices.length === 1
            "
            class="authority-service mb-3"
            :placeholder="i18n.t('batch.id_service_placeholder')"
            :label="i18n.t('labels.id_service')"
            item-title="label"
            item-value="value"
            :rules="[(v) => !!v || 'Field required']"
            :items="possibleAuthorityServices"
          ></v-select>
        </v-container>
      </v-form>
      <!-- /FORM AUTHORITY SERVICE SELECTOR -->

      <!-- 15 files or more -->
      <div
        v-if="
          documents.length > 14 &&
          (batchState === 'idle' ||
            batchState === 'checking-auth-service' ||
            batchState === 'ongoing')
        "
        class="mb-10 p-3 bg-yellow-50 border border-yellow-300 rounded grid"
      >
        {{ i18n.t('batch.info.over_fourteen_files') }}
      </div>

      <!-- Success state -->
      <SuccessBatchProcess
        v-if="batchState === 'completed'"
        :documents="signedDocuments"
      />

      <!-- Max file size state -->
      <MaxSizeState
        v-if="batchState === 'uploaded-files-max-size'"
        :documents="documents"
        @remove-document="handleRemoveDocument"
      />

      <!-- Required authority service state -->
      <div
        v-if="batchState === 'required-authority-service'"
        class="mb-10 p-3 bg-yellow-50 border border-yellow-300 rounded grid"
      >
        {{ i18n.t('batch.info.authority_service_required') }}
      </div>

      <!-- Unmatching Required authority service state -->
      <div
        v-if="batchState === 'unmatching-authority-service'"
        class="mb-10 p-3 bg-yellow-50 border border-yellow-300 rounded grid"
      >
        {{ i18n.t('batch.info.unmatching_authority_service_required') }}
      </div>

      <!-- Unavailable MobileID state -->
      <RasWarningCard
        v-if="batchState === 'unavailable-mobile-id'"
        :ras-service="rasService"
      />

      <!-- Error state -->
      <div
        v-if="batchState === 'error'"
        class="mb-10 p-3 bg-red-50 border border-red-300 rounded grid"
      >
        {{ batchError }}
      </div>

      <!-- ACTIONS -->
      <DeepButton
        v-if="batchState !== 'completed'"
        :disabled="
          !form.authorityService ||
          batchState === 'unmatching-authority-service' ||
          batchState === 'uploaded-files-max-size' ||
          documents.length > MAX_DOCUMENT_COUNT
        "
        :action="submitForm"
        class="mb-3"
        :class="{
          'cursor-wait pointer-events-none':
            batchState === 'checking-auth-service' || batchState === 'ongoing'
        }"
        color="primary"
        :text="
          batchState === 'checking-auth-service' || batchState === 'ongoing'
            ? i18n.t('batch.preparing_files')
            : i18n.t('batch.begin_process')
        "
        :icon="
          batchState === 'checking-auth-service' || batchState === 'ongoing'
            ? 'fa-solid fa-spinner-third'
            : ''
        "
        :show-loader-and-text="
          batchState === 'checking-auth-service' || batchState === 'ongoing'
        "
      />
      <DeepButton
        :action="closeDialog"
        :disabled="
          batchState === 'checking-auth-service' || batchState === 'ongoing'
        "
        variant="outlined"
        :text="
          batchState === 'completed'
            ? i18n.t('buttons.close')
            : i18n.t('buttons.cancel')
        "
      />
      <!-- /ACTIONS -->
    </v-card>
  </v-dialog>

  <BatchPendingDialog />
  <BatchIdentifyDialog
    @identification-finished="handleIdentificationFinished"
    @identification-failed="handleIdentificationFailed"
  />
  <ConfirmationDialog ref="confirmationDialog" />
</template>

<script setup lang="ts">
import { computed, onUnmounted, reactive, ref, watch } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { useI18n } from 'vue-i18n';
import {
  batchSign,
  batchStatus,
  getAvailableAuthorityServices
} from '@/api/deepsign/batch';
import { SignStatus } from '@/types/enums/SignStatus';
import { usePreferencesStore } from '@/stores/deepadmin/preferences';
import type { Document } from '@/types/Document';
import { AuthorityService } from '@/types/enums/AuthorityService';
import type { BatchSignDocument } from '@/types/deepsign/BatchSignDocument';
import SuccessBatchProcess from '../cards/SuccessBatchProcess.vue';
import { PreferenceOptions } from '@/types/enums/PreferenceOptions';
import { Jurisdiction } from '@/types/enums/Jurisdiction';
import { SignatureMode } from '@/types/enums/SignatureMode';
import { BatchStatus } from '@/types/enums/BatchStatus';
import { useAuthorityServices } from '@/components/batchprocess/useAuthorityServices.ts';
import RasWarningCard from '../cards/RasWarningCard.vue';
const i18n = useI18n();
const emitter = useEmitter();
const preferenceStore = usePreferencesStore();

const showDialog = ref(false);
const batchProcessForm = ref();
const batchSignResponse = ref();
const documents = ref<Document[]>([]);
const signedDocuments = ref<BatchSignDocument[]>([]);
const confirmationDialog = ref();
const pollInterval = ref(0);
const MAX_DOCUMENT_COUNT = 100;
const MAX_FILES_SIZE = 40;
const batchState = ref<
  | 'idle'
  | 'required-authority-service'
  | 'unmatching-authority-service'
  | 'uploaded-files-max-size'
  | 'checking-auth-service'
  | 'unavailable-mobile-id'
  | 'ongoing'
  | 'error'
  | 'completed'
>();
const batchError = ref();
const possibleAuthorityServices = ref();
const rasService = ref<'sms-code' | 'mobile-id'>();
const emit = defineEmits(['handle-signed-documents']);

const form = reactive<{
  authorityService: string | undefined;
  comment: string | undefined;
}>({
  authorityService: undefined,
  comment: undefined
});

const signKeys = computed(() => {
  return documents.value
    .filter(
      (document) => document.signKey && document.signatureType === 'signature'
    )
    .map((document) => document.signKey);
});

const maxFilesSizeReached = computed(() => {
  let filesSize = 0;
  documents.value.forEach((file) => (filesSize += file.initialSize || 0));
  return parseFloat((filesSize / 1000000).toFixed(1)) > MAX_FILES_SIZE;
});

const onSuccess = () => {
  batchState.value = 'completed';
  preferenceStore.setPreference(
    PreferenceOptions.DEFAULT_AUTHORITY_SERVICE,
    form.authorityService
  );
  emitter.$emit('update-signed-documents');
  emit('handle-signed-documents', signedDocuments.value);
};

emitter.$on('open-batch-process-dialog', openDialog);
emitter.$on('close-batch-process-dialog', closeDialog);

onUnmounted(() => {
  emitter.$off('open-batch-process-dialog', openDialog);
  emitter.$off('close-batch-process-dialog', closeDialog);
});

function openDialog(eventData) {
  batchState.value = 'idle';
  documents.value = eventData.value;
  showDialog.value = true;
  if (isDocumentCountInvalid()) {
    batchState.value = 'error';
    batchError.value = i18n.t('batch.error.maximum_files');
    return;
  }
  setupAuthorityService();
}

function isDocumentCountInvalid() {
  return documents.value && documents.value.length > MAX_DOCUMENT_COUNT;
}

function closeDialog() {
  showDialog.value = false;
  batchProcessForm.value?.reset();
  form.authorityService = preferenceStore.preferences
    ?.defaultAuthorityService as string;
  form.comment = undefined;
  batchState.value = 'idle';
  batchError.value = undefined;
}

function handleIdentificationFinished(hasBeenSuccessfullyIdentify: boolean) {
  if (hasBeenSuccessfullyIdentify) {
    batchState.value = 'ongoing';
  } else {
    batchState.value = 'error';
    batchError.value = i18n.t('batch.error.generic_error');
  }
}

function handleIdentificationFailed(service: 'sms-code' | 'mobile-id') {
  rasService.value = service;
  form.authorityService = AuthorityService.DEEP_ID;
  batchState.value = 'unavailable-mobile-id';
}

function handleRemoveDocument(documentId: string) {
  documents.value = documents.value.filter(
    (doc) => doc.documentId !== documentId
  );
}

async function checkAuthorityServicesError() {
  batchState.value = 'checking-auth-service';
  const availableAuthorityServicesPayload = {
    signatureMode: documents.value[0].signatureMode,
    jurisdiction: documents.value[0].jurisdiction
  };
  const response = await getAvailableAuthorityServices(
    availableAuthorityServicesPayload
  );
  if (
    (!response.data.did.hasRequiredLevel &&
      form.authorityService === AuthorityService.DEEP_ID) ||
    (!response.data.ras.hasRequiredLevel &&
      form.authorityService === AuthorityService.MOBILE_ID)
  ) {
    if (
      form.authorityService === AuthorityService.MOBILE_ID &&
      response.data.ras.signeeIssues[0] ===
        'user.phonenumber.not-required-level'
    ) {
      batchState.value = 'unavailable-mobile-id';
      form.authorityService = AuthorityService.DEEP_ID;
    } else {
      emitter.$emit('open-batch-identify-dialog', {
        signMode: documents.value[0].signatureMode,
        authServiceKey: form.authorityService
      });
    }
  } else {
    batchState.value = 'ongoing';
  }
}

async function submitForm() {
  const isFormValid = await batchProcessForm.value.validate();
  if (isFormValid) {
    if (form.authorityService === AuthorityService.MOBILE_ID) {
      await confirmationDialog.value
        .open(
          i18n.t('titles.signature_confirmation'),
          i18n.t(`terms.${documents.value[0].jurisdiction}`),
          null,
          {
            confirmActionText: i18n.t('buttons.confirm'),
            width: 417
          }
        )
        .then((result) => {
          if (result) {
            checkAuthorityServicesError();
          } else {
            confirmationDialog.value.cancel();
          }
        });
    } else {
      checkAuthorityServicesError();
    }
  }
}

function stopPolling() {
  clearInterval(pollInterval.value);
}

function handleDocumentErrors(documents: BatchSignDocument[]) {
  const be_error = ref();
  const userIsNotDeepIdVerified = documents.every(
    (document: BatchSignDocument) =>
      document.lastError.messageId === 'error.sign.identity.not-ready'
  );

  const documentsHaveBeenRejected = documents.every(
    (document: BatchSignDocument) =>
      document.lastError.messageId === 'error.sign.user.cancel'
  );
  const documentsHaveBeenTimedOut = documents.every(
    (document: BatchSignDocument) =>
      document.lastError.messageId === 'error.sign.user.timeout'
  );
  const userEnteredWrongOTPCode = documents.every(
    (document: BatchSignDocument) =>
      document.lastError.messageId === 'error.sign.user.otp.invalid'
  );
  const signingFailed = documents.every((document: BatchSignDocument) => {
    be_error.value = document.lastError.message;
    return document.lastError.messageId === 'error.sign.failed';
  });

  if (documentsHaveBeenRejected) {
    batchState.value = 'error';
    batchError.value = i18n.t('texts.signature_cancelled');
  } else if (documentsHaveBeenTimedOut) {
    batchState.value = 'error';
    batchError.value = i18n.t('batch.error.user.timeout');
  } else if (userEnteredWrongOTPCode) {
    batchState.value = 'error';
    batchError.value = i18n.t('batch.error.user.otp.invalid');
  } else if (userIsNotDeepIdVerified) {
    batchState.value = 'error';
    batchError.value = i18n.t('errors.api.deep_id.visual_verification');
  } else if (signingFailed) {
    if (form.authorityService === AuthorityService.MOBILE_ID) {
      checkAuthorityServicesError();
    } else {
      batchState.value = 'error';
      if (be_error.value) {
        batchError.value = be_error.value;
      } else {
        batchError.value = i18n.t('batch.error.generic_error');
      }
    }
  }
}

async function getBatchStatus(signKey: string) {
  try {
    const response = await batchStatus(signKey);

    const allDocumentsHaveBeenSigned = response.data.documents.every(
      (document: BatchSignDocument) => document.signStatus === SignStatus.SIGNED
    );
    if (response.data.batchStatus === 'completed') {
      // If at least the first document had an error
      if (response.data.documents[0].lastError) {
        handleDocumentErrors(response.data.documents);
        emitter.$emit('close-batch-pending-dialog');
        stopPolling();
      }
      // Only if all documents were signed, we show success
      else if (allDocumentsHaveBeenSigned) {
        signedDocuments.value = response.data.documents;
        onSuccess();
        emitter.$emit('close-batch-pending-dialog');
        stopPolling();
      }
    }
  } catch (error) {
    batchState.value = 'error';
    batchError.value = i18n.t('batch.error.generic_error');
    emitter.$emit('close-batch-pending-dialog');
    stopPolling();
  }
}

function checkAsynchronousSign() {
  try {
    if (batchSignResponse.value?.batchStatus === SignStatus.IN_PROGRESS) {
      pollInterval.value = setInterval(() => {
        getBatchStatus(batchSignResponse.value?.batchKey);
      }, 1000);
      emitter.$emit('show-batch-pending-dialog', {
        documents: documents.value,
        authorityService: form.authorityService,
        batchResponse: batchSignResponse.value,
        correlationCode: batchSignResponse.value.correlationCode
          ? batchSignResponse.value.correlationCode
          : undefined
      });
    } else if (batchSignResponse.value?.batchStatus === BatchStatus.COMPLETED) {
      handleDocumentErrors(batchSignResponse.value.documents);
    }
  } catch (error) {
    console.error('Error polling sign status: ', error);
  }
}

// Start the signing process as soon as the availableAuthorityService has no errors on the BE
watch(
  () => batchState.value,
  async (newBatchState) => {
    const formData = new FormData();
    const data = JSON.stringify({
      signKeys: signKeys.value,
      comment: form.comment,
      authorityService: form.authorityService
    });
    formData.append('data', new Blob([data], { type: 'application/json' }));
    if (newBatchState === 'ongoing') {
      try {
        const response = await batchSign(formData);
        batchSignResponse.value = response.data;
        // If at least the first document had an error
        if (
          response.data.batchStatus === BatchStatus.COMPLETED &&
          response.data.documents &&
          response.data.documents[0].lastError
        ) {
          handleDocumentErrors(response.data.documents);
        }
        checkAsynchronousSign();
      } catch (error) {
        batchState.value = 'error';
        if (error && error.response && error.response.data.message) {
          batchError.value = error.response.data.message;
        } else {
          batchError.value = i18n.t('batch.error.generic_error');
        }
      }
    }
  }
);

// If any of the documents has a required authorityService we need to set it as default
// If at least 2 documents have a different required
function setupAuthorityService() {
  const { anyDocumentHasRequiredAuthorityService } = useAuthorityServices(
    documents.value
  );
  anyDocumentHasRequiredAuthorityService
    ? handleEnforcedAuthorityCase()
    : handleNoEnforcedAuthorityService();
}

function handleEnforcedAuthorityCase() {
  const {
    anyDocumentHasRequiredAuthorityService,
    multipleDocumentsHaveDifferentAuthorityServices,
    singleDocumentWithRequiredAuthorityService,
    firstPossibleEnforcedAuthorityService,
    authorityServices
  } = useAuthorityServices(documents.value);

  if (multipleDocumentsHaveDifferentAuthorityServices) {
    form.authorityService = firstPossibleEnforcedAuthorityService;
    possibleAuthorityServices.value = authorityServices;
    batchState.value = 'unmatching-authority-service';
  } else if (
    anyDocumentHasRequiredAuthorityService &&
    singleDocumentWithRequiredAuthorityService
  ) {
    // Handle cases for all not AES (EiDas) only
    if (
      (documents.value &&
        documents.value[0].jurisdiction !== Jurisdiction.EIDAS) ||
      documents.value[0].signatureMode === SignatureMode.QUALIFIED
    ) {
      batchState.value = 'required-authority-service';
    }
    possibleAuthorityServices.value = authorityServices;
    form.authorityService = firstPossibleEnforcedAuthorityService;
  }
}

function handleNoEnforcedAuthorityService() {
  const { authorityServices } = useAuthorityServices(documents.value);
  //No authority service enforced
  possibleAuthorityServices.value = authorityServices;
  // We use the default only if there is one and
  // is not AES and EiDas, then it has to be DeepId.
  if (
    preferenceStore.preferences &&
    preferenceStore.preferences.defaultAuthorityService
  ) {
    if (
      documents.value.length &&
      documents.value[0].jurisdiction === Jurisdiction.EIDAS &&
      documents.value[0].signatureMode === SignatureMode.ADVANCED
    ) {
      form.authorityService = AuthorityService.DEEP_ID;
    } else {
      form.authorityService = preferenceStore.preferences
        .defaultAuthorityService as string;
    }
  }
}

watch(
  () => maxFilesSizeReached.value,
  (isMaximumReached) => {
    if (isMaximumReached) {
      batchState.value = 'uploaded-files-max-size';
    } else {
      batchState.value = 'idle';
    }
  }
);
</script>

<style lang="scss" scoped>
@import '@/styles/core/colors';
:deep(.v-text-field.v-text-field--enclosed .v-text-field__details) {
  padding-left: 0 !important;
}

.authority-service :deep(.v-input__details) {
  padding: 0;
}

.deep-id-card {
  padding: 16px;
  background-color: white;
  border-color: $primary-color !important;
  margin-bottom: 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15) !important;
}

.warning-subtitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #090909;
  text-align: left !important;
}

.deep-id-overline {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #121212;
  margin-bottom: 8px;
}

.deep-id-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #121212;
}
</style>
