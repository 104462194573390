import { createI18n } from 'vue-i18n';
import { watchEffect } from 'vue';
// app locales
import de from '@/locales/de.json';
import en from '@/locales/en.json';
import fr from '@/locales/fr.json';
import it from '@/locales/it.json';

// DeepUiLib locales
import { locales } from '@deepcloud/deep-ui-lib';
import { useStorage } from '@vueuse/core';

const storedAppLocale = useStorage('storedAppLocale');

// `createI18n` options is almost same vue-i18n-next (vue-i18n@v9.x) API
const i18n = createI18n({
  legacy: false,
  locale: storedAppLocale.value || import.meta.env.VITE_I18N_LOCALE || 'de',
  fallbackLocale:
    storedAppLocale.value || import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'de',
  silentTranslationWarn: false,
  warnHtmlMessage: true,
  globalInjection: false,
  messages: {
    de: de,
    en: en,
    fr: fr,
    it: it
  }
});

i18n.global.mergeLocaleMessage('de', locales.de);
i18n.global.mergeLocaleMessage('fr', locales.fr);
i18n.global.mergeLocaleMessage('it', locales.it);
i18n.global.mergeLocaleMessage('en', locales.en);

watchEffect(() => {
  document.documentElement.lang = i18n.global.locale.value;
});

export default i18n;
