import type { UnwrapRef } from 'vue';
import type { Document } from '@/types/Document.ts';
import { AuthorityService } from '@/types/enums/AuthorityService.ts';
import { SignatureMode } from '@/types/enums/SignatureMode.ts';

export function useAuthorityServices(documents: UnwrapRef<Document[]>) {
  const uniqueAuthorityServices = getUniqueEnforcedAuthorityServices(documents);
  const authorityServices: Array<{ label: string; value: string }> = [];
  const anyDocumentHasRequiredAuthorityService =
    uniqueAuthorityServices.size > 0;
  const multipleDocumentsHaveDifferentAuthorityServices =
    uniqueAuthorityServices.size > 1;
  const singleDocumentWithRequiredAuthorityService =
    uniqueAuthorityServices.size === 1;
  const firstPossibleEnforcedAuthorityService =
    Array.from(uniqueAuthorityServices)[0] || undefined;

  if (anyDocumentHasRequiredAuthorityService) {
    if (firstPossibleEnforcedAuthorityService === AuthorityService.DEEP_ID) {
      authorityServices.push({ label: 'DeepID', value: 'did' });
    } else {
      authorityServices.push(getMobileOption());
    }
  } else {
    authorityServices.push(getMobileOption(), {
      label: 'DeepID',
      value: 'did'
    });
  }

  function getMobileOption() {
    return {
      label:
        documents && documents[0].signatureMode === SignatureMode.QUALIFIED
          ? 'Mobile ID'
          : 'SMS-Code',
      value: 'ras'
    };
  }
  return {
    anyDocumentHasRequiredAuthorityService,
    multipleDocumentsHaveDifferentAuthorityServices,
    singleDocumentWithRequiredAuthorityService,
    firstPossibleEnforcedAuthorityService,
    authorityServices
  };
}

function getUniqueEnforcedAuthorityServices(docs: Document[]): Set<string> {
  const uniqueAuthorityServices = new Set<string>();
  docs.forEach((doc) => {
    if (doc.requiredAuthorityService) {
      uniqueAuthorityServices.add(doc.requiredAuthorityService);
    }
  });
  return uniqueAuthorityServices;
}
