<template>
  <div class="d-flex flex-row align-center" style="color: #b1b1b7">
    <!-- FILE NAME -->
    <v-col
      class="pa-0 flex-fill text-truncate file-name"
      :data-test-id="`attached-${fileName}`"
    >
      {{ fileName }}
    </v-col>
    <!-- /FILE NAME -->

    <!-- ICONS -->
    <v-col class="pa-0 flex-fill text-right" style="max-width: 80px">
      <div
        v-if="
          file.status === AttachmentStatus.EXCLUDED ||
          file.status === AttachmentStatus.ERROR
        "
        class="d-flex flex-column align-end"
      >
        <v-menu location="left top" max-width="300" open-on-hover open-on-click>
          <template #activator="{ props: ActivatorProps }">
            <v-icon
              v-bind="ActivatorProps"
              color="#F99C2C"
              size="12px"
              data-test-id="icon-password-protected"
            >
              fal fa-triangle-exclamation
            </v-icon>
          </template>
          <v-card>
            <v-card-text data-test-id="label-tooltip-password-protected">
              {{
                file.errorId
                  ? i18n.t(`errors.api.${file.errorId}`)
                  : i18n.t('texts.file_too_large')
              }}
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
      <div v-if="isAttached" class="file-size">
        {{ fileSize }}
      </div>
      <div
        v-if="file.status === AttachmentStatus.PROGRESS"
        class="d-flex flex-column align-end"
      >
        <v-icon color="primary" size="12px">
          fas fa-circle-notch fa-spin
        </v-icon>
      </div>
      <div
        v-if="file.status === AttachmentStatus.PENDING"
        class="d-flex flex-column align-end"
      >
        <v-icon size="12px"> fal fa-hourglass-start </v-icon>
      </div>
      <div
        v-if="file.status === AttachmentStatus.UNSUPPORTED"
        class="d-flex flex-column align-end"
      >
        <v-menu location="left top" max-width="300" open-on-hover open-on-click>
          <template #activator="{ props: ActivatorProps }">
            <v-icon v-bind="ActivatorProps" color="error" size="12px">
              far fa-file-slash
            </v-icon>
          </template>
          <v-card>
            <v-card-text class="signature-level-description">
              {{ i18n.t('texts.unsupported_attachment') }}
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
    </v-col>
    <!-- /ICONS -->

    <!-- REMOVE -->
    <v-col class="pa-0">
      <v-btn class="ml-2" icon size="small" variant="text" @click="removeFile">
        <v-icon color="philippineSilver" size="20"> fal fa-close </v-icon>
      </v-btn>
    </v-col>
    <!-- /REMOVE -->
  </div>
</template>

<script setup>
import { AttachmentStatus } from '@/types/enums/AttachmentStatus.ts';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { humanFileSize } from '@/composables/useUtils';

const props = defineProps({
  file: {
    type: Object,
    default: undefined
  },
  excluded: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['remove-uploaded-attachment']);

const i18n = useI18n();

const isAttached = computed(() => {
  return props.file.status === AttachmentStatus.ATTACHED;
});
const fileSize = computed(() => {
  return isAttached.value
    ? humanFileSize(props.file.attachment.size)
    : humanFileSize(props.file.file.size);
});

const fileName = computed(() => {
  return isAttached.value ? props.file.attachment.name : props.file.file.name;
});

function removeFile() {
  emit('remove-uploaded-attachment', props.file);
}
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.file-name {
  color: $light-theme-black;
}

.tooltip {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}

.file-size {
  color: $light-theme-black;
}
</style>
