import { setSettingsBackRoute } from '@/composables/useUtils';
import AuthService from '@/services/auth-service';
import type { NavigationGuardNext, Route } from 'vue-router';

const settingsGuardian = () => {
  return async (_to: Route, from: Route, next: NavigationGuardNext) => {
    if (AuthService.user?.sub && from && from.name && from.fullPath) {
      setSettingsBackRoute(AuthService.user.sub, from);
    }
    next();
  };
};

export default settingsGuardian;
