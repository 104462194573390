/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import 'vuetify/styles';

// Composables
import { createVuetify } from 'vuetify';
import * as directives from 'vuetify/directives';
import { de, en, fr, it } from 'vuetify/locale';
import colors from 'vuetify/util/colors';
import { aliases, fa } from 'vuetify/iconsets/fa';
import { config } from '@deepcloud/deep-ui-lib';
export default createVuetify({
  directives,
  locale: {
    messages: {
      de,
      en,
      fr,
      it
    },
    locale: 'de'
  },
  theme: {
    variations: {
      colors: ['primary', 'secondary'],
      lighten: 2,
      darken: 0
    },
    themes: {
      light: {
        colors: {
          primary: '#0052ff',
          accent: colors.grey.darken3,
          secondary: '#f2c94e',
          info: '#04a5f4',
          warning: '#E2A600',
          error: '#D83D0E',
          success: '#4CAF50',
          greyDarken1: '#757575',
          black: '#212529',
          philippineSilver: '#B1B1B7'
        }
      },
      dark: {
        colors: {
          primary: colors.blue.darken2,
          accent: colors.grey.darken3,
          secondary: colors.amber.darken3,
          info: colors.teal.lighten1,
          warning: colors.amber.base,
          error: colors.deepOrange.accent4,
          success: colors.green.accent3
        }
      }
    }
  },
  icons: {
    defaultSet: 'fa',
    aliases: {
      ...aliases,
      ...config.icons.fa,
      ...{
        cancel: 'fat fa-xmark',
        expand: 'fal fa-chevron-down'
      }
    },
    sets: {
      fa
    }
  },
  display: {
    thresholds: {
      sm: 350
    }
  },
  defaults: {
    global: {
      ripple: false
    },
    // Vuetify Components
    VBtn: {
      variant: 'flat',
      style: 'text-transform: none;',
      color: undefined,
      ripple: false
    },
    VIcon: {
      size: 16
    },
    VAppBar: {
      flat: true,
      border: true
    },
    VMenu: {
      transition: 'fade-transition',
      VCard: {
        class: 'rounded-lg'
      },
      VList: {
        rounded: 'lg'
      }
    },
    VListItem: {},
    VTextField: {
      variant: 'outlined',
      color: 'primary',
      density: 'compact'
    },
    VNavigationDrawer: {
      flat: true,
      border: true
    },
    VTextarea: {
      variant: 'outlined',
      color: 'primary',
      rounded: 'xl'
    },
    VSelect: {
      variant: 'outlined',
      color: 'primary',
      itemColor: 'primary',
      density: 'compact'
    },
    VSheet: {
      border: true,
      rounded: 'md'
    },
    VAutocomplete: {
      variant: 'outlined',
      color: 'primary',
      itemColor: 'primary',
      menuProps: {
        contentClass: 'rounded-lg'
      }
    },
    VCombobox: {
      color: 'primary',
      itemColor: 'primary',
      menuProps: {
        contentClass: 'rounded-lg'
      }
    },
    VSwitch: {
      color: 'primary',
      inset: true,
      ripple: false
    },
    VDivider: {
      color: 'accent'
    },
    VDataTableServer: {
      VSelectionControl: {
        color: 'primary'
      }
    },
    VCheckbox: {
      color: 'primary',
      ripple: false
    },
    VCheckboxBtn: {
      color: 'primary'
    },
    VChip: {
      variant: 'tonal'
    }
  }
});
