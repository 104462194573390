<template>
  <div class="grid gap-8 md:grid-cols-8 w-full">
    <v-form
      ref="userSignatureForm"
      class="col-span-8 md:col-span-5"
      @submit.prevent
    >
      <div
        class="grid md:col-span-5 justify-start divide-y divide-zinc-300 overflow-hidden rounded-[0.65rem] border border-zinc-300 shadow"
      >
        <p class="!text-sm p-4 h-full bg-zinc-100">
          {{ i18n.t('settings.subtitles.my_signature') }}
        </p>
        <div class="p-4 py-8 grid gap-6">
          <SettingsSignaturePad
            :initial-signature-url="
              settings.autographUrl ? settings.autographUrl : ''
            "
            @update-signature-url="handleUpdateSignature"
          />

          <div>
            <label for="optionalText">
              {{ i18n.t('settings.labels.optional_text') }}
            </label>
            <v-text-field
              id="optionalText"
              v-model.trim="settings.optionalText"
              :disabled="updatingState === 'loading'"
              :rules="[optionalTextValidationRule]"
              variant="outlined"
              density="compact"
              hide-details="auto"
              :placeholder="i18n.t('settings.placeholders.optional_text')"
              type="text"
              @update:model-value="getPreviewDebounced()"
            />
          </div>
          <div>
            <label for="location">{{
              i18n.t('settings.labels.localtion')
            }}</label>
            <v-text-field
              id="location"
              v-model.trim="settings.location"
              :disabled="updatingState === 'loading'"
              :rules="[locationValidationRule]"
              variant="outlined"
              density="compact"
              hide-details="auto"
              :placeholder="i18n.t('settings.placeholders.location')"
              type="text"
              class="form-input"
              @update:model-value="getPreviewDebounced()"
            />
          </div>
          <div class="grid gap-2 md:hidden">
            <DeepButton
              :action="handleSubmit"
              :loading="updatingState === 'loading'"
              :class="{ 'ml-auto w-fit': !isNotSettings }"
              :text="i18n.t('buttons.save')"
            />
            <DeepButton
              v-if="isNotSettings"
              :action="() => emit('close-signature-edition')"
              :disabled="updatingState === 'loading'"
              variant="outlined"
              :text="i18n.t('buttons.cancel')"
            />
          </div>
        </div>
        <div
          class="md:flex hidden justify-between w-full p-4 bg-zinc-100"
          :class="isNotSettings ? 'grid-cols-2 gap-2' : 'w-[230px] ml-auto'"
        >
          <DeepButton
            v-if="isNotSettings"
            :action="() => emit('close-signature-edition')"
            :disabled="updatingState === 'loading'"
            class="md:w-[48%]"
            variant="outlined"
            :text="i18n.t('buttons.cancel')"
            :block="false"
          />
          <DeepButton
            :action="handleSubmit"
            :loading="updatingState === 'loading'"
            :class="{ 'ml-auto w-fit': !isNotSettings }"
            :text="i18n.t('buttons.save')"
            :block="false"
          />
        </div>
      </div>
    </v-form>
    <div
      class="gap-4 !order-first md:order-last md:col-span-3 col-span-8 w-full"
    >
      <div
        class="rounded-[0.65rem] border border-zinc-300 bg-zinc-100 p-10 shadow-perfect w-full"
      >
        <div class="relative">
          <div
            v-if="preview.isReady && !preview.state.value"
            class="!aspect-[851/448] !animate-pulse rounded-md border border-black/15 bg-white shadow-xl"
          ></div>
          <div>
            <img
              v-if="preview.state.value"
              :src="preview.state.value"
              class="mx-auto w-full max-w-[488px] rounded-md border border-black/15 object-contain shadow-xl dark:border-white/15"
            />
            <OverlaySpinner
              v-if="preview.state.value && preview.isLoading.value"
            />
          </div>
        </div>
      </div>
      <div
        v-if="preview.isReady && !preview.error.value"
        class="aspec max-w-xs text-center text-sm mx-auto py-4"
      >
        {{ i18n.t('settings.labels.signature_preview') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAsyncState } from '@vueuse/core';
import deepSignApi from '@/api/deepsign/deepsignApi';
import { base64ToFile, debounce } from '@/composables/useUtils';
import { useRoute } from 'vue-router';
import { useMessageStore } from '@/stores/message/message';
import type { AxiosError, AxiosResponse } from 'axios';

const i18n = useI18n();
const route = useRoute();
const messageStore = useMessageStore();
const updatingState = ref<'idle' | 'loading' | 'error'>('idle');
const settings = ref<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  autographUrl: any;
  optionalText: string;
  location: string;
}>({
  autographUrl: undefined,
  optionalText: '',
  location: ''
});
const previewChanged = ref(false);
const autographChanged = ref(false);
const userSignatureForm = ref();
const emit = defineEmits(['signature-updated', 'close-signature-edition']);

const isNotSettings = computed(() => route.name !== 'settings');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const locationValidationRule = (value: any) => {
  if (!value) {
    return true;
  }
  return value.length <= 100 || i18n.t('settings.validation.location');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const optionalTextValidationRule = (value: any) => {
  if (!value) {
    return true;
  }
  return value.length <= 100 || i18n.t('settings.validation.optional_text');
};

function convertBlobToBase64(blob: Blob): Promise<string> | undefined {
  if (!blob) return;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

async function fetchImageAsBase64(url: string): Promise<string | undefined> {
  if (!url) return;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Network response was not ok for URL: ${url}`);
  }
  const imageBlob = await response.blob();

  const base64 = await convertBlobToBase64(imageBlob);
  return base64;
}

const settingsInitialState = reactive(
  useAsyncState(
    () =>
      deepSignApi.get('users/me').then(async (t) => {
        settings.value.location = t.data.location;
        settings.value.optionalText = t.data.optionalText;
        settings.value.autographUrl = await fetchImageAsBase64(
          t.data.autographUrl
        );
        return t.data;
      }),
    {},
    {
      resetOnExecute: false,
      shallow: false,
      immediate: false,
      onSuccess: async () => preview.execute(0, await getPreviewPayload())
    }
  )
);

settingsInitialState.execute();

const preview = useAsyncState(
  (visualSettings) => {
    return deepSignApi
      .post<AxiosError, AxiosResponse<Blob>>(
        `users/me/signature/preview`,
        visualSettings,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          responseType: 'blob'
        }
      )
      .then((t) => URL.createObjectURL(t.data));
  },
  '',
  {
    resetOnExecute: false,
    immediate: false
  }
);

async function getPreviewPayload() {
  const formData = new FormData();
  if (settings.value.autographUrl) {
    const autograph = await base64ToFile(
      settings.value.autographUrl,
      'autograph'
    );
    formData.append('autograph', autograph);
  }

  const data = JSON.stringify({
    sizeFactor: 2,
    optionalText: settings.value.optionalText
      ? settings.value.optionalText
      : undefined,
    location: settings.value.location ? settings.value.location : undefined
  });
  formData.append('data', new Blob([data], { type: 'application/json' }));

  return formData;
}

async function getAutographPayload() {
  const formData = new FormData();

  if (settings.value.autographUrl) {
    const autograph = await base64ToFile(
      settings.value.autographUrl,
      'autograph'
    );
    formData.append('autograph', autograph);
  }
  return formData;
}

const saveSettings = useAsyncState(
  (newSettings) => {
    return deepSignApi.put('users/me', newSettings);
  },
  {},
  {
    resetOnExecute: false,
    shallow: false,
    immediate: false
  }
);

const saveAutograph = reactive(
  useAsyncState(
    (formData) => {
      return deepSignApi.post('users/me/autograph', formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
    },
    {},
    {
      resetOnExecute: false,
      shallow: false,
      immediate: false
    }
  )
);

const removeAutograph = reactive(
  useAsyncState(
    () => {
      return deepSignApi.delete('users/me/autograph');
    },
    undefined,
    {
      resetOnExecute: false,
      shallow: false,
      immediate: false
    }
  )
);

const getPreviewDebounced = debounce(async () => {
  preview.execute(0, await getPreviewPayload());
  previewChanged.value = true;
}, 750);

function handleUpdateSignature(newSignatureUrl: string) {
  settings.value.autographUrl = newSignatureUrl;
  autographChanged.value = true;
  getPreviewDebounced();
}

async function handleSubmit() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promises: Promise<any>[] = [];

  if (autographChanged.value) {
    const promise = settings.value.autographUrl
      ? saveAutograph.execute(0, await getAutographPayload())
      : removeAutograph.execute(0);
    promises.push(promise);
  }

  if (previewChanged.value) {
    const payload = {
      location: settings.value.location,
      optionalText: settings.value.optionalText
    };
    const settingsPromise = saveSettings.execute(0, payload);
    promises.push(settingsPromise);
  }

  if (promises.length > 0) {
    updatingState.value = 'loading';
    Promise.all(promises)
      .then(() => {
        messageStore.showMessage({
          key: 'snackbars.signature_updated_success',
          color: 'success',
          icon: 'fa fa-circle-check',
          timeout: 2000
        });
        console.log('autograph', settings.value.autographUrl);
        emit('signature-updated', settings.value.autographUrl);
      })
      .catch(() => {
        messageStore.showMessage({
          key: 'snackbars.signature_updated_error',
          color: 'error',
          icon: 'fa fa-circle-exclamation',
          timeout: 2000
        });
      })
      .finally(() => {
        updatingState.value = 'idle';
      });
  } else {
    emit('close-signature-edition');
  }
}
</script>

<style lang="scss" scoped>
:deep(.v-text-field.v-text-field--enclosed .v-text-field__details) {
  padding-left: 0 !important;
}
:deep(.theme--light.v-skeleton-loader .v-skeleton-loader__image) {
  height: 100%;
}

.v-skeleton-loader :deep(.v-skeleton-loader__image) {
  height: 100%;
}

.shadow-perfect {
  --tw-shadow: rgba(14 62 124 /0.04) 0px 0px 0px 1px,
    rgba(0.165 0.198 0.275 / 0.04) 0px 1px 1px -0.5px,
    rgba(0.165 0.198 0.275 / 0.04) 0px 3px 3px -1.5px,
    rgba(0.165 0.198 0.275 / 0.04) 0px 6px 6px -3px,
    rgba(14 62 124 / 0.04) 0px 12px 12px -6px,
    rgba(14 62 124 /0.04) 0px 24px 24px -12px;
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color),
    0px 1px 1px -0.5px var(--tw-shadow-color),
    0px 3px 3px -1.5px var(--tw-shadow-color),
    0px 6px 6px -3px var(--tw-shadow-color),
    0px 12px 12px -6px var(--tw-shadow-color),
    0px 24px 24px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>
