<template>
  <v-card v-if="canSign" class="signing-card" flat>
    <div class="flex flex-row">
      <div class="flex flex-col w-full">
        <!-- TITLE -->
        <div class="flex flex-row h-[24px] rounded-0">
          {{ i18n.t('titles.sealing_as') }}
        </div>
        <!-- /TITLE -->
        <!-- /REQUEST DETAILS -->
      </div>
    </div>
    <!-- VISUAL SIGNATURE -->
    <div class="w-full py-4">
      <!-- REQUESTOR DETAILS -->
      <!-- REQUESTOR ORGANIZATION AND NAME -->
      <div
        class="flex flex-col flex-wrap text mb-4"
        data-test-id="requestor-org-name"
      >
        <!-- /REQUESTOR ORGANIZATION AND NAME -->
        <div
          class="flex items-center content-center company-name deep-truncate"
          :data-test-id="`organization-${initiatorCompanyDisplayName}`"
        >
          {{ initiatorCompanyDisplayName }}
        </div>
        <DocumentSealLevel :seal="selectedSeal" />
      </div>

      <div
        v-if="selectedSeal"
        class="flex flex-col gap-4 justify-center items-center text-center"
      >
        <img
          v-if="previewSrc"
          alt="seal preview"
          :src="previewSrc"
          class="!select-none !pointer-events-none p-[1px] mt-auto"
        />
      </div>
      <div v-else>
        <span>
          {{ i18n.t('texts.select_seal_placeholder') }}
        </span>
      </div>
    </div>
    <!-- /VISUAL SIGNATURE -->
    <v-combobox
      v-model="selectedSeal"
      class="mb-6 seal-combobox"
      :class="sealItems.length === 1 ? 'remove-icon pointer-events-none' : ''"
      hide-details="auto"
      :rules="[() => !!selectedSeal || i18n.t('texts.seal_document_required')]"
      item-title="displayName"
      item-value="companyId"
      :items="sealItems"
      variant="outlined"
      :readonly="sealItems.length === 1"
      :placeholder="i18n.t('texts.select_seal')"
      :item-props="
        (item) => ({
          'data-test-id': `seal-${item.displayName.replace(/\s+/g, '-').toLowerCase()}`
        })
      "
    />
    <!-- PLACE SIGNATURE -->
    <DeepButton
      v-if="!isSignaturePlaced"
      :action="placeSignaturesAutomatically"
      class="mt-2"
      variant="outlined"
      :disabled="!selectedSeal || sealingState === 'sealing'"
      :text="i18n.t('buttons.place_seal')"
    />
    <!-- /PLACE SIGNATURE -->

    <!-- SIGN -->
    <DeepButton
      :disabled="!selectedSeal"
      data-test-id="sign-now"
      :loading="sealingState === 'sealing'"
      :action="handleSeal"
      class="mt-2"
      :text="i18n.t('buttons.seal_now')"
    />
    <!-- /SIGN -->

    <!-- IDENTIFY DIALOG -->
    <IdentifyDialog />
  </v-card>
</template>

<script setup lang="ts">
import { useGlobalStore } from '@/stores/global/global';
import { useSignStore } from '@/stores/sign/sign';
import { watch, onUnmounted, ref, onMounted, computed } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { useI18n } from 'vue-i18n';
import { getSealPreview } from '@/api/deepsign/users-me-seals-preview';
import DocumentSealLevel from '@/components/signatureArea/DocumentSealLevel.vue';

const props = defineProps({
  document: {
    type: Object,
    default: undefined
  },
  signatures: {
    type: Array,
    default: () => []
  },
  email: {
    type: String,
    default: ''
  },
  canAddAutograph: {
    type: Boolean,
    default: false
  },
  canSign: {
    type: Boolean,
    default: false
  },
  isSignedByUser: {
    type: Boolean,
    default: false
  },
  signButtonEnabled: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update-authority-service']);

const signStore = useSignStore();
const emitter = useEmitter();
const i18n = useI18n();
const globalStore = useGlobalStore();

const changeAutograph = ref(false);
const fileReader = ref(null);
const authorityService = ref(null);
const ownSignatureModel = ref();
const selectedSeal = ref(globalStore.selectedSeal || null);
const sealingState = ref<'idle' | 'sealing' | 'error'>('idle');
const sealItems = ref([]);

const initiatorCompanyDisplayName = computed(() => {
  return props.document?.initiatorCompanyDisplayName || '';
});

if (props.signatures) {
  ownSignatureModel.value = props.signatures.find(
    (signee) => signee.signeeId === signStore.signInfo?.signeeId
  );
}

const previewSrc = ref('');

emitter.$on('update-change-autograph', updateChangeAutograph);

onMounted(() => {
  fileReader.value = new FileReader();
  setupSealsForCompany();
});

onUnmounted(() => {
  emitter.$off('update-change-autograph', updateChangeAutograph);
});

const isSignaturePlaced = computed(() => {
  return signStore.placedSignatures.some(
    (signee) => signee.signeeId === signStore.signInfo?.signeeId
  );
});

function placeSignaturesAutomatically() {
  emitter.$emit('place-signatures-automatically', props.signatures);
}

function updateChangeAutograph(value: boolean) {
  changeAutograph.value = value;
}

async function handleSeal() {
  // Call the API to seal
  try {
    sealingState.value = 'sealing';
    if (globalStore.selectedSeal) {
      const payload = {
        signKey: signStore.signInfo?.signKey,
        sign: {
          comment: '',
          authorityService: authorityService.value,
          autographPosition: signStore.signInfo?.canModifyAutographPosition
            ? signStore.getPlacedSignaturePosition
            : null
        },
        autograph: null,
        sealId: globalStore.selectedSeal.sealId
      };
      await signStore.sealDocument(payload);
    }
    sealingState.value = 'idle';
  } catch (error) {
    sealingState.value = 'error';
  }
}

async function setupSealsForCompany() {
  try {
    // TODO: BE needs to persist the initiatorCompanyId
    // so we can filter by this and not initiatorCompanyDisplayName
    const availableSeals = signStore.signInfo?.availableSeals?.filter(
      (item) =>
        item.company.displayName === props.document?.initiatorCompanyDisplayName
    );
    sealItems.value = availableSeals;

    if (sealItems.value.length === 1) {
      selectedSeal.value = sealItems.value[0];
    }

    return sealItems.value;
  } catch (e) {
    console.error('setupSealsForCompany ', e);
    sealItems.value = [];
    return e;
  }
}

watch(
  () => selectedSeal,
  async (newValue) => {
    if (!newValue) return;
    globalStore.setSelectedSeal(newValue.value);
    if (newValue.value) {
      await getSealImage(newValue.value.sealId);
    }
  },
  {
    deep: true,
    immediate: true
  }
);

watch(
  () => authorityService.value,
  (value) => {
    emit('update-authority-service', value?.key || null);
  },
  {
    immediate: true
  }
);

async function getSealImage(sealId: string) {
  try {
    const IMAGE_SIZE_FACTOR = 3;
    const result = await getSealPreview(sealId, IMAGE_SIZE_FACTOR);
    previewSrc.value = URL.createObjectURL(result.data);
    signStore.sealPreviewUrl = previewSrc.value;
  } catch (e) {
    console.error('getSealPreview ', e);
    return e;
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.signing-card {
  box-sizing: border-box;
  border-radius: 7px;
  background-color: $grey-background;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15) !important;
  border: 1px solid $grey-border;
  margin-bottom: 32px;
  margin-top: 16px;
  padding: 1rem;
}

.signee-email {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0025em;
  color: black !important;
  padding-bottom: 8px;

  &.mobile {
    font-size: 16px;
  }
}

.signature-level {
  color: $grey;
  font-weight: 400 !important;
  margin-right: 6px;
  font-size: 14px !important;
}

.actions-container {
  width: 100%;
}

.signed {
  margin-right: 4px;
  color: $success;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.signed-icon {
  margin-top: -28px;
  color: $success;
}

.date {
  color: $grey;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.default-autograph {
  margin-top: 16px;
  margin-bottom: 10px;
  width: 100%;
  aspect-ratio: 140/47;
  border: 1px solid $grey;
  border-radius: 4px;
}

.change-default-autograph {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.change-button {
  &:hover {
    color: $primary-color;
    cursor: pointer;
  }

  &:hover :deep(.v-icon.v-icon) {
    color: $primary-color;
    cursor: pointer;
  }
}

.menu-icon {
  color: $grey;
}

.tooltip {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}

.deep-caption-grey {
  color: $philippine-silver;
}

.too-large {
  color: $error;
}

.authority-service {
  color: #8e8e93;
  margin-top: 8px;
  width: 100%;
}

.move-signatures-menu {
  padding-top: 1rem;
}

.remove-icon :deep(.v-field__append-inner .v-combobox__menu-icon) {
  display: none !important;
}

.company-name {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0025em;
  color: black !important;

  &.mobile {
    font-size: 16px;
  }
}

.seal-combobox :deep(.v-input__details) {
  padding-inline: 0px;
}
</style>
