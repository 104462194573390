<template>
  <DeepHeader
    :logo="logo"
    :app-switch-items="appSwitchItems"
    :hide-app-switch="!AuthService.isAuthenticated"
  >
    <template #prepend-start>
      <div class="border-solid border-r">
        <v-btn
          v-if="!$vuetify.display.mdAndUp"
          color="primary"
          size="56"
          icon
          @click="handleCLick"
        >
          <v-icon size="large" color="black">{{
            isOpen ? 'fa-thin fa-xmark' : 'fa-regular fa-bars'
          }}</v-icon>
        </v-btn>
      </div>
    </template>
    <template #append>
      <div class="flex justify-between items-center">
        <UserAccountMenu />
      </div>
    </template>
  </DeepHeader>
</template>
<script setup lang="ts">
import { DeepHeader } from '@deepcloud/deep-ui-lib';
import { computed, ref } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';
import AuthService from '@/services/auth-service.ts';

const deepBoxUrl = import.meta.env.VITE_DEEPBOX_FRONTEND_BASE_URL;
const deepSignUrl = import.meta.env.VITE_DEEPSIGN_FRONTEND_BASE_URL;

const emitter = useEmitter();

defineEmits(['toggle-sidebar']);
defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
});

const logo = ref({
  appId: 'deepsign',
  to: '/'
});

const appSwitchItems = computed(() => [
  {
    key: 'deepbox',
    title: 'DeepBox',
    href: deepBoxUrl
  },
  {
    key: 'deepsign',
    title: 'DeepSign',
    href: deepSignUrl
  }
]);

function handleCLick() {
  emitter.$emit('toggle-sidebar');
}
</script>
<style lang="scss" scoped>
:deep(.AppSwitcherTrigger) {
  margin-right: 8px;
}

:deep(.v-btn__overlay) {
  background-color: transparent !important;
}
</style>
