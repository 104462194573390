<template>
  <div class="sign-requirement md:mobile-sign-requirement">
    <div class="mt-6 drawer-section-title text-uppercase">
      {{ i18n.t('titles.who_needs_to_sign') }}
    </div>

    <CustomSwitchWrapper
      v-if="AuthService.isAuthenticated && !isDocumentAuthAuthenticated"
      class="mt-2 mb-4"
      :initial-value="shouldISign"
      :label="i18n.t('titles.sign_yourself')"
      label-class="drawer-subhead"
      switch-id="requestor-switch"
      :disabled="addingSignature"
      data-test-id="me-signing"
      @value-changed="changeUserSignature"
    />

    <!-- SIGNATURES -->
    <div>
      <!-- IF ORDERED SIGNEES -->
      <div
        v-if="
          ordered &&
          Array.isArray(documentStore.signGroups) &&
          documentStore.signGroups.length > 1
        "
        class="overflow-auto"
        :class="{
          'is-group': documentStore.signGroups.some((group) => group.length > 1)
        }"
      >
        <v-form ref="signaturesForm" @submit.prevent>
          <Draggable
            v-model="draggableGroups.value"
            item-key="index"
            animation="200"
            ghost-class="group-ghost"
            group="group"
            handle=".group-grip-icon"
            @change="onGroupDragUpdate"
            @end="dragging = false"
            @start="dragging = true"
          >
            <template #item="{ element: groupitem, index: groupIndex }">
              <SigneesDraggableGroup
                :key="groupIndex"
                class="sortable"
                :dragging="dragging"
                :disabled="disableDrag"
                :class="{
                  'multi-item-group': groupitem.length > 1,
                  'empty-group': groupitem.length === 0,
                  highlight:
                    !hiddenGroupIndexes.includes(groupIndex) &&
                    currentDraggedGroupIndex !== null &&
                    firstOrLast(groupIndex),
                  drag: dragging || hiddenGroupIndexes.includes(groupIndex),
                  'current-drag':
                    groupIndex === currentDraggedGroupIndex &&
                    groupitem.length === 1
                }"
                :show-drag-notch="groupitem.length > 1"
              >
                <template #content>
                  <Draggable
                    :item-key="groupIndex.toString()"
                    animation="200"
                    ghost-class="item-ghost"
                    group="items"
                    :disabled="disableDrag"
                    handle=".grip-icon"
                    :list="groupitem"
                    @change="onItemDragUpdate"
                    @end="endItemDragging"
                    @start="startItemDragging(groupIndex)"
                  >
                    <template #item="{ element }">
                      <div class="d-flex flex-row align-center">
                        <v-icon
                          size="large"
                          color="greyDarken1"
                          class="grip-icon transition-colors"
                          :class="{ dragging: draggingItem }"
                        >
                          far fa-grip-dots-vertical
                        </v-icon>
                        <EmailSuggestion
                          :id="`email-${element.signeeId}`"
                          v-model.trim="element.email"
                          :autocomplete="`signee-email-${element.email}-${element.signeeId}`"
                          :name="`signee-email-${element.email}-${element.signeeId}`"
                          class="my-3"
                          :signee="element"
                          :data-test-id="`signee-email-${element.email}`"
                          :data-test-sign-order="`sign-order-${Math.ceil(groupIndex / 2)}`"
                          :class="{ initiator: isRequestorSignee(element) }"
                          :readonly="isRequestorSignee(element)"
                          :rules="signatureRules"
                          :email-to-hide="initiatorSigneeEmail"
                          @blur="onBlur($event, element)"
                          @focus="onFocus(element)"
                          @update:model-value="
                            handleSigneeDebounced($event, element)
                          "
                          @keydown.enter="handleSignee($event, element)"
                        />
                        <input
                          :id="`order-input-${element.signeeId}`"
                          class="round-input mr-2 mt-2 pointer-events-auto"
                          style="min-height: 56px"
                          type="text"
                          :value="Math.ceil(groupIndex / 2)"
                          @focusin="disableDrag = true"
                          @focusout="disableDrag = false"
                          @change="updateOrder($event, element)"
                        />
                        <v-btn
                          color="default"
                          :class="{
                            'opacity-25 cursor-not-allowed pointer-events-none':
                              removingSignee
                          }"
                          class="text-gray-400 mx-1"
                          icon="fal fa-xmark"
                          size="medium"
                          @click="removeSigneeFromDocument(element)"
                        >
                          <v-icon>fal fa-xmark</v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </Draggable>
                </template>
              </SigneesDraggableGroup>
            </template>
          </Draggable>
        </v-form>
      </div>
      <!-- IF ORDERED SIGNEES -->

      <!-- UNORDERED SIGNEES -->
      <div v-else>
        <v-form ref="signaturesForm" @submit.prevent>
          <div
            v-for="signee in signeeFields"
            :key="signee.signeeId"
            class="my-2"
          >
            <div class="d-flex flex-row align-center">
              <EmailSuggestion
                :id="`email-${signee.signeeId}`"
                v-model.trim="signee.email"
                :data-test-id="`signee-email-${signee.email}`"
                :autocomplete="`signee-email-${signee.email}-${signee.signeeId}`"
                :name="`signee-email-${signee.email}-${signee.signeeId}`"
                :class="{ initiator: isRequestorSignee(signee) }"
                :readonly="isRequestorSignee(signee)"
                :rules="signatureRules"
                :email-to-hide="initiatorSigneeEmail"
                @blur="onBlur($event, signee)"
                @focus="onFocus(signee)"
                @update:model-value="handleSigneeDebounced($event, signee)"
                @keydown.enter="handleSignee($event, signee)"
              />

              <v-btn
                class="mt-2 ml-1 text-gray-400"
                color="default"
                icon="fal fa-xmark"
                :class="{
                  'opacity-25 cursor-not-allowed pointer-events-none':
                    removingSignee
                }"
                size="medium"
                data-test-id="remove-signee"
                @click="removeSigneeFromDocument(signee)"
              >
                <v-icon>fal fa-xmark</v-icon>
              </v-btn>
            </div>
          </div>
        </v-form>
      </div>
      <!-- UNORDERED SIGNEES -->

      <!-- ADD SIGNEE -->
      <div class="mt-4 mb-6" :class="{ 'mt-6': dragging }">
        <v-btn
          class="deep-secondary-button rounded-lg"
          color="primary"
          variant="outlined"
          :loading="addingSignature"
          data-test-id="add-signee"
          @click="addSigneeField"
        >
          {{ i18n.t('buttons.add_signatory') }}
        </v-btn>
      </div>
      <!-- /ADD SIGNEE -->
    </div>
    <!-- SIGNATURES -->

    <!-- SIGN ORDER ACTIONS -->
    <v-expand-transition>
      <div v-show="showDefineOrderSwitch">
        <div class="d-flex flex-column">
          <!-- ORDER ENABLED SWITCH -->
          <CustomSwitchWrapper
            class="mb-2"
            :initial-value="ordered"
            :label="i18n.t('titles.define_order_of_signing')"
            label-class="drawer-subhead"
            @value-changed="onOrderSwitchToggle"
          />
          <!-- ORDER ENABLED SWITCH -->

          <v-expansion-panels
            v-show="
              documentStore.getSignOrderContext &&
              documentStore.updatedSignOrderRequestBody.length > 1
            "
            v-model="tab"
          >
            <v-expansion-panel class="!shadow-none">
              <v-expansion-panel-text>
                <div class="d-flex flex-row flex-grow-1 panel-content mb-2">
                  <div class="drawer-footnote mr-4">
                    {{ getSignOrderContextDisplayValue }}
                  </div>
                  <div class="d-flex flex-row">
                    <div
                      class="deep-text-button"
                      @click="updateContextSignOrder(SignOrder.FIRST)"
                    >
                      {{ i18n.t('actions.first') }}
                    </div>
                    <v-icon class="px-3" color="#D9D9D9" size="16">
                      fal fa-pipe
                    </v-icon>
                    <div
                      class="deep-text-button"
                      @click="updateContextSignOrder(SignOrder.LAST)"
                    >
                      {{ i18n.t('actions.last') }}
                    </div>
                  </div>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </v-expand-transition>
    <!-- /SIGN ORDER ACTIONS -->
  </div>
</template>

<script setup>
import Draggable from 'vuedraggable';
import {
  getRequestBodyFromSignees,
  getRequestBodyFromSignGroups,
  getSignGroupsFromSignees,
  getUpdateOrderRequestFromSignGroups
} from '@/services/documentService';
import SignatureService from '@/services/signatureService';
import AuthService from '@/services/auth-service';
import { SignOrder } from '@/types/enums/SignOrder';
import { useSigneeStore } from '@/stores/signee/signee';
import { updateSignOrderType } from '@/api/deepsign/sign-order-type';
import { showSignOrder } from '@/api/deepsign/sign-order';
import { useDocumentStore } from '@/stores/document/document';
import {
  ref,
  computed,
  onUnmounted,
  onMounted,
  nextTick,
  watch,
  toRef
} from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { useI18n } from 'vue-i18n';
import { cloneDeep, maxBy, debounce } from '@/composables/useUtils';
import { useDocumentAuth } from '@/composables/useDocumentAuth';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { useValidationRules } from '@/composables/useValidationRules';
const { currentDocument } = useDocumentContext();
const { signatureRules } = useValidationRules();
const signeeStore = useSigneeStore();
const documentStore = useDocumentStore();
const emitter = useEmitter();
const i18n = useI18n();

const signees = ref([]);
const currentFieldPreviousValue = ref(null);
const placeSignatures = ref(false);
const ordered = ref(false);
const tab = ref(null);
const signaturesForm = ref();
const dragging = ref(false);
const draggingItem = ref(false);
const hiddenGroupIndexes = ref([]);
const currentDraggedGroupIndex = ref(null);
const tmpAutographPosition = ref(null);
const addingSignature = ref(false);
const disableDrag = ref(false);
const removingSignee = ref(false);
const documentAuth = useDocumentAuth();

const isDocumentAuthAuthenticated = computed(() => {
  return documentAuth.isAuthenticated.value;
});

function isRequestorSignee(signee) {
  return (
    signee.signeeId === initiatorSigneeId.value &&
    !isDocumentAuthAuthenticated.value
  );
}

const me = computed(() => {
  return AuthService.user?.email;
});

const shouldISign = computed(() => {
  return !!signees.value.find((signee) =>
    SignatureService.compareString(signee?.email, me.value)
  );
});

const allSignaturesArePlaced = computed(() => {
  return (
    !currentDocument.value.signees.some(
      (signature) => signature.autographPosition === null
    ) && currentDocument.value.signees.length > 0
  );
});

const signeeFields = computed(() => {
  if (!ordered.value) {
    const initiator = signees.value.find(
      (s) => s.signeeId === documentStore.initiatorSignee?.signeeId
    );
    const others = signees.value.filter(
      (s) => s.signeeId !== documentStore.initiatorSignee?.signeeId
    );
    if (initiator) {
      return [initiator, ...others];
    }
  }
  return signees.value;
});

const draggableGroups = computed(() => {
  const arrayWhitEmptySlots = [[]];
  documentStore.signGroups.forEach((group) => {
    arrayWhitEmptySlots.push(group);
    arrayWhitEmptySlots.push([]);
  });
  return toRef(cloneDeep(arrayWhitEmptySlots));
});

const nextGroupIndex = computed(() => {
  if (currentDocument.value.signees.length === 0 || !ordered.value) {
    return 0;
  }
  return (
    maxBy(currentDocument.value.signees, function (s) {
      return s.signOrder;
    }).signOrder + 1
  );
});

const getSignOrderContextDisplayValue = computed(() => {
  if (documentStore.signOrderContext === null) {
    return '';
  } else if (documentStore.signOrderContext === me.value) {
    return i18n.t('texts.i_am_signing');
  } else if (validateEmail(documentStore.signOrderContext)) {
    return i18n.t('texts.email_signs', {
      email: documentStore.signOrderContext
    });
  } else {
    return i18n.t('texts.domain_signs', {
      domain: documentStore.signOrderContext
    });
  }
});

const initiatorSigneeEmail = computed(() => {
  return documentStore.document?.initiatorDisplayEmail || '';
});

const initiatorSigneeId = computed(() => {
  return documentStore.initiatorSignee?.signeeId || null;
});

const showDefineOrderSwitch = computed(() => {
  if (ordered.value) {
    return true;
  }
  return currentDocument.value.signees.length > 1 && !addingSignature.value;
});

watch(
  () => signeeFields.value,
  (value) => {
    emitter.$emit('update-signee-fields', value);
  }
);

onMounted(async () => {
  emitter.$on('update-place-signatures-automatically', updatePlaceSignatures);
  emitter.$on('signee-hover', onSigneeHover);

  if (currentDocument.value.documentId != null) {
    currentDocument.value.signees.forEach((signee) =>
      signees.value.push({
        id: SignatureService.compareString(signee?.email, me.value)
          ? signee.email
          : randomUuid(),
        email: signee.email,
        signeeId: signee.signeeId
      })
    );
    if (!shouldISign.value && signees.value.length === 0) {
      await addSigneeField();
    }
  }
  ordered.value = documentStore.hasSignOrder;
  if (ordered.value) {
    showSignOrder(currentDocument.value.documentId).then((response) => {
      documentStore.signOrderContext = response.data.context;
    });
  }
  tab.value = documentStore.hasSignOrder ? 0 : null;

  if (allSignaturesArePlaced.value) {
    placeSignatures.value = true;
  }
});

onUnmounted(() => {
  emitter.$off('update-place-signatures-automatically', updatePlaceSignatures);
  emitter.$off('signee-hover', onSigneeHover);
});

async function changeUserSignature(needsOwnSignature) {
  try {
    addingSignature.value = true;
    if (needsOwnSignature) {
      // If there is only an empty email on the list
      // and you add yourself as a signee,
      // the empty field should be removed.
      if (
        currentDocument.value.documentId &&
        documentStore.signGroups &&
        documentStore.signGroups[0] &&
        documentStore.signGroups[0].length === 1 &&
        documentStore.signGroups[0][0] &&
        !documentStore.signGroups[0][0].email &&
        documentStore.signGroups[0][0].signeeId &&
        !ordered.value
      ) {
        signees.value = [];
        const signeeId = documentStore.signGroups[0][0].signeeId;

        if (documentStore.placedSignatures.length > 0) {
          SignatureService.removeSignaturePlaceholder(signeeId);
        }

        await signeeStore.removeSignee({
          documentId: currentDocument.value.documentId,
          signeeId
        });
      }
      // Requestor needs to sign
      signees.value.unshift({
        id: AuthService.user.id,
        email: me.value,
        signeeId: null
      });

      await signeeStore.addSignee({
        documentId: currentDocument.value.documentId,
        autographPosition: tmpAutographPosition.value,
        email: me.value,
        comment: null,
        signOrder: null
      });
      const addedSigneeIndex = signees.value.findIndex(
        (s) => s.email.toLowerCase() === me.value.toLowerCase()
      );
      signees.value[addedSigneeIndex].signeeId = signeeStore.signee.signeeId;

      if (placeSignatures.value) {
        emitter.$emit(
          'place-signatures-automatically',
          currentDocument.value.signees
        );
      }
    } else {
      // Requestor does not sign
      const requestorSignees = signees.value.filter(
        (signee) =>
          signee.email &&
          signee.email.toLowerCase() ===
            currentDocument.value.initiatorDisplayEmail.toLowerCase()
      );
      const signee = signees.value.find(
        (s) => s.email.toLowerCase() === me.value.toLowerCase()
      );

      const promises = [];
      if (signees.value.length - requestorSignees.length >= 1) {
        const removePromises = requestorSignees.map((requestorSignee) =>
          removeSigneeFromDocument(requestorSignee)
        );
        promises.push(...removePromises);
      } else {
        const signeeFieldToUpdate = requestorSignees.find(
          (sf) => sf.signeeId === signee.signeeId
        );

        // Update remaining signee to null
        const updatedSigneeField = { ...signeeFieldToUpdate, email: null };
        promises.push(
          signeeStore.updateSignee({
            documentId: currentDocument.value.documentId,
            signeeId: updatedSigneeField.signeeId,
            email: null,
            autographPosition: updatedSigneeField.autographPosition
          })
        );

        // Remove all other requestor signees
        const remainingSignees = requestorSignees.filter(
          (sf) => sf.signeeId !== signee.signeeId
        );
        const removePromises = remainingSignees.map((requestorSignee) =>
          removeSigneeFromDocument(requestorSignee)
        );
        promises.push(...removePromises);

        signees.value = [
          {
            id: signee.signeeId,
            signeeId: signee.signeeId,
            email: null
          }
        ];
      }
      await Promise.all(promises);
      clearInvalidFields();
    }
    focusFirstEmptyInput();

    addingSignature.value = false;
  } catch (error) {
    console.error('Error changing owner signature', error);
  }
}

function focusFirstEmptyInput() {
  if (signees.value) {
    signees.value.some((signee) => {
      if (signee && signee.signeeId) {
        const signeeField = document.getElementById(`email-${signee.signeeId}`);
        if (signeeField && signeeField.value === '') {
          nextTick(() => {
            signeeField.focus();
          });
          // Break the some loop
          return true;
        }
      }
      // Keep looking
      return false;
    });
  }
}

function onBlur(event, signee) {
  if (!signee) return;
  const isValidEmail = validateEmail(signee.email);
  emitter.$emit('signee-blur', { ...signee, isValidEmail });
}

async function handleSignee(event, signee) {
  if (!signee) return;
  emitter.$emit('signee-blur', signee);

  const isValidEmail = validateEmail(signee.email);
  emitter.$emit('signee-blur', { ...signee, isValidEmail });
  try {
    if (!isValidEmail) {
      return;
    }

    const valueChanged = await hasInputValueChanged(signee);

    if (valueChanged) {
      if (signee && signee.signeeId && currentDocument.value.documentId) {
        await signeeStore.updateSignee({
          documentId: currentDocument.value.documentId,
          signeeId: signee.signeeId,
          email: signee.email === '' ? null : signee.email,
          autographPosition: signee.autographPosition
        });

        currentFieldPreviousValue.value = { ...signee };
        return;
      }

      if (
        signee &&
        !signee.signeeId &&
        signee.email !== '' &&
        currentDocument.value.documentId
      ) {
        // Add new one
        await signeeStore.addSignee({
          documentId: currentDocument.value.documentId,
          email: signee.email
        });

        if (
          placeSignatures.value &&
          currentDocument.value.signees &&
          currentDocument.value.signees.length > 0
        ) {
          emitter.$emit(
            'place-signatures-automatically',
            currentDocument.value.signees
          );
        }

        const addedSigneeIndex = signees.value.findIndex(
          (s) => s.signeeId === signee.signeeId
        );

        signees.value[addedSigneeIndex].signeeId = signeeStore.signee.signeeId;
        currentFieldPreviousValue.value = {
          ...signees.value[addedSigneeIndex]
        };
      }
    }
    if (
      event.relatedTarget
        ? event.relatedTarget.id === 'requestor-switch'
        : false
    ) {
      document.getElementById('requestor-switch').click();
    }
  } catch (error) {
    currentFieldPreviousValue.value = { ...signee };
    console.error('Error handling signee (add / update)', error);
  }
}

async function hasInputValueChanged(signee) {
  const currentDocumentSignees = documentStore.document?.signees.map(
    (signee) => {
      const signeeStringified =
        JSON.stringify(signee.value) || JSON.stringify(signee);
      return JSON.parse(signeeStringified);
    }
  );

  const currentSignee = currentDocumentSignees.find(
    (s) => s.signeeId === signee.signeeId
  );
  const hasValueChanged =
    currentDocumentSignees.length > 0 && signee.email
      ? signee.email.toLowerCase() !== currentSignee.email
      : false;

  return hasValueChanged;
}

const handleSigneeDebounced = debounce(function (event, signee) {
  handleSignee(event, signee);
}, 200);

function onFocus(signee) {
  if (!signee) return;
  emitter.$emit('signee-focus', signee);
  currentFieldPreviousValue.value = { ...signee };
}

async function removeSigneeFromDocument(signee) {
  removingSignee.value = true;
  // If there is only one signee and that signee is you
  // When you click on delete, we need to replace it for an empty one
  if (
    signees.value.length === 1 &&
    signee.email &&
    me.value &&
    signee.email.toLowerCase() === me.value.toLowerCase()
  ) {
    const requestorSignees = signees.value.filter(
      (s) =>
        s.email.toLowerCase() ===
        currentDocument.value.initiatorDisplayEmail.toLowerCase()
    );
    const signeeFieldToUpdate = requestorSignees.find(
      (sf) => sf.signeeId === signee.signeeId
    );
    const updatedSigneeField = { ...signeeFieldToUpdate, email: null };
    signees.value = [
      {
        id: signee.signeeId,
        signeeId: signee.signeeId,
        email: null
      }
    ];
    try {
      await signeeStore.updateSignee({
        documentId: currentDocument.value.documentId,
        signeeId: updatedSigneeField.signeeId,
        email: null,
        autographPosition: updatedSigneeField.autographPosition
      });
      removingSignee.value = false;
    } catch (error) {
      console.error('Error removing signee ', error);
    }
  } else {
    try {
      if (signee.signeeId) {
        const pdfPreview = document.getElementById('pdf-preview');
        const signaturePreviews =
          pdfPreview.getElementsByClassName('signature-preview');
        if (signaturePreviews.length > 0) {
          SignatureService.removeSignaturePlaceholder(signee.signeeId);
        }

        await signeeStore.removeSignee({
          documentId: currentDocument.value.documentId,
          signeeId: signee.signeeId
        });
      }
      signees.value = signees.value.filter(
        (s) => s.signeeId !== signee.signeeId
      );
    } catch (error) {
      console.error('Error removing signee ', error);
    } finally {
      removingSignee.value = false;
    }
  }
  focusFirstEmptyInput();
}

async function addSigneeField() {
  addingSignature.value = true;
  await signeeStore
    .addSignee({
      documentId: currentDocument.value.documentId,
      email: null,
      signOrder: nextGroupIndex.value
    })
    .then((response) => {
      const signee = response.data;
      signees.value.push({
        id: signee.signeeId,
        email: signee.email,
        signeeId: signee.signeeId
      });
      if (placeSignatures.value) {
        emitter.$emit(
          'place-signatures-automatically',
          currentDocument.value.signees
        );
      }
      nextTick(() => {
        const signeeField = document.getElementById(`email-${signee.signeeId}`);
        if (signeeField) {
          signeeField.focus();
        }
      });
      addingSignature.value = false;
    })
    .catch((error) => {
      addingSignature.value = false;
      console.error('Error adding signature field', error);
    });
  await documentStore.fetchDocument(currentDocument.value.documentId);
}

function updatePlaceSignatures(value) {
  placeSignatures.value = value;
}

function randomUuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

async function updateOrder(event, signee) {
  const newSigneeIndex = event.target.value - 1;
  const newSigneeGroups = [...documentStore.signGroups];
  let groupToRemove = null;
  let updatedSignee = null;
  const signeeToPush = {
    ...currentDocument.value.signees.find((s) => s.signeeId === signee.signeeId)
  };
  let added = false;
  signeeToPush.signOrder = newSigneeIndex;

  if (newSigneeIndex < 0) {
    signFirst(signeeToPush.email);
    return;
  }

  newSigneeGroups.forEach((group, index) => {
    let groupItem = group;
    updatedSignee = groupItem.find((s) => signee.signeeId === s.signeeId);
    if (updatedSignee !== undefined) {
      if (groupItem.length === 1) {
        groupToRemove = index;
      } else {
        groupItem = [
          ...groupItem.filter((s) => s.signeeId !== signee.signeeId)
        ];
      }
      newSigneeGroups[index] = groupItem;
    }
    if (index === newSigneeIndex) {
      added = true;
      signeeToPush.signOrder = newSigneeIndex;
      groupItem.push(signeeToPush);
    }
  });
  if (groupToRemove !== null) {
    newSigneeGroups.splice(groupToRemove, 1);
  }
  if (!added) {
    newSigneeGroups.push([signeeToPush]);
  }
  const requestBody = getUpdateOrderRequestFromSignGroups(newSigneeGroups);
  if (requestBody.length > 1) {
    await documentStore.updateDocumentSignOrder(
      currentDocument.value.documentId,
      requestBody
    );
  } else {
    documentStore.deleteDocumentSignOrder(currentDocument.value.documentId);
    tab.value = null;
  }
}
function onOrderSwitchToggle(event) {
  ordered.value = event;
  clearInvalidFields();
  if (!event) {
    documentStore.deleteDocumentSignOrder(currentDocument.value.documentId);
    tab.value = null;
  } else {
    const requestBody = [];
    currentDocument.value.signees.forEach((signee) => {
      requestBody.push([signee.signeeId]);
    });
    documentStore.updateDocumentSignOrder(
      currentDocument.value.documentId,
      requestBody
    );
    tab.value = 0;
  }
}
function signFirst(signeeEmail) {
  // Retrieve the signees list from the document
  const { signees } = currentDocument.value;

  // Find the user signee based on the signeeEmail
  const userSignee = signees.find((signee) =>
    SignatureService.compareString(signee?.email, signeeEmail)
  );

  // Get the sign groups from the document signees
  const signGroups = getSignGroupsFromSignees(signees);

  // Check if the user signee is already the first signer and if there is only one signee in the first sign group
  const isFirstSigner = userSignee.signOrder === 0;
  const isFirstSignGroupWithSingleSignee = signGroups[0].length === 1;

  if (!(isFirstSigner && isFirstSignGroupWithSingleSignee)) {
    // Create a new array with updated sign order values
    const updatedSignees = signees.map((signee) => {
      if (SignatureService.compareString(signee.email, signeeEmail)) {
        // Set the sign order of the user signee to 0
        return { ...signee, signOrder: 0 };
      }
      // Increment the sign order of other signees
      return { ...signee, signOrder: signee.signOrder + 1 };
    });

    // Create the request body from the updated signees list
    const requestBody = getRequestBodyFromSignees(updatedSignees);

    // Update the document sign order
    documentStore.updateDocumentSignOrder(
      currentDocument.value.documentId,
      requestBody
    );
  }
}

async function updateContextSignOrder(type) {
  const response = await updateSignOrderType(
    currentDocument.value.documentId,
    type
  );
  documentStore.updateDocumentSignOrderTemp(response.data);
}

function onItemDragUpdate() {
  const newOrderedGroups = draggableGroups.value.value.filter(
    (group) => group.length > 0
  );

  const body = getRequestBodyFromSignGroups(newOrderedGroups);

  updateDebounced(body);
  documentStore.updateDocumentSignOrderTemp(body);
}
function onGroupDragUpdate(event) {
  const { moved } = event;

  // Calculate old and new index
  const oldIndex = moved.oldIndex / 2 - 0.5;
  const difference = (moved.newIndex - moved.oldIndex) / 2;
  const newIndex = oldIndex + difference;

  // Create a clone of the sign groups
  const groups = cloneDeep(documentStore.signGroups);

  // Remove the moved group from the original position
  const movedGroup = groups.splice(oldIndex, 1);

  // Insert the moved group at the new position
  groups.splice(newIndex, 0, ...movedGroup);

  // Update the sign order for each group
  const updatedGroups = groups.map((group, index) =>
    group.map((s) => ({ ...s, signOrder: index }))
  );

  // Generate the request body from the updated groups
  const requestBody = getRequestBodyFromSignGroups(updatedGroups);

  // Update the document's sign order
  documentStore.updateDocumentSignOrder(
    currentDocument.value.documentId,
    requestBody
  );
}

function startItemDragging(index) {
  draggingItem.value = true;
  currentDraggedGroupIndex.value = index;
  if (draggableGroups.value.value[index].length === 1) {
    hiddenGroupIndexes.value = [index - 1, index + 1];
  }
}

function endItemDragging() {
  draggingItem.value = false;
  currentDraggedGroupIndex.value = null;
  hiddenGroupIndexes.value = [];
}

const updateDebounced = debounce(function (body) {
  documentStore.updateDocumentSignOrder(currentDocument.value.documentId, body);
}, 200);

function validateEmail(mail) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/.test(
    mail
  );
}

/*
function updateSigneeField(event, signee) {
  const signeeFieldToUpdate = signeeFields.value.find(
    (sf) => sf.signeeId === signee.signeeId
  );

  if (signeeFieldToUpdate) {
    //TODO @daniel.henkiel debug if event contains email property
    signeeFieldToUpdate.email = event.email.toLowerCase();
  }

  handleSigneeDebounced(event, signee);
}
*/

function clearInvalidFields() {
  // Clear invalid email fields
  signeeFields.value.forEach((signeeField) => {
    if (!validateEmail(signeeField.email)) {
      const signeeToUpdate = signees.value.find(
        (signee) => signee.id === signeeField.id
      );
      if (signeeToUpdate) {
        signeeToUpdate.email = '';
      }
    }
  });

  // Update email fields with valid email addresses from the document signees
  signeeFields.value.forEach((signeeField) => {
    const documentSignee = currentDocument.value.signees.find(
      (ds) => ds.signeeId === signeeField.signeeId
    );
    const signeeToUpdate = signees.value.find(
      (signee) => signee.id === signeeField.id
    );
    if (signeeToUpdate) {
      signeeToUpdate.email = documentSignee.email;
    }
  });
}

function onSigneeHover(signeeId) {
  const signeeField = document.getElementById(`email-${signeeId}`);
  if (signeeField) {
    signeeField.focus();
  }
}

function firstOrLast(index) {
  return index === 0 || index === draggableGroups.value.value.length - 1;
}
</script>

<style lang="scss" scoped>
@import '@/styles/core/colors';

.sign-yourself-container {
  margin-top: 3px;
  max-height: 42px;
}

.sign-yourself-subtitle {
  color: $grey;
}

.request-signatures-text {
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.004em;
}

.v-expansion-panel-content :deep(.v-expansion-panel-content__wrap) {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.panel-content {
  font-size: 14px !important;
  line-height: 17px !important;
}

.buttons {
  margin-top: 35px;
}

.row-v {
  height: 150px;
  width: 200px;
}

.deep-secondary-button :deep(.v-btn__content),
.v-btn__content > span {
  font-weight: 400 !important;
  letter-spacing: 0;
}

.v-expansion-panels :deep(.v-expansion-panel__shadow) {
  box-shadow: 0 0 #0000 !important;
}

.v-expansion-panels :deep(.v-expansion-panel-text__wrapper) {
  padding: 0;
  padding-bottom: 8px !important;
}

.v-card .v-card--disabled * {
  opacity: 1;
}
</style>
