<template>
  <v-dialog v-model="showDialog" persistent :max-width="900">
    <v-card class="pa-6" rounded="lg">
      <v-card-title class="flex items-center px-0 py-0">
        <h5 class="card-title">{{ i18n.t('texts.customize_signature') }}</h5>
      </v-card-title>
      <p class="text-gray-600 max-w-lg pb-4">
        {{ i18n.t('settings.subtitles.my_signature') }}
      </p>
      <UserSignatureForm
        v-if="showDialog"
        class="py-0"
        @signature-updated="signatureUpdated"
        @close-signature-edition="closeDialog"
      />
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, onUnmounted } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { useSignStore } from '@/stores/sign/sign';
import { useI18n } from 'vue-i18n';

const emitter = useEmitter();
const showDialog = ref(false);
const signStore = useSignStore();
const i18n = useI18n();
const isUpdating = ref<boolean>(false);

emitter.$on('open-signature-edition-dialog', () => (showDialog.value = true));

onUnmounted(() => {
  emitter.$off(
    'open-signature-edition-dialog',
    () => (showDialog.value = true)
  );
});

function closeDialog() {
  if (isUpdating.value) return;
  showDialog.value = false;
}

function signatureUpdated(autographUrl: string) {
  if (signStore.signInfo) {
    signStore.autographData = autographUrl;
    signStore.signInfo.defaultAutographUrl = autographUrl;
  }
  emitter.$emit('retrigger-preview');
  closeDialog();
}
</script>

<style scoped lang="scss">
.card-title {
  word-break: break-word;
  font-weight: 600;
  font-size: 24px !important;
  text-align: center;
  margin-bottom: 15px !important;
  white-space: normal;
}
</style>
