<template>
  <v-snackbar
    location="top center"
    content-class="snackbar-text bg-white mt-2"
    :timeout="-1"
    :model-value="messageStore.snackbar.active"
  >
    <div class="flex gap-3 bg-white">
      <!-- ICON -->
      <v-icon
        v-if="messageStore.snackbar.icon"
        :color="
          messageStore.snackbar.color === 'success'
            ? 'success'
            : messageStore.snackbar.color
        "
        size="small"
        :icon="messageStore.snackbar.icon"
      />
      <!-- /ICON -->

      <!-- KEY -->
      <span v-if="messageStore.snackbar.key">
        {{ i18n.t(messageStore.snackbar.key) }}
      </span>
      <!-- /KEY -->

      <!-- TEXT -->
      <span v-if="messageStore.snackbar.text">
        {{ String(messageStore.snackbar.text) }}
      </span>
      <!-- /TEXT -->

      <!-- CONTEXTUAL ACTIONS -->
      <template
        v-for="(action, index) in messageStore.snackbar.actions"
        :key="index"
      >
        <div class="d-flex flex-row align-center justify-center">
          <a
            v-if="action.text"
            class="action-text"
            :href="action.href"
            :style="`color: ${action.color}`"
            target="_blank"
          >
            {{ i18n.t(action.text) }}
          </a>
          <v-icon v-if="action.separator" class="pipe" icon="fa-thin fa-pipe" />
        </div>
      </template>
    </div>

    <!-- /CONTEXTUAL ACTIONS -->

    <!-- CLOSE ICON BUTTON -->
    <template #actions>
      <v-btn
        class="bg-gray-300 text-black rounded-full"
        elevation="0"
        max-height="24"
        max-width="24"
        @click="messageStore.hideMessage"
      >
        <v-icon size="12"> $close </v-icon>
      </v-btn>
    </template>
    <!-- /CLOSE ICON BUTTON -->
  </v-snackbar>
</template>

<script setup lang="ts">
import { useMessageStore } from '@/stores/message/message';
import { useI18n } from 'vue-i18n';

const messageStore = useMessageStore();
const i18n = useI18n();
</script>
<style lang="scss" scoped>
@import '@/styles/core/colors';

.snackbar-text {
  span {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.005em;
    color: #000000;
  }
}

.pipe {
  margin: 0;
  color: rgba(0, 0, 0, 0.15);
}

.action-text {
  margin: 0 12px;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.004em;
}
</style>
