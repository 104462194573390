<template>
  <div class="d-flex flex-column" style="width: 100%">
    <v-card color="#F5F5F5" class="mb-4 pa-4" flat rounded="lg">
      <v-alert
        color="warning"
        variant="outlined"
        class="warning-message mb-4"
        rounded
      >
        <div class="d-flex flex-row align-center">
          <v-icon color="warning" class="mr-2" size="14"
            >fas fa-triangle-exclamation</v-icon
          >
          <div class="d-flex flex-column align-center">
            {{ i18n.t('dialogs.identify_warning.alert_text') }}
          </div>
        </div>
      </v-alert>
      <div v-if="rasService === 'sms-code'" class="warning-hint">
        {{ i18n.t('dialogs.identify_warning.hint') }}
      </div>
      <div v-else class="warning-hint">
        {{ i18n.t('dialogs.identify_warning.hint') }}
        <span class="warning-hint">
          <a
            href="https://check-signature.scapp.swisscom.com/"
            target="_blank"
            class="text-primary"
            style="text-decoration: none"
            rel="noopener"
          >
            {{ i18n.t('dialogs.identify_warning.check_phone_number') }}
          </a>
        </span>
      </div>
    </v-card>
    <div class="mb-3 warning-subtitle">
      {{ i18n.t('dialogs.identify_warning.subtitle') }}
    </div>

    <v-card class="deep-id-card" variant="outlined" rounded="lg">
      <!-- DISABLE EsLint here because of https://github.com/vuetifyjs/eslint-plugin-vuetify/issues/83 -->
      <!-- eslint-disable -->
      <v-img
        src="/svg/authority-services/did-logo.svg"
        height="20"
        contain
        position="left"
        class="mb-4"
      />
      <!-- eslint-enable -->
      <div class="deep-id-overline">
        {{ i18n.t('dialogs.identify_warning.overline') }}
      </div>
      <div class="deep-id-text">
        {{ i18n.t('dialogs.identify_warning.deep_id_text') }}
      </div>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { PropType } from 'vue';

defineProps({
  rasService: {
    type: String as PropType<'sms-code' | 'mobile-id'>,
    default: ''
  }
});

const i18n = useI18n();
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.warning-title {
  text-align: center;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
}

.deep-id-card {
  padding: 16px;
  background-color: white;
  border-color: $primary-color !important;
  margin-bottom: 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15) !important;
}

.warning-message {
  background-color: white !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 12px;
}

.warning-subtitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #090909;
  text-align: left !important;
}

.warning-hint {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #090909;
}

.deep-id-overline {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #121212;
  margin-bottom: 8px;
}

.deep-id-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #121212;
}
</style>
