<template>
  <v-dialog v-model="showDialog" persistent scrollable width="600">
    <v-card class="pa-6" rounded="lg">
      <!-- TITLE -->
      <v-card-title class="justify-center">
        <h5
          class="md:text-base text-sm flex items-center justify-center mb-4 whitespace-normal text-center"
        >
          {{ i18n.t('titles.support_title') }}
        </h5>
      </v-card-title>
      <!-- /TITLE -->

      <v-card-text class="px-0">
        <!-- HINT -->

        <I18nT
          keypath="hints.check_knowledge_base"
          class="text-center mt-4 mb-10"
          tag="div"
        >
          <template #link>
            <a
              :href="knowledgeCenterUrl"
              target="_blank"
              style="text-decoration: none"
              class="text-primary"
              rel="noopener nofollow"
            >
              {{ i18n.t('labels.knowledge_base') }}
            </a>
          </template>
        </I18nT>

        <!-- /HINT -->

        <!-- FORM -->
        <v-form
          ref="supportRequestForm"
          class="mt-4 grid gap-6"
          @submit.prevent
        >
          <v-text-field
            v-model="form.email"
            autocapitalize="off"
            autocomplete="off"
            autocorrect="off"
            type="email"
            :autofocus="form.email === null"
            :hint="
              i18n
                .t('hints.we_may_contact_you_for_further_questions')
                .toString()
            "
            :label="i18n.t('labels.email').toString()"
            name="field-form-feedback-email"
            variant="outlined"
            persistent-hint
            :placeholder="i18n.t('placeholders.contact_email').toString()"
            :rules="emailRules"
          />

          <v-textarea
            ref="feedbackMessage"
            v-model="form.message"
            :autofocus="form.email !== null"
            :label="i18n.t('labels.request_message')"
            name="request_message"
            variant="outlined"
            :placeholder="i18n.t('placeholders.enter_support_request')"
            :rules="feedbackMessageRules"
          />

          <FileUpload />
        </v-form>
        <!-- /FORM -->
      </v-card-text>

      <!-- ACTIONS -->
      <DeepButton
        :action="submitForm"
        class="mb-3"
        color="primary"
        :loading="deepAdminStore.sendEmailRequestPending"
        :text="i18n.t('buttons.send_support_request')"
      />
      <DeepButton
        :action="closeDialog"
        variant="outlined"
        :text="i18n.t('buttons.cancel')"
      />
      <!-- /ACTIONS -->
    </v-card>
  </v-dialog>
</template>

<script setup>
import AuthService from '@/services/auth-service';
import { useDeepAdminStore } from '@/stores/deepadmin/deepadmin.ts';
import { EmailRequestType } from '@/types/deepadmin/enums/EmailRequestType.ts';
import { ref, reactive, onUnmounted, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useEmitter } from '@/composables/useEmitter.ts';
import I18nT from '@/components/I18nT.vue';
import { useValidationRules } from '@/composables/useValidationRules';

const supportRequestForm = ref();
const deepAdminStore = useDeepAdminStore();
const i18n = useI18n();
const emitter = useEmitter();
const showDialog = ref(false);
const form = reactive({
  signeeAttachments: [],
  email: null,
  message: null
});
const attachmentsSizeTooLarge = ref(false);

const { emailRules, feedbackMessageRules } = useValidationRules();

const knowledgeCenterUrl = computed(() => {
  let url = import.meta.env.VITE_KNOWLEDGE_CENTER_URL;
  switch (i18n.locale.value) {
    case 'en':
      url += '/en/topic/deepsign/';
      break;
    case 'fr':
      url += '/en/topic/deepsign/';
      break;
    case 'it':
      url += '/en/topic/deepsign/';
      break;
    case 'de':
      url += '/topic/deepsign/';
      break;
  }
  return url;
});

emitter.$on('open-support-dialog', openDialog);
emitter.$on('update-attachments', updateAttachments);

onUnmounted(() => {
  emitter.$off('open-support-dialog', openDialog);
  emitter.$off('update-attachments', updateAttachments);
});

function openDialog() {
  showDialog.value = true;
  if (AuthService.user?.email) form.email = AuthService.user?.email;
}

function closeDialog() {
  supportRequestForm.value.reset();
  emitter.$emit('reset-attachments');
  form.email = null;
  form.message = null;
  showDialog.value = false;
}

async function submitForm() {
  const isFormValid = await supportRequestForm.value.validate();
  if (isFormValid && !attachmentsSizeTooLarge.value) {
    await deepAdminStore.sendEmailRequest({
      files: form.signeeAttachments,
      contact: form.email,
      text: form.message,
      type: EmailRequestType.SUPPORT
    });
    closeDialog();
  }
}

function updateAttachments(payload) {
  attachmentsSizeTooLarge.value = payload.tooLarge;
  form.signeeAttachments = payload.signeeAttachments;
}
</script>

<style lang="scss" scoped>
:deep(.v-text-field.v-text-field--enclosed .v-text-field__details) {
  padding-left: 0 !important;
}
</style>
