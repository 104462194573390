import { defineStore } from 'pinia';
import publicAxios from '@/plugins/publicAxios';
import { useMessageStore } from '@/stores/message/message';
import { ref } from 'vue';
import globalAxios from '@/composables/useAxios';

export const useDownloadStore = defineStore('download', () => {
  const documentDownloadUrl = ref('');
  const fetchDocumentDownloadUrlPending = ref(false);
  const fetchAuthDocumentDownloadUrlPending = ref(false);
  const fetchOwnerDocumentDownloadUrlPending = ref(false);

  async function fetchDocumentDownloadUrl(signKey: string) {
    try {
      fetchDocumentDownloadUrlPending.value = true;
      const response = await publicAxios.get(`sign/${signKey}`);
      documentDownloadUrl.value = response.data.document.documentUrl;
      fetchDocumentDownloadUrlPending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      const messageStore = useMessageStore();
      messageStore.showMessage({
        key: 'errors.api.download_document',
        color: 'error',
        icon: 'fa fa-circle-exclamation'
      });
      fetchDocumentDownloadUrlPending.value = false;
      return Promise.reject(error);
    }
  }

  async function fetchAuthDocumentDownloadUrl(signKey: string) {
    try {
      fetchAuthDocumentDownloadUrlPending.value = true;
      const response = await globalAxios.get(
        `${import.meta.env.VITE_DEEPSIGN_API_BASE_URL}sign/${signKey}`
      );
      documentDownloadUrl.value = response.data.document.documentUrl;
      fetchAuthDocumentDownloadUrlPending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      const messageStore = useMessageStore();
      messageStore.showMessage({
        key: 'errors.api.download_document',
        color: 'error',
        icon: 'fa fa-circle-exclamation'
      });
      fetchAuthDocumentDownloadUrlPending.value = false;
      return Promise.reject(error);
    }
  }

  async function fetchOwnerDocumentDownloadUrl(documentId: string) {
    try {
      fetchDocumentDownloadUrlPending.value = true;
      const response = await globalAxios.get(
        `${import.meta.env.VITE_DEEPSIGN_API_BASE_URL}documents/${documentId}`
      );
      documentDownloadUrl.value = response.data.documentUrl;
      fetchDocumentDownloadUrlPending.value = false;
    } catch (error) {
      const messageStore = useMessageStore();
      messageStore.showMessage({
        key: 'errors.api.download_document',
        color: 'error',
        icon: 'fa fa-circle-exclamation'
      });
      fetchDocumentDownloadUrlPending.value = false;
      return Promise.reject(error);
    }
  }

  async function fetchObserverDocumentDownloadUrl(documentId: string) {
    try {
      fetchDocumentDownloadUrlPending.value = true;
      const response = await globalAxios.get(
        `${
          import.meta.env.VITE_DEEPSIGN_API_BASE_URL
        }sign/documents/${documentId}`
      );
      documentDownloadUrl.value = response.data.document.documentUrl;
      fetchDocumentDownloadUrlPending.value = false;
    } catch (error) {
      const messageStore = useMessageStore();
      messageStore.showMessage({
        key: 'errors.api.download_document',
        color: 'error',
        icon: 'fa fa-circle-exclamation'
      });
      fetchDocumentDownloadUrlPending.value = false;
      return Promise.reject(error);
    }
  }

  return {
    // State
    documentDownloadUrl,
    fetchDocumentDownloadUrlPending,
    fetchAuthDocumentDownloadUrlPending,
    fetchOwnerDocumentDownloadUrlPending,

    // Actions
    fetchDocumentDownloadUrl,
    fetchAuthDocumentDownloadUrl,
    fetchOwnerDocumentDownloadUrl,
    fetchObserverDocumentDownloadUrl
  };
});
