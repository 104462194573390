<template>
  <v-dialog v-model="showDialog" persistent width="600">
    <v-card class="pa-6 scrollbar-menu-card !rounded-[12px]">
      <!-- SMS CODE -->
      <SmsCodeIdentifyCard
        v-if="
          isSmsCode &&
          !deepAdminInternalStore.isVerified &&
          !showWarning &&
          !showMobileIDWarning
        "
        @show-warning="showWarning = true"
        @close-dialog="closeDialog"
      />
      <!-- /SMS CODE -->

      <!-- DEEP ID -->
      <DeepIDIdentifyCard
        v-if="
          isDeepID &&
          !deepAdminInternalStore.isVerified &&
          !showWarning &&
          !showMobileIDWarning
        "
        @close-dialog="closeDialog"
      />
      <!-- /DEEP ID -->

      <!-- MOBILE ID -->
      <MobileIDIdentifyCard
        v-if="
          isMobileID &&
          !deepAdminInternalStore.isVerified &&
          !showWarning &&
          !showMobileIDWarning
        "
        @show-mobile-id-warning="showMobileIDWarning = true"
        @close-dialog="closeDialog"
      />
      <!-- /MOBILE ID -->

      <!-- VERIFIED -->
      <SuccessfulVerifiedCard
        v-if="deepAdminInternalStore.isVerified"
        :authority-service="authorityServiceKey"
        @close-dialog="closeDialog"
      />
      <!-- /VERIFIED -->

      <!-- WARNING -->
      <IdentifyWarningCard
        v-if="showWarning && !showMobileIDWarning"
        @close-dialog="closeDialog"
      />
      <!-- /WARNING -->

      <!-- MOBILE ID WARNING -->
      <MobileIDWarningCard
        v-if="showMobileIDWarning"
        @close-dialog="closeDialog"
        @cancel="closeDialog"
      />
      <!-- /MOBILE ID WARNING -->
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import AuthService from '@/services/auth-service';
import { useMessageStore } from '@/stores/message/message.ts';
import { SignatureMode } from '@/types/enums/SignatureMode.ts';
import { AuthorityService } from '@/types/enums/AuthorityService.ts';
import { useDeepAdminInternalStore } from '@/stores/deepadmin/deepadmin-internal.ts';
import { ref, computed, onUnmounted } from 'vue';
import { useEmitter } from '@/composables/useEmitter';

const messageStore = useMessageStore();
const deepAdminInternalStore = useDeepAdminInternalStore();
const emitter = useEmitter();
const showDialog = ref(false);
const showWarning = ref(false);
const showMobileIDWarning = ref(false);
const authService = ref(null);
const onboardingTimeout = ref();

const signMode = ref(null);

const isSmsCode = computed(() => {
  return (
    authorityServiceKey.value === AuthorityService.MOBILE_ID &&
    signMode.value === SignatureMode.ADVANCED
  );
});

const isMobileID = computed(() => {
  return (
    authorityServiceKey.value === AuthorityService.MOBILE_ID &&
    signMode.value === SignatureMode.QUALIFIED
  );
});

const isDeepID = computed(() => {
  return authorityServiceKey.value === AuthorityService.DEEP_ID;
});

const authorityServiceKey = computed(() => {
  return authService.value?.key || '';
});

emitter.$on('restart-verification', restartVerification);
emitter.$on('open-identify-dialog', openDialog);

onUnmounted(() => {
  emitter.$off('open-identify-dialog', openDialog);
  emitter.$off('restart-verification', restartVerification);
});

function openDialog({ authorityService, signatureMode }) {
  authService.value = authorityService;
  signMode.value = signatureMode;
  if (authorityServiceKey.value === AuthorityService.DEEP_ID) {
    checkStatus();
  }
  showDialog.value = true;
}

function closeDialog() {
  showDialog.value = false;
  showWarning.value = false;
  showMobileIDWarning.value = false;
  setTimeout(() => {
    authService.value = null;
    if (onboardingTimeout.value) {
      clearTimeout(onboardingTimeout.value);
    }
  }, 200);
}

async function checkStatus() {
  try {
    await deepAdminInternalStore.fetchOnboardingStatus();
    if (
      deepAdminInternalStore.isVerified &&
      deepAdminInternalStore.isDeepIDVerification
    ) {
      emitter.$emit('enable-deep-id');
    }
    if (deepAdminInternalStore.shouldStartValidation) {
      const payload = {
        first_name: AuthService.user.given_name,
        last_name: AuthService.user.family_name,
        source: 'deepid',
        purpose: 'deepsign'
      };
      await deepAdminInternalStore.startUserValidation(payload).then(() => {
        checkStatus();
      });
    } else if (deepAdminInternalStore.isInProgress) {
      onboardingTimeout.value = setTimeout(checkStatus, 3000);
    } else {
      clearTimeout(onboardingTimeout.value);
    }
  } catch (error) {
    messageStore.showMessage({
      key: 'errors.custom.identification_status',
      color: 'error',
      icon: 'fa fa-circle-exclamation'
    });
  }
}

async function restartVerification() {
  clearTimeout(onboardingTimeout.value);
  const payload = {
    first_name: AuthService.user.given_name,
    last_name: AuthService.user.family_name,
    source: 'deepid',
    purpose: 'deepsign'
  };
  await deepAdminInternalStore.startUserValidation(payload).finally(() => {
    checkStatus();
  });
}
</script>
