<template>
  <div class="flex items-center justify-between bg-white rounded-b-lg">
    <Title>
      {{ i18n.t('text.documents_total', { n: total }) }}
    </Title>

    <UploadNewDocumentButton v-if="!$vuetify.display.mdAndUp" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { Title } from '@deepfront/text';

const i18n = useI18n();

defineProps({
  total: {
    type: Number,
    required: true
  }
});
</script>
