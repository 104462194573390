<template>
  <v-footer id="mobile-footer" app class="bottom-sheet-footer">
    <DocumentBottomSheet />
  </v-footer>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.v-footer {
  bottom: 0 !important;
}
</style>
