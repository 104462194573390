<template>
  <div class="d-flex flex-column" style="width: 100%">
    <div class="mobile-id-title">
      {{ i18n.t('titles.mobile_id_verification') }}
    </div>
    <div class="mobile-id-subtitle">
      {{ i18n.t('texts.check_identity_ras_hint') }}
    </div>
    <v-row no-gutters>
      <v-col :cols="12" :md="6">
        <v-text-field
          v-model="form.firstname"
          disabled
          :class="{
            'mr-2': $vuetify.display.mdAndUp,
            'mb-5': !$vuetify.display.mdAndUp
          }"
          hide-details="auto"
          :label="i18n.t('labels.firstname')"
          variant="outlined"
        />
      </v-col>
      <v-col :cols="12" :md="6">
        <v-text-field
          v-model="form.lastname"
          disabled
          :class="{ 'ml-2': $vuetify.display.mdAndUp }"
          hide-details="auto"
          :label="i18n.t('labels.lastname')"
          variant="outlined"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5" no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model="form.displayName"
          hide-details="auto"
          :label="i18n.t('labels.display_name')"
          :error-messages="displayNameErrors"
          variant="outlined"
          data-test-id="mobile-id-display-name"
          @update:model-value="clearErrors"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5 mb-2" no-gutters>
      <v-col cols="12">
        <FieldPhone
          v-bind="bindProps"
          id="phone-input"
          :label="i18n.t('labels.phone_number')"
          :country-label="i18n.t('labels.country')"
          hide-details="auto"
          :rules="phoneNumberErrors"
          :invalid-message="i18n.t('errors.invalid_phone_number')"
          :placeholder="String(i18n.t('placeholders.phone_number'))"
          data-test-id="mobile-id-phone-number"
          :valid-characters-only="true"
          @update:model-value="onInput"
        />
      </v-col>
    </v-row>
    <!-- ACTIONS -->
    <DeepButton
      :disabled="!isFormValid"
      :action="updateDeepAdminUser"
      :loading="
        deepAdminStore.updateUserPending || deepAdminStore.checkMobileIDPending
      "
      class="my-3"
      color="primary"
      :text="i18n.t('buttons.save')"
      data-test-id="mobile-id-save-button"
    />
    <DeepButton
      :action="closeDialog"
      variant="outlined"
      :text="i18n.t('buttons.cancel')"
    />
  </div>
</template>

<script setup lang="ts">
import AuthService from '@/services/auth-service';
import { useDeepAdminStore } from '@/stores/deepadmin/deepadmin';
import { useSignStore } from '@/stores/sign/sign';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useEmitter } from '@/composables/useEmitter';
import { AuthorityService } from '@/types/enums/AuthorityService';
import { useSignContext } from '@/composables/useSignContext';
import { Jurisdiction } from '@/types/enums/Jurisdiction';
import type { formType, errorType } from '@/types/PhoneForm';
import type { ParsedPhoneNumber } from 'awesome-phonenumber';

const emit = defineEmits(['close-dialog', 'show-mobile-id-warning']);
const deepAdminStore = useDeepAdminStore();
const signStore = useSignStore();
const i18n = useI18n();
const emitter = useEmitter();

const form = reactive<formType>({
  firstname: AuthService.user.given_name,
  lastname: AuthService.user.family_name,
  displayName: AuthService.user.display_name,
  phoneNumber: '',
  id: AuthService.user.sub
});

const phone = ref<ParsedPhoneNumber>();
const errors = ref<errorType>();

const bindProps = reactive({
  defaultCountry: 'CH',
  preferredCountries: ['CH', 'IT', 'FR', 'DE', 'AT', 'LI'],
  mode: 'international',
  disabledFetchingCountry: false,
  disabled: false,
  disabledFormatting: false,
  required: true,
  enabledFlags: true,
  ignoredCountries: [],
  autocomplete: 'on',
  name: 'number',
  maxLen: 25,
  dropdownOptions: { disabledDialCode: true, tabindex: 0 },
  inputOptions: { showDialCode: false, tabindex: 0 },
  enabledCountryCode: false,
  validCharactersOnly: true
});

const displayNameErrors = computed(() => {
  return errors.value?.non_field_errors || [];
});

const phoneNumberErrors = computed(() => {
  if (!phone.value) return [];
  const numberPattern = /^\+?\d+(\s\d+)*$/;

  if (
    phone.value.number &&
    phone.value.number.international &&
    numberPattern.test(phone.value.number.international) &&
    phone.value.valid
  ) {
    return [];
  } else {
    return [i18n.t('errors.invalid_phone_number')];
  }
});

const isFormValid = computed(() => {
  return phone.value && phone.value.valid && Boolean(phoneNumberErrors.value);
});

function closeDialog() {
  emit('close-dialog');
}

function onInput(phoneObject: ParsedPhoneNumber) {
  if (phoneObject.number) {
    form.phoneNumber = phoneObject.number.international;
  }
  phone.value = phoneObject;
}

const { jurisdiction } = useSignContext();

async function updateDeepAdminUser() {
  try {
    await deepAdminStore.updateUser({
      id: form.id,
      firstName: form.firstname,
      lastName: form.lastname,
      display_name: form.displayName,
      country: phone.value?.regionCode ? phone.value?.regionCode : '',
      phonenumber: form.phoneNumber ? form.phoneNumber : ''
    });
    const response = await deepAdminStore.checkMobileID(form.phoneNumber);
    if (response.data?.valid && response.data?.valid_eidas) {
      // EIDAS & ZERTES
      enableSmsCode();
    } else if (
      jurisdiction.value === Jurisdiction.ZERTES &&
      response.data?.valid
    ) {
      // ZERTES
      enableSmsCode();
    } else if (
      jurisdiction.value === Jurisdiction.EIDAS &&
      response.data?.valid_eidas
    ) {
      // EIDAS
      enableSmsCode();
    } else {
      emit('show-mobile-id-warning');
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    errors.value = e?.response?.data;
    if (!displayNameErrors.value) {
      throw e;
    }
  }
}

function enableSmsCode() {
  signStore.enableAuthorityService(AuthorityService.MOBILE_ID);
  emitter.$emit('enable-sms-code');
  closeDialog();
}
function clearErrors() {
  errors.value = undefined;
}
</script>

<style scoped lang="scss">
.mobile-id-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  margin-bottom: 12px;
}

.mobile-id-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
}

:deep(.v-text-field.v-text-field--enclosed .v-text-field__details) {
  padding-left: 0 !important;
}
</style>
