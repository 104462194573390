import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Signee } from '@/types/Signee';
import type { AddSigneePayload } from '@/types/AddSigneePayload';
import { useMessageStore } from '@/stores/message/message';
import { useI18n } from 'vue-i18n';
import type { RemoveSigneePayload } from '@/types/RemoveSigneePayload';
import type { UpdateSigneePayload } from '@/types/UpdateSigneePayload';
import { showSignOrder } from '@/api/deepsign/sign-order';
import { useDocumentStore } from '@/stores/document/document';
import globalAxios from '@/composables/useAxios';

export const useSigneeStore = defineStore('signee', () => {
  const i18n = useI18n();
  const messageStore = useMessageStore();
  const documentStore = useDocumentStore();

  const signee = ref<Signee>();
  const signees = ref<Signee[]>();
  const fetchSigneesPending = ref(false);
  const fetchSigneePending = ref(false);
  const addSigneePending = ref(false);
  const removeSigneePending = ref(false);
  const updateSigneePending = ref(false);
  const updateAutographPositionPending = ref(false);
  const signeeBeingMoved = ref<string>();

  async function addSignee(payload: AddSigneePayload) {
    try {
      addSigneePending.value = true;

      const response = await globalAxios.post(
        `${import.meta.env.VITE_DEEPSIGN_API_BASE_URL}documents/${
          payload.documentId
        }/signees`,
        {
          email: payload.email,
          comment: payload.comment,
          signOrder: payload.signOrder,
          autographPosition: payload.autographPosition,
          signatureType: payload.signatureType
        }
      );

      signee.value = response.data as Signee;
      addSigneePending.value = false;
      documentStore.addSigneeToDocument(response.data);
      const newOrderContext = await showSignOrder(payload.documentId);
      documentStore.signOrderContext = newOrderContext.data.context;
      return Promise.resolve(response);
    } catch (error) {
      addSigneePending.value = false;
      messageStore.showMessage({
        key: i18n.t('errors.api.add_signee'),
        color: 'error',
        icon: 'fa fa-circle-exclamation'
      });
      return Promise.reject(error);
    }
  }

  async function removeSignee(payload: RemoveSigneePayload) {
    try {
      const response = await globalAxios.delete(
        `${import.meta.env.VITE_DEEPSIGN_API_BASE_URL}documents/${
          payload.documentId
        }/signees/${payload.signeeId}`
      );
      removeSigneePending.value = false;
      documentStore.removeSigneeFromDocument(payload.signeeId);
      const newOrderContext = await showSignOrder(payload.documentId);
      documentStore.signOrderContext = newOrderContext.data.context;
      return Promise.resolve(response);
    } catch (error) {
      messageStore.showMessage({
        key: i18n.t('errors.api.remove_signee'),
        color: 'error',
        icon: 'fa fa-circle-exclamation'
      });
      return Promise.reject(error);
    }
  }

  async function updateSignee(payload: UpdateSigneePayload) {
    try {
      updateSigneePending.value = true;
      const body = {
        autographPosition: payload.autographPosition,
        email: payload.email,
        comment: payload.comment,
        signatureType: payload.signatureType
      };
      const response = await globalAxios.put(
        `${import.meta.env.VITE_DEEPSIGN_API_BASE_URL}documents/${
          payload.documentId
        }/signees/${payload.signeeId}`,
        body
      );
      const newOrderContext = await showSignOrder(payload.documentId);
      documentStore.signOrderContext = newOrderContext.data.context;
      documentStore.updateDocumentSignee(response.data);
      updateSigneePending.value = false;
      return Promise.resolve(response);
    } catch (error) {
      updateSigneePending.value = false;
      messageStore.showMessage({
        key: i18n.t('errors.api.update_signee'),
        color: 'error',
        icon: 'fa fa-circle-exclamation'
      });
      return Promise.reject(error);
    }
  }

  async function updateAutographPosition(payload: UpdateSigneePayload) {
    try {
      updateAutographPositionPending.value = true;

      const body = {
        autographPosition: payload.autographPosition,
        email: payload.email,
        comment: payload.comment
      };

      const response = await globalAxios.put(
        `${import.meta.env.VITE_DEEPSIGN_API_BASE_URL}documents/${
          payload.documentId
        }/signees/${payload.signeeId}`,
        body
      );
      updateAutographPositionPending.value = false;
      documentStore.updateSigneeAutographPosition(response.data);
      return Promise.resolve(response);
    } catch (error) {
      updateAutographPositionPending.value = false;
      messageStore.showMessage({
        key: i18n.t('errors.api.autograph_position'),
        color: 'error',
        icon: 'fa fa-circle-exclamation'
      });
      return Promise.reject(error);
    }
  }

  function updateSigneeBeingMoved(email: string) {
    signeeBeingMoved.value = email;
  }

  return {
    // State
    addSigneePending,
    fetchSigneePending,
    fetchSigneesPending,
    removeSigneePending,
    signee,
    signeeBeingMoved,
    signees,
    updateAutographPositionPending,
    updateSigneePending,

    // Actions
    addSignee,
    removeSignee,
    updateSignee,
    updateAutographPosition,
    updateSigneeBeingMoved
  };
});
