<template>
  <span class="pb-3">
    {{ i18n.t('batch.error.maximum_file_size') }}
  </span>
  <div class="text-gray-500">
    {{ `${totalFilesSize} MB / 40 MB` }}
  </div>
  <div class="mb-8 p-3 bg-yellow-50 border border-yellow-300 rounded grid">
    <ul class="grid gap-4">
      <li
        v-for="document in documents"
        :key="document.documentId"
        class="flex items-center justify-between"
      >
        <span class="font-semibold underline">
          {{ document.documentName }}
        </span>
        <v-icon
          size="x-small"
          @click="emit('remove-document', document.documentId)"
        >
          fa-solid fa-x
        </v-icon>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import type { Document } from '@/types/Document';
import { computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  documents: {
    type: Array as PropType<Document[]>,
    default: undefined
  }
});

const emit = defineEmits(['remove-document']);

const i18n = useI18n();

const totalFilesSize = computed(() => {
  let fileSize = 0;
  props.documents.forEach((file) => (fileSize += file.initialSize));
  return (fileSize / 1000000).toFixed(1);
});
</script>
