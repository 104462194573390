<template>
  <div
    class="d-flex flew-row pt-5 min-h-[60px]"
    @mouseenter="isMouseHover = true"
    @mouseleave="isMouseHover = false"
  >
    <div class="d-flex flex-column observer-container pl-3">
      <!-- EMAIL -->
      <div
        class="d-flex flex-row align-center justify-space-between observer-email"
        :data-test-id="`added-observer-${observer.email}`"
      >
        <v-tooltip
          content-class="tooltip deep-light tooltip-top"
          location="top"
          open-on-click
        >
          <template #activator="{ props: ActivatorProps }">
            <span v-bind="ActivatorProps" class="deep-truncate">
              {{ observer.email }}
            </span>
          </template>
          <span>
            {{ observer.email }}
          </span>
        </v-tooltip>
      </div>
      <!-- /EMAIL -->

      <!-- IS ADMIN -->
      <div class="d-flex flex-row align-center is-admin">
        <div v-if="observer.isAdmin">
          <span :data-test-id="`observer-admin-${observer.email}`">
            {{ i18n.t('labels.admin') }}
          </span>
        </div>
      </div>
      <!-- /IS ADMIN -->
    </div>
    <v-spacer />
    <div class="flex flex-col justify-center">
      <ParticipantMenu
        participant-type="observer"
        :show-activator="isMouseHover"
        :participant-policy="observer.policy"
        :participant-id="observer.observerId"
        :participant-email="observer.email"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

defineProps({
  observer: {
    type: Object,
    required: true
  }
});

const i18n = useI18n();
const isMouseHover = ref(false);
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.observer-email {
  word-break: break-all;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}

.is-admin {
  margin-top: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.015em;
  color: $grey;
}

.observer-container {
  max-width: calc(100% - 20px);
}
</style>
