<template>
  <div class="flex items-center gap-2">
    <component :is="icon" :size="16" class="text-gray-500" />
    <div
      class="whitespace-nowrap text-ellipsis truncate"
      :style="{ 'max-width': maxWidth ? `${maxWidth}px` : 'auto' }"
    >
      {{ item?.display_name }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { Building2, CircleUserRound } from 'lucide-vue-next';
import { computed } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    default: undefined
  },
  active: {
    type: Boolean,
    default: false
  },
  maxWidth: {
    type: Number,
    default: undefined
  }
});

const isPersonalSpace = computed(() => {
  return props?.item?.structure === 'personal';
});

// Icons
const personalSpaceIcon = CircleUserRound;
const organizationIcon = Building2;
const icon = computed(() => {
  return isPersonalSpace.value ? personalSpaceIcon : organizationIcon;
});
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.organization-logo {
  border: solid 1px $grey-darken-1;
  border-radius: 4px;
  object-fit: cover;
  height: 24px;
  width: 24px;
  margin-right: 16px;

  &.active {
    border: solid 1px $primary-color;
  }
}
</style>
