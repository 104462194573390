<template>
  <v-menu
    v-if="!!globalStore.pageCount && globalStore.pageCount > 1"
    v-model="menu"
    class="move-to-page-menu"
    :close-on-content-click="false"
    location="bottom"
    @close="closeMenu"
  >
    <!-- Activator -->
    <template #activator="{ props: ActivatorProps }">
      <a
        v-bind="ActivatorProps"
        class="deep-text-button"
        data-test-id="move-signature"
      >
        {{
          modelValue
            ? i18n.t('texts.move_one_signature')
            : i18n.t('texts.move_all_signatures')
        }}
      </a>
    </template>
    <!-- /Activator -->

    <v-card
      class="move-to-page-menu"
      :min-width="
        signaturesPresentOnDifferentPages && !confirmed && !inProgress
          ? 300
          : 250
      "
    >
      <!-- User confirmation is needed -->
      <v-list-item
        v-if="
          signaturesPresentOnDifferentPages &&
          !confirmed &&
          !inProgress &&
          !modelValue
        "
        class="pr-1 d-flex justify-lg-space-between align-center py-2"
        density="compact"
      >
        <div class="confirm-hint">
          <I18nT keypath="texts.signatures_on_different_pages_warning">
            <template #pageNumber>
              <strong>{{ globalStore.currentPage }}</strong>
            </template>
          </I18nT>
          <p class="text-primary cursor-pointer">
            <a @click="onConfirm">
              {{ i18n.t('buttons.confirm') }}
            </a>
          </p>
        </div>
        <template #append>
          <v-divider vertical />
          <v-btn class="ml-2" icon size="x-small" @click="closeMenu">
            <v-icon color="grey-lighten-1" size="small"> far fa-xmark </v-icon>
          </v-btn>
        </template>
      </v-list-item>
      <!-- Signatures are all on the same page or user confirmed -->
      <v-list-item v-else density="compact" class="h-[58px]">
        <div class="flex flex-row justify-space-between items-center">
          <v-btn
            color="primary"
            :disabled="!canDecrease"
            size="x-small"
            variant="plain"
            icon
            data-test-id="move-to-first-page"
            @click="placeOnFirstPage"
          >
            <v-icon size="medium"> far fa-chevrons-left </v-icon>
          </v-btn>

          <v-btn
            color="primary"
            :disabled="!canDecrease"
            size="x-small"
            variant="plain"
            icon
            data-test-id="move-to-previous-page"
            @click="decreasePageNumber"
          >
            <v-icon size="medium"> far fa-chevron-left </v-icon>
          </v-btn>

          <div class="px-4">{{ i18n.t('labels.page') }}</div>

          <v-text-field
            v-model="pageNumber"
            class="max-w-[70px]"
            density="compact"
            hide-details
            hide-spin-buttons
            min="1"
            :max="globalStore.pageCount"
            variant="outlined"
            type="number"
            required
            :rules="pageNumberRules"
            single-line
          />

          <v-btn
            color="primary"
            :disabled="!canIncrease"
            size="x-small"
            variant="plain"
            icon
            data-test-id="move-to-next-page"
            @click="increasePageNumber"
          >
            <v-icon size="medium"> far fa-chevron-right </v-icon>
          </v-btn>

          <v-btn
            color="primary"
            :disabled="!canIncrease"
            size="x-small"
            variant="plain"
            icon
            data-test-id="move-to-last-page"
            @click="placeOnLastPage"
          >
            <v-icon size="medium"> far fa-chevrons-right </v-icon>
          </v-btn>
        </div>
      </v-list-item>
      <!-- /Signatures are all on the same page or user confirmed -->
    </v-card>
  </v-menu>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
});
</script>

<script setup>
import { useGlobalStore } from '@/stores/global/global.ts';
import { useSigneeStore } from '@/stores/signee/signee.ts';
import { ref, computed, onUnmounted, watch } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';
import I18nT from '@/components/I18nT.vue';
import { debounce } from '@/composables/useUtils';
import { useSignStore } from '@/stores/sign/sign';
import { useDocumentContext } from '@/composables/useDocumentContext';

const props = defineProps({
  updateOnlyOne: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: Object,
    required: false,
    default: undefined
  }
});

const { currentDocument } = useDocumentContext();

const emit = defineEmits(['update:modelValue']);
const signStore = useSignStore();
const globalStore = useGlobalStore();
const { updateSignee } = useSigneeStore();
const emitter = useEmitter();
const i18n = useI18n();

const confirmed = ref(false);
const pageNumber = ref(null);
const menu = ref(false);
const inProgress = ref(false);

const pageNumberRules = computed(() => {
  return [
    (v) => !!v, // Required
    (v) => /^[1-9]\d*$/.test(v), // Positive integer
    (v) => isValid(v) // Is a valid page Number
  ];
});

const canIncrease = computed(() => {
  return parseInt(pageNumber.value) < globalStore.pageCount;
});

const canDecrease = computed(() => {
  return parseInt(pageNumber.value) > 1;
});

const signaturesPresentOnDifferentPages = computed(() => {
  try {
    const unique = [
      ...new Set(
        currentDocument.value.signees.map(
          (item) => item.autographPosition.pageNumber
        )
      )
    ];
    return unique.length > 1;
  } catch (e) {
    return false;
  }
});

const numericPageNumber = computed(() => {
  return parseInt(pageNumber.value);
});

emitter.$on('close-move-all-signatures-menu', closeMenu);

onUnmounted(() => {
  emitter.$off('close-move-all-signatures-menu', closeMenu);
});

function isPageNumberValid(value) {
  if (value === null) return false;
  if (value === '') return false;
  if (value > globalStore.pageCount) return false;
  return value >= 1;
}

function isValid(pageNumber) {
  return pageNumber <= globalStore.pageCount;
}

function increasePageNumber() {
  pageNumber.value = (numericPageNumber.value + 1).toString();
}

function placeOnFirstPage() {
  pageNumber.value = '1';
}

function placeOnLastPage() {
  pageNumber.value = globalStore.pageCount.toString();
}

function decreasePageNumber() {
  pageNumber.value = (numericPageNumber.value - 1).toString();
}

function closeMenu() {
  menu.value = false;
  confirmed.value = false;
}

const updateDebounced = debounce(function () {
  updateSignaturesPage();
}, 300);

const updateVisualPositionDebounced = debounce(function (pageNumber) {
  updateVisualPosition(pageNumber);
}, 300);

function updateSignaturesPage() {
  if (isPageNumberValid(pageNumber.value)) {
    // set in progress so that we do not show the warning in menu that signatures are at the moment on 2 different pages
    inProgress.value = true;

    // Filter to only make calls for the needed signatures
    const signeesToUpdate = currentDocument.value.signees.filter(
      (signee) =>
        signee.autographPosition?.pageNumber !== numericPageNumber.value
    );

    Promise.all(
      signeesToUpdate.map(async (signeeToUpdate) => {
        const updatedSignee = {
          documentId: currentDocument.value.documentId,
          signeeId: signeeToUpdate.signeeId,
          email: signeeToUpdate.email,
          autographPosition: { ...signeeToUpdate.autographPosition }
        };
        updatedSignee.autographPosition.pageNumber = numericPageNumber.value;
        await updateSignee(updatedSignee);
      })
    ).then(() => {
      inProgress.value = false;
      confirmed.value = false;
    });
  }
}

function onConfirm() {
  confirmed.value = true;
  if (pageNumber.value === globalStore.currentPage) {
    updateVisualPositionDebounced(globalStore.currentPage);
    updateDebounced();
  } else {
    pageNumber.value = globalStore.currentPage;
  }
}

function updateVisualPosition(page) {
  if (isPageNumberValid(pageNumber.value)) {
    // Filter to only make calls for the needed signatures
    const signeesToUpdate = currentDocument.value.signees.filter(
      (signee) => signee.autographPosition.pageNumber !== parseInt(page)
    );
    const signeesToUpdateIds = signeesToUpdate.map((signee) => {
      return signee.signeeId;
    });
    emitter.$emit('update-placeholders-page', {
      signeeIds: signeesToUpdateIds,
      pageNumber: parseInt(page)
    });
  }
}

watch(
  () => menu.value,
  (newValue) => {
    if (props.modelValue && props.modelValue.autographPosition.pageNumber) {
      pageNumber.value = props.modelValue.autographPosition.pageNumber;
      return;
    }
    if (
      newValue &&
      !pageNumber.value &&
      (!signaturesPresentOnDifferentPages.value || confirmed.value)
    ) {
      pageNumber.value = globalStore.currentPage;
    } else {
      confirmed.value = false;
    }
  }
);

watch(
  () => pageNumber.value,
  (newValue, oldValue) => {
    if (newValue) {
      if (props.modelValue && newValue !== oldValue) {
        const newOwnSignature = {
          ...props.modelValue,
          autographPosition: {
            ...props.modelValue.autographPosition,
            pageNumber: newValue
          }
        };
        emit('update:modelValue', newOwnSignature);
        if (oldValue !== null) {
          emitter.$emit('update-placeholders-page', {
            signeeIds: [props.modelValue.signeeId],
            pageNumber: parseInt(newValue)
          });
        }
        signStore.updateSigneeAutographPage({
          signeeId: props.modelValue.signeeId,
          page: parseInt(newValue)
        });
      } else {
        updateVisualPositionDebounced(newValue);
        updateDebounced();
      }
    }
  }
);
</script>

<style scoped lang="scss">
.confirm-hint {
  margin-right: 4px;
  max-width: 200px;
}
</style>
