<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card class="dialog-container" rounded="lg">
      <v-card-text class="d-flex flex-column align-center justify-center pa-0">
        <!-- SMS CODE -->
        <BatchSmsCodeCard
          v-if="isSmsCode"
          :documents="documents"
          :phone-number="formattedPhoneNumber"
        />
        <!-- /SMS CODE -->

        <!-- DEEP ID - MOBILE ID -->
        <BatchAuthorityServiceSigningCard
          v-if="isDeepID || isMobileID"
          :documents="documents"
          :correlation-code="correlationCode"
          :authority-service="authorityService"
        />
        <!-- /DEEP ID - MOBILE ID -->

        <!-- ACTIONS -->
        <DeepButton
          v-if="device.isMobile && isDeepID"
          class="mb-3"
          :action="openDeepID"
          color="primary"
          :text="i18n.t('buttons.open_deepid')"
        />

        <DeepButton
          v-if="batchResponse.otpCallbackUrl && !waiting"
          color="primary"
          class="mb-3"
          :action="openPopup"
          :text="i18n.t('buttons.continue')"
        />
        <!-- /ACTIONS -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { SignatureMode } from '@/types/enums/SignatureMode.ts';
import { AuthorityService } from '@/types/enums/AuthorityService.ts';
import { formatPhoneNumber } from '@/services/utilsService';
import { ref, computed, onUnmounted, inject } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';
import { DeviceKey } from '@/plugins/device-detector-js';

const device = inject(DeviceKey);
const dialog = ref(false);
const waiting = ref(false);
const authorityService = ref(null);
const documents = ref();
const correlationCode = ref();
const batchResponse = ref();
const emitter = useEmitter();
const i18n = useI18n();

const formattedPhoneNumber = computed(() => {
  return batchResponse?.value?.phoneNumber
    ? formatPhoneNumber(batchResponse.value.phoneNumber)
    : '';
});

const isSmsCode = computed(() => {
  return (
    authorityService.value === AuthorityService.MOBILE_ID &&
    documents.value[0].signatureMode === SignatureMode.ADVANCED
  );
});

const isMobileID = computed(() => {
  return (
    authorityService.value === AuthorityService.MOBILE_ID &&
    documents.value[0].signatureMode === SignatureMode.QUALIFIED
  );
});

const isDeepID = computed(() => {
  return authorityService.value === AuthorityService.DEEP_ID;
});

emitter.$on('show-batch-pending-dialog', showDialog);
emitter.$on('close-batch-pending-dialog', closeDialog);

onUnmounted(() => {
  emitter.$off('show-batch-pending-dialog', showDialog);
  emitter.$off('close-batch-pending-dialog', closeDialog);
});

function openPopup() {
  window.open(batchResponse.value.otpCallbackUrl, '', 'width=640,height=480');
  waiting.value = true;
}

function openDeepID() {
  const url = import.meta.env.VITE_DEEPID_APP_UNIVERSAL_LINK;
  window.open(url, '_blank');
}

function showDialog(eventData) {
  documents.value = eventData.documents;
  authorityService.value = eventData.authorityService;
  batchResponse.value = eventData.batchResponse;
  correlationCode.value = eventData.correlationCode;
  dialog.value = true;
}

function closeDialog() {
  dialog.value = false;
  authorityService.value = null;
  waiting.value = false;
}
</script>

<style lang="scss" scoped>
.dialog-container {
  padding: 24px !important;
}
</style>
