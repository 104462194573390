import { computed } from 'vue';
import { useDocumentStore } from '@/stores/document/document';
import { useSignStore } from '@/stores/sign/sign';
import { useRoute } from 'vue-router';

export function useDocumentContext() {
  const documentStore = useDocumentStore();
  const signStore = useSignStore();

  const route = useRoute();

  const isDraft = computed(() => {
    return (
      route.name === 'document-create' || route.name === 'p-document-create'
    );
  });

  const currentDocument = computed(() => {
    if (isDraft.value || isDocumentAuthRoute.value) {
      return documentStore.document;
    } else {
      return signStore.signInfo?.document;
    }
  });

  const currentPlacedSignatures = computed(() => {
    if (isDraft.value || isDocumentAuthRoute.value) {
      return documentStore.placedSignatures;
    } else {
      return signStore.placedSignatures;
    }
  });

  const currentSignees = computed(() => {
    if (isDraft.value || isDocumentAuthRoute.value) {
      return documentStore.document?.signees || [];
    } else {
      return signStore.signInfo?.document?.signees || [];
    }
  });

  const isDocumentAuthRoute = computed(() => {
    return route.name?.startsWith('p-document') ?? false;
  });

  return {
    isDocumentAuthRoute,
    isDraft,
    currentDocument,
    currentPlacedSignatures,
    currentSignees
  };
}
