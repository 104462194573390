<template>
  <div class="d-flex flex-column" style="max-width: 100%">
    <div class="d-flex justify-center">
      <img
        class="mb-4 h-[24px]"
        :src="`/svg/authority-services/${authorityService}-logo.svg`"
        alt=""
      />
    </div>
    <div class="card-subtitle">
      {{
        i18n.t('texts.qualified_signature_instructions', {
          appName: i18n.t(`authorities.${authorityService}`)
        })
      }}
    </div>

    <v-card
      rounded="lg"
      color="#FAFAFA"
      class="pa-6 mb-8 text-center mv"
      width="100%"
      flat
    >
      <div class="max-h-48 overflow-y-auto mb-4">
        <div
          v-for="document in documents"
          :key="`document-name-${document.documentName}`"
          class="filename deep-truncate"
        >
          {{ document.documentName }}
        </div>
      </div>
      <v-card
        v-if="correlationCode"
        rounded="lg"
        color="#F5F5F5"
        class="pa-6 request-code-card d-flex flex-column justify-center align-center"
        flat
        variant="outlined"
        style="
          background-color: rgb(245, 245, 245);
          border-color: rgb(245, 245, 245);
        "
      >
        <div class="request-code">
          {{ correlationCode || '' }}
        </div>
        <div style="max-width: 200px">
          <div class="d-flex flex-column hint-text justify-center align-center">
            {{
              i18n.t('texts.correlation_code_check', {
                appName: i18n.t(`authorities.${authorityService}`)
              })
            }}
          </div>
        </div>
        <DocumentSignatureLevel
          v-if="documents"
          :signature-mode="documents[0].signatureMode"
          :jurisdiction="documents[0].jurisdiction"
          class="mt-4"
        />
        <!-- DATE TIME -->
        <div
          v-if="documents"
          class="d-flex flex-row align-center date-time justify-center"
        >
          {{
            formatDateToLocale(
              new Date().toISOString(),
              i18n.locale.value,
              true
            ) +
            ' · ' +
            formatDateTimeToLocaleTime(
              new Date().toISOString(),
              i18n.locale.value
            )
          }}
        </div>
        <!-- DATE TIME -->
      </v-card>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import {
  formatDateToLocale,
  formatDateTimeToLocaleTime
} from '@/composables/useUtils';
import { useI18n } from 'vue-i18n';
import type { Document } from '@/types/Document';
import { type PropType } from 'vue';

defineProps({
  documents: {
    type: Object as PropType<Document[]>,
    default: undefined
  },
  authorityService: {
    type: String,
    default: ''
  },
  correlationCode: {
    type: String,
    default: undefined
  }
});

const i18n = useI18n();
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.filename {
  margin-bottom: 16px;
  font-weight: 590;
  font-size: 16px;
  line-height: 19px;
  color: #121212;
}

.request-code-card {
  border-color: #eeeeee !important;
}

.request-code {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 12px;
  color: #1e1e1e;
}

.hint-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8e8e93;
}

.signature-level-description {
  padding: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.004em;
}

.signature-level {
  color: $grey;
  font-weight: 400 !important;
  margin-right: 6px;
  font-size: 14px !important;
}

.date-time {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8e8e93;
}

.card-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  margin-top: 16px;
  margin-bottom: 30px;
}
</style>
