<template>
  <Actionbar :counter="`${i18n.t('text.document', { n: total })}`">
    <div class="flex gap-2 items-center ActionGroup">
      <VBtn
        v-for="secondaryAction in secondaryActions"
        :key="secondaryAction.label"
        v-tooltip:bottom="secondaryAction.label"
        variant="tonal"
        :color="secondaryAction.type === 'warning' ? 'red' : undefined"
        icon
        size="small"
        @click="secondaryAction.action"
      >
        <VIcon
          :icon="secondaryAction.icon ? secondaryAction.icon : undefined"
        />
      </VBtn>

      <VBtn
        class="hidden lg:flex"
        :color="mainAction.type === 'warning' ? 'error' : 'primary'"
        :prepend-icon="mainAction.icon"
        @click.prevent="() => mainAction.action()"
      >
        {{ mainAction.label }}
      </VBtn>
      <VBtn
        v-tooltip:bottom="mainAction.label"
        class="block lg:hidden"
        :color="mainAction.type === 'warning' ? 'error' : 'primary'"
        icon
        size="small"
        :aria-label="mainAction.label"
        @click.prevent="() => mainAction.action()"
      >
        <v-icon :icon="mainAction.icon" />
      </VBtn>
    </div>
  </Actionbar>
</template>

<script lang="ts" setup>
import '@deepfront/button/style';
import { Actionbar } from '@deepfront/actionbar';
import '@deepfront/popover/style';
import '@deepfront/menu/style';
import '@deepfront/actionbar/style';
import { useI18n } from 'vue-i18n';
import { useDocumentActions } from '@/composables/useDocumentActions';
import { useRouteQuery } from '@vueuse/router';
import type {
  FilterDocumentStatuses,
  FilterSignStatuses
} from '@/types/OverviewFilter';
import { computed, inject } from 'vue';

const i18n = useI18n();

const filterSignStatus = useRouteQuery<FilterSignStatuses>('filterSignStatus');
const filterDocumentStatus = useRouteQuery<FilterDocumentStatuses>(
  'filterDocumentStatus'
);

const selectedDocuments = inject('selectedDocuments');

defineProps({
  total: {
    type: Number,
    required: true
  }
});

function getActions() {
  const { actions } = useDocumentActions(
    Array.isArray(filterDocumentStatus.value)
      ? filterDocumentStatus.value[0]
      : filterDocumentStatus.value,
    Array.isArray(filterSignStatus.value)
      ? filterSignStatus.value[0]
      : filterSignStatus.value,
    selectedDocuments
  );
  return actions.value;
}

const mainAction = computed(() => {
  return getActions()[0];
});
const secondaryActions = computed(() => {
  return getActions().slice(1);
});
</script>

<style scoped lang="postcss">
:deep(.Actionbar) {
  max-width: initial !important;
  width: calc(100% - 16px) !important;
}

:deep(.ButtonClose) {
  @apply !ml-[4.25px];
}

:deep(.FieldLabel) {
  @apply ml-2;
}
</style>
