import { createApp } from 'vue';
import App from './App.vue';
import globalAxios from './composables/useAxios';
import AuthService from '@/services/auth-service';
// Plugins
import { registerPlugins } from '@/plugins';

import '@/assets/main.css';
import '@/styles/main.scss';
import '@/assets/fontawesome/css/all.css';
import '@deepcloud/deep-ui-lib/dist/style.css';

AuthService.initKeycloak(onAuthFinish);
async function onAuthFinish() {
  const app = createApp(App);

  globalAxios.defaults.headers.Authorization = AuthService.fullAccessToken;

  registerPlugins(app);
  app.mount('#app');

  // With this "hack" we force the KC token to be always updaten on window focus
  // this is needed for the logout from another domain like: deepsign.swiss
  const isPublicDocument = window.location.href.includes('/p/document');
  if (AuthService.isAuthenticated && !isPublicDocument) {
    window.addEventListener(
      'focus',
      function () {
        AuthService.updateToken(-1);
      },
      false
    );
  }
}
