import deepSignApi from '@/api/deepsign/deepsignApi';
import type { BatchSignDocuments } from '@/types/deepsign/BatchSignDocuments';
import { SignatureMode } from '@/types/enums/SignatureMode.ts';
import { Jurisdiction } from '@/types/enums/Jurisdiction.ts';
import type { AxiosError, AxiosResponse } from 'axios';

type AvailableAuthorityServicesResponse = {
  data: {
    ras: {
      hasRequiredLevel: boolean;
      signeeIssues: string[];
    };
    did: {
      hasRequiredLevel: boolean;
      signeeIssues: string[];
    };
  };
};

export const batchSign = (payload: BatchSignDocuments | FormData) =>
  deepSignApi.post<AxiosError, AxiosResponse>('batch/sign', payload, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

export const batchStatus = (batchKey: string) =>
  deepSignApi.get(`batch/${batchKey}/status`);

export const getAvailableAuthorityServices = (payload: {
  signatureMode: SignatureMode;
  jurisdiction: Jurisdiction;
}): AvailableAuthorityServicesResponse => {
  return deepSignApi.get<AvailableAuthorityServicesResponse>(
    `users/me/available-authority-services?signatureMode=${payload.signatureMode}&jurisdiction=${payload.jurisdiction}`
  );
};
