import { useDocumentAuth } from '@/composables/useDocumentAuth';
import AuthService from '@/services/auth-service';

const setAuthorizationHeaders = async (headers: any) => {
  const { getDocumentToken } = useDocumentAuth();
  const documentToken = getDocumentToken.value;
  if (documentToken) {
    headers.Authorization = `Bearer ${documentToken}`;
  } else if (AuthService.isAuthenticated) {
    await AuthService.updateToken();
    if (AuthService.accessToken) {
      headers.Authorization = AuthService.fullAccessToken;
    }
    return Promise.resolve(headers);
  }

  return Promise.resolve();
};

export { setAuthorizationHeaders };
